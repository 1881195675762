import React, { useEffect, useState } from 'react';
import { IonRow, IonCol } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import ClearIcon from '@material-ui/icons/Clear';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import { RootState } from '../../../models/RootState';
import { AxiosResponse } from 'axios';
import {
  getCurrencyTypeFromToken,
  setCasinoGame,
  updateCasinoGames,
} from '../../../store';
import { CasinoGameDTO } from '../../../models/IndianCasinoState';
import { BRAND_NAME } from '../../../constants/Branding';
import { ShowCasinoModal } from '../../../constants/WhitelabelConfig';
import './CasinoNew.scss';
import API from '../../../api';
import { GapGame } from '../../../models/dc/DcGame';
import inplay from '../../../assets/images/common/Banner/Polygon.svg';
// import ElectionImg from '../../../assets/images/common/Banner/';
import './CasinoNew.scss';
import { Button } from '@material-ui/core';
import HomeView from '../../../constants/HomeView';

type StoreProps = {
  loggedIn: boolean;
  loggedInUserStatus: any;
  updateCasinoGames: (casinoGames: CasinoGameDTO[]) => void;
  setCasinoGame: (cGame: CasinoGameDTO) => void;
};

const OtherGames: React.FC<StoreProps> = (props) => {
  const [games, setGames] = useState<GapGame[]>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { loggedIn, setCasinoGame, loggedInUserStatus } = props;
  const history = useHistory();

  const otherImgs = HomeView.OTHERS_TAB_GAMES_WEB;

  const setDialogShow = (show: boolean) => {
    setShowDialog(show);
  };

  let gameList = games.filter((g) => g.providerName === 'AWC');

  return (
    <div className="dc-page-bg">
      <div className="dc-ctn">
        <IonRow class="casino-filters-row">
          <div className="title">Other</div>
        </IonRow>
        <IonRow className="dc-games-ctn">
       
        </IonRow>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  let status = 0;
  if (state.auth.loggedIn) {
    status = JSON.parse(
      window.atob(sessionStorage.getItem('jwt_token').split('.')[1])
    ).status;
  }
  return {
    loggedIn: state.auth.loggedIn,
    loggedInUserStatus: status,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    updateCasinoGames: (casinoGames: CasinoGameDTO[]) =>
      dispatch(updateCasinoGames(casinoGames)),
    setCasinoGame: (cGame: CasinoGameDTO) => dispatch(setCasinoGame(cGame)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherGames);
