import React from 'react';
import './Poker.scss';
import { connect } from 'react-redux';
import { RootState } from '../../models/RootState';
import UserStatusValues from '../../constants/UserStatus';

type StoreProps = {
  loggedIn: boolean;
  fetchBalance: () => void;
  loggedInUserStatus: number;
};

const UserStatus = UserStatusValues.USER_STATUS;

const Poker: React.FC<StoreProps> = (props) => {
  const { loggedIn, loggedInUserStatus } = props;
  const bcToken = sessionStorage.getItem('bc_token');

  // const iFrameSrc = bcToken;
  // ? `https://poker-web.hypexonline.com/1544/#?partnerId=1544&language=en&openType=real&gameId=Poker&token=${bcToken}`
  // : `https://poker-web.hypexonline.com/1544/#?partnerId=1544&language=en&openType=fun&gameId=Poker`;

  const iFrameSrc = bcToken
    ? `https://games.hypexonline.com/authorization.php?partnerId=1544&gameId=${28}&token=${bcToken}&openType=real&language=en&devicetypeid=1`
    : `https://games.hypexonline.com/authorization.php?partnerId=1544&gameId=${28}&openType=fun&language=en&devicetypeid=1`;

  return (
    <div className="poker-ctn">
      {loggedIn && UserStatus[loggedInUserStatus] !== 'active' ? (
        <div className="bet-locked-view">
          You are locked from betting, Please contact your upline!!
        </div>
      ) : (
        <iframe src={iFrameSrc} title="Poker-Iframe"></iframe>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  let status = 0;
  if (state.auth.loggedIn) {
    status = JSON.parse(
      window.atob(sessionStorage.getItem('jwt_token').split('.')[1])
    ).status;
  }
  return {
    loggedIn: state.auth.loggedIn,
    loggedInUserStatus: status,
  };
};

export default connect(mapStateToProps)(Poker);
