import React from 'react';
import Copyright from '../../components/Copyright/Copyright';
import { BRAND_NAME } from '../../constants/Branding';

import './ResponsibleGambling.scss';

const ResponsibleGambling: React.FC = () => {
  return (
    <>
      <div className="responsible-gaming-ctn">
        <div className="data-ctn">
          <h1 className="rules-para-header">Play responsibly</h1>

          <p>
            {BRAND_NAME}’s Responsible Gambling Policy sets out its commitment
            to minimizing the negative effects of problem gambling and to
            promote responsible gambling practices.
          </p>

          <p>
            {BRAND_NAME} provides online customers with a wide range of games
            and entertainment. We also take responsibility for our product
            line-up. The aim of {BRAND_NAME} is to provide the world’s safest
            and most innovative gaming platform for adults. The products and
            services allow each user to play within his/her financial means and
            receive the highest quality service. Integrity, fairness and
            reliability are the guiding principles for {BRAND_NAME}. It is
            therefore clear that {BRAND_NAME}
            should do its best to assist those customers that show signs of
            suffering from problem gambling, particularly in those cases of
            immoderate playing. At the same time, it is important to respect the
            rights of those who take part in games of chance to a reasonable
            extent and treat it as a form of entertainment.
          </p>

          <p>
            Responsible Gaming at {BRAND_NAME} is based on three fundamental
            principles: security of the player, security of the game and
            protection against problem gambling. Together with research
            institutes, associations and therapy institutions, {BRAND_NAME}{' '}
            works on the creation of a responsible, secure and reliable
            framework for online gambling.
          </p>
          <h1 className="rules-para-header">Player security</h1>

          <p>
            {BRAND_NAME} takes responsibility for the security of its players.
            Protection of the players is based on preventing under age players
            from participating in games and the protection of privacy, which
            involves responsible processing of personal data and payments.
            Fairness and the random nature of the products offered are monitored
            closely by independent organisations. Marketing communications are
            also created with player protection and fairness in mind.
          </p>

          <h1 className="rules-para-header">
            Protection against problem gambling: research – prevention –
            intervention
          </h1>

          <p>
            The majority of gamblers who place sports bets, casino bets and
            other gaming offers play in moderation for entertainment. Certain
            habits and behaviour patterns (such as shopping, playing sports,
            eating or consumption of alcohol) that are considered to be normal
            and are not any cause for concern, can develop into addiction for
            some people and cause them problems. In the same way, betting on
            sports and gambling in general can lead to problems for a small
            percentage of customers.
          </p>

          <p>
            Clients showing signs of problem gambling may be prohibited from
            playing certain products or even altogether. Furthermore, clients
            displaying signs of problem gambling are provided with contacts of
            organisations where they can receive professional advice and
            support.
          </p>

          <h1 className="rules-para-header">
            Self-responsibility is the most sustainable form of prevention
          </h1>

          <p>
            The basic principle promoted by {BRAND_NAME} is to create a safe and
            responsible online environment where customers can enjoy their
            betting experience. Whilst {BRAND_NAME} can provide tools to assist
            customers to control their gambling, self-responsibility of the
            customer plays a large part in using the tools we offer and is the
            most effective form of protection from problem gambling.{' '}
            {BRAND_NAME} sees its responsibility in assisting the customers by
            providing transparent products, tools to control gambling and full
            information on where customers experiencing problems with their
            gambling can find professional advice and support.
          </p>

          <h1 className="rules-para-header">Protection of minors</h1>

          <p>
            {BRAND_NAME} does not allow minors (persons under the age of 18 or
            whatever the minimum age to gamble is within the customers
            jurisdiction of residence) to participate in games and place bets.
            For this reason, confirmation of age and date of birth are mandatory
            requirements during registration. {BRAND_NAME} takes the issue of
            minors taking part in games and betting very seriously. In order to
            offer the best possible protection of minors, {BRAND_NAME} also
            relies on the support of their parents and caregivers. {BRAND_NAME}{' '}
            advises its customers to keep their data for account access in a
            safe place (user ID and password) and to not disclose this data to
            anyone.
          </p>

          <p>
            Furthermore, {BRAND_NAME} would recommend that customers install
            filter software. This software will allow customers to restrict the
            access to Internet resources inappropriate for children and
            teenagers.
          </p>

          <h1 className="rules-para-header">Responsibility towards problems</h1>

          <p>
            {BRAND_NAME} offers a variety of games and bets which are forms of
            entertainment for the majority of customers. At the same time, the
            company takes responsibility for its customers by providing support
            and tools for maintenance of a secure and entertaining environment,
            taking into account the associated risks.
          </p>

          <p>
            Clients who have difficulty in assessing risks, recognizing their
            own limits or those who suffer from problem gambling are potentially
            not able to enjoy the product line-up responsibly and do not
            perceive it as a form of entertainment. {BRAND_NAME} may take
            responsibility for these users by blocking their access to certain
            products for their own protection.
          </p>

          <h1 className="rules-para-header">
            Be informed about the main issues!
          </h1>

          <p>
            Most people play for pleasure and enjoy moderate participation in
            games within their financial capacity. However, for a small
            percentage of people gambling is not a form of entertainment and
            this is a challenge that must be considered seriously.
          </p>

          <h1 className="rules-para-header">
            What is the problematic gambling behaviour?
          </h1>

          <p>
            Problematic gambling behaviour is considered to be that which
            interferes with mode of life, work, financial position or health of
            a person or his family. Prolonged participation in gambling is
            counterproductive to such person as it can lead to negative
            consequences.
          </p>

          <p>
            In 1980 pathological game dependence has been officially recognized
            and enlisted in the list of psychological diseases of international
            classification system DSM-IV and ICD-10. It is defined as long,
            repeating and frequently amplifying inclination for game, despite of
            existing negative personal and social circumstances, such as a debt,
            rupture of family relations and delay of professional growth.
          </p>

          <h1 className="rules-para-header">
            What type of gambling behaviours can be considered problematic?
          </h1>

          <p>
            Whilst the diagnoses of problem gambling can only be qualified by
            experts, the material presented on this web-page will help customers
            estimate and define their own gambling behaviour.
          </p>

          <p>
            Addictions that are not associated with any substance are very
            difficult to define the line between pleasure and problem.
            Nevertheless, there are some diagnostic signals that may indicate
            existing issues. Customers that experience at least five of the
            following symptoms are more likely to be suffering from problem
            gambling:
          </p>

          <p>
            1.The player is fully immersed in gambling and appears to think of
            nothing else,<br></br>
            2.Bet stakes increase, particularly if on a losing streak,<br></br>
            3.Attempts to quit or control gambling appear to be unsuccessful,
            <br></br>
            4.Shows signs of anxiety, irritation and disappointment when
            limiting gambling,<br></br>
            5.The player treats gambling as a means of escape from problems or
            life issues,<br></br>
            6.Regularly chases losses,<br></br>
            7.Lies about his playing behaviour,<br></br>
            8.Commits illegal acts,<br></br>
            9.Arguments or strained or broken relationships with family and
            colleagues,<br></br>
            10.Borrows funds to participate in gambling.
          </p>
          <h1 className="rules-para-header">
            Note
          </h1>
          <p>
           1.players using VPN and login from different IP frequently may result to void bets.<br></br>
           2.And on the basis of different IP from multiple city we can suspend the account and void bets.
          </p>
          <h1 className="rules-para-header">
            Casino Payout Restrictions
          </h1>
          <p>
           1.Restriction of Payout is Applicable for all casino games<br></br>
           2.In single round ,user is eligible for a max payout of 100 times his bet amount , example if the bet is 100 then max payout shall be 100 x 100 = 10000, any winning above this multiplier shall not be paid out by the company.<br></br>
           3.Another restriction is max payout amount is capped at 2,00,00,000 ( 2crore points ) , if netwinning amount is beyond this amount then user shall be paid only this amount as max winning in casino games.
          </p>
          <h1 className="rules-para-header">
            Suggestions for responsible gambling
          </h1>

          <p>
            By following the simple rules below, you can enjoy the game without
            anxiety:
          </p>

          <p>
            1.Do not think of gambling as a way to make money or as a
            distraction from everyday problems.<br></br>
            2Take regular breaks. Set a time limit and when the time is up,
            quit. The odds are that the more time you spend gambling, the more
            you will lose.<br></br>
            3.Only gamble with money you can afford to lose, not money you have
            set aside for bills,<br></br>
            4.Set a monthly money limit in advance and stick with it.<br></br>
            5.Once setting a maximum limit, do not further increase it.<br></br>
            6.Never chase your losses, if you set a limit and then try and win
            some of it back after you reached it, you haven’t set a limit.
            <br></br>
            7.Do not play under the influence of alcohol or drugs.<br></br>
            8.Do not gamble when you are depressed or upset, decision making can
            be more difficult when you are stressed or emotionally upset.
          </p>
          <h1 className="rules-para-header">
            Self-Exclusion, Time-Outs and Reality Checks
          </h1>

          <p>
            {BRAND_NAME} provides Self-Exclusion, Time Out and Reality Check
            facilities to assist our customers with managing their gambling.
            Self-exclusion can be applied for periods at the customers
            discretion from six (6) months to five (5) years.
          </p>

          <p>
            If the self-exclusion option is taken it is understood that
            customers may not access their account until the expiry of the
            self-exclusion period. The Account cannot be re-opened within the
            exclusion period. We will do everything we can to prevent a new
            account from an excluded customer being opened during the period of
            self-exclusion. During the self-exclusion, {BRAND_NAME} will
            endeavour to not send any marketing and/or promotional material.
          </p>

          <p>
            Self-excluded accounts can only be re-opened if by contacting
            customer support after the self-exclusion period has expired and a
            24 hour “cooling off” period is served.
          </p>
          <p>
            When self-excluding any open-bets will remain and any winnings paid
            out and any balance in the account will be refunded.
          </p>

          <p>
            {BRAND_NAME} customers can apply a Reality Check to their account
            for periods of 10 minutes, 20 minutes, 30 minutes 1 hour, 2 hours, 4
            hours, 6 hours and 8 hours. Customers can apply these limits
            themselves through their account or by contacting customer support.
          </p>
          <p>
            Should customers wish to permanently close their account due to
            problem gambling, they should contact customer support as soon as
            possible. Once this is done customers will not be able to re-open
            their account under any circumstances.
          </p>

          <p>
            {BRAND_NAME} customers can apply a Time-out to their account for
            periods of 24 hours, one week, one month; or two months. Customers
            can apply these limits themselves through their account or by
            contacting customer support.
          </p>
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default ResponsibleGambling;
