export const CURRENCY_TYPE_FACTOR = {
  0: 1,
  1: 100,
  2: 100,
  3: 1,
};

export const IC_PARTNER_BY_CURRENCY_TYPE = {
  0: "jeraminr",
  1: "jeramhkd",
  2: "jeramusd",
  3: "jeraminr",
};
