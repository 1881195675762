import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import './IndCasinoIframe.scss';
import { isMobile, isIOS } from 'react-device-detect';
import { RootState } from '../../../models/RootState';
// import LoadingPage from '../../../LoadingPage/LoadingPage';

type RouteParams = {
  gameCode: string;
};

type StoreProps = {
  gameUrl: string;
  loggedIn: boolean;
};

const IndCasinoIframeNew: React.FC<StoreProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { loggedIn } = props;
  const history = useHistory();
  const claims = sessionStorage.getItem('jwt_token').split('.')[1];
  const userStatus = JSON.parse(window.atob(claims)).status;
  const { gameCode } = useParams<RouteParams>();

  // Iframe urls
  const mobileIframeUrl = `https://m2.fawk.app/#/splash-screen/${sessionStorage.getItem(
    'jwt_token'
  )}/9372?opentable=${gameCode}`;
  const webIframeUrl = `https://d2.fawk.app/#/splash-screen/${sessionStorage.getItem(
    'jwt_token'
  )}/9372?opentable=${gameCode}`;
  // 9372 - INR
  // 9373 - HKD

  useEffect(() => {
    if (!loggedIn) {
      history.push('/');
    }

    if (userStatus === 0 || userStatus === 3) {
      return history.push(`/home`);
    }

    setTimeout(() => {
      if (loggedIn && isIOS && !window.location.href.includes('live')) {
        const url = window.location.href.replace('live', 'live');
        window.location.href = url;
      }
      setLoading(false);
    }, 800);
  }, []);

  return (
    <div className="ind-casino-iframe-ctn">
      {loading && isIOS && userStatus === 1 ? (
        <></>
      ) : (
        <>
          {isMobile ? (
            <iframe
              src={mobileIframeUrl}
              title="DC game"
              sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
            ></iframe>
          ) : (
            <iframe
              src={webIframeUrl}
              title="DC game"
              sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
            ></iframe>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    gameUrl: state.common.dcGameUrl,
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(IndCasinoIframeNew);
