import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonRow } from '@ionic/react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MomentUtils from '@date-io/moment';
import RedeemIcon from '@material-ui/icons/Redeem';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import API from '../../api/index';
import { getCurrencyTypeFromToken } from '../../store';
import { FundTransferRecord } from '../../models/FundTransferRecord';
import { AuthResponse } from '../../models/api/AuthResponse';
import { MarketCommisionBonus } from '../../models/MarketCommisionInfo';
import Spinner from '../../components/Spinner/Spinner';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { TableSortLabel } from '@material-ui/core';
import { Currency } from '../../models/Currency';
import { RootState } from '../../models/RootState';
import MyWalletImg from '../../assets/images/icons/wallet.svg';
// import ToastMsg from '../../components/ToastMsg/ToastMsg';
import { CONFIG_PERMISSIONS } from '../../constants/ConfigPermission';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import './MyWallet.scss';
import Copyright from '../../components/Copyright/Copyright';
import SVLS_API from '../../svls-api';

type StoreProps = {
  allowedConfig: number;
  commissionEnabled: boolean;
  balance: number;
};

const MyWallet: React.FC<StoreProps> = (props) => {
  const { allowedConfig, commissionEnabled, balance } = props;
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState<Boolean>(true);
  const [records, setRecords] = useState<FundTransferRecord[]>([]);
  const [sortDesc, setSortDesc] = useState<Boolean>(true);
  const [pageNum, setPageNum] = useState<number>(1);
  const [fromDate, setfromDate] = useState<Moment>(moment().subtract(7, 'd'));
  const [toDate, setToDate] = useState<Moment>(moment());
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
  const [marketComisBonusInfo, setMarketComisBonusInfo] =
    useState<MarketCommisionBonus>(null);
  const sortOrderHandler = () => {
    setLoading(true);
    setSortDesc(!sortDesc);
  };

  const fromDateChangeHandler = (d: Moment) => {
    setfromDate(d);
  };

  const toDateChangeHandler = (d: Moment) => {
    setToDate(d);
  };

  const fetchRecords = useCallback(async () => {
    setLoading(true);
    setErrorMsg(null);

    try {
      const response: AuthResponse = await API.get('/user/reports/wallet', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
        params: {
          sortDesc: sortDesc,
          pageNum: pageNum,
          dateFrom: fromDate.startOf('day').toISOString(),
          dateTo: toDate.endOf('day').toISOString(),
          currencyType: null,
        },
      });
      if (response.status === 200) {
        setRecords(response.data);
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error(response.data);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setErrorMsg(err.response.data.error);
      }
      setLoading(false);
    }
  }, [sortDesc, pageNum, fromDate, toDate]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  const claimBonus = async () => {
    setErrorMsg(null);

    try {
      const response: AuthResponse = await SVLS_API.post(
        `/wallet/v2/wallets/${sessionStorage.getItem('aid')}/:claim-incentive`,
        {},
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 200 && response?.data?.success) {
        toast.success(<div>{response.data.message}</div>, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          // isUserHaveclaimBonus();
        }, 4000);
      } else {
        setErrorMsg(response.data.message);
        toast.error(<div>{response.data.message}</div>, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      setErrorMsg('Something went wrong');
    }
  };

  const isUserHaveclaimBonus = async () => {
    try {
      const response: AuthResponse = await API.post(
        '/admin/get-commission-amount',
        {},
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
          params: {
            username: sessionStorage.getItem('username'),
          },
        }
      );
      if (response.status === 200) {
        setMarketComisBonusInfo(response.data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    // isUserHaveclaimBonus();
  }, []);

  return (
    <>
      <div className="reports-ctn my-wallet-ctn">
        <>
          <div className="header-ctn my-wallet-header">
            <IonRow className="header-ctn-row">
              <div className="img-page-title-row">
                <div className="title">My Wallet</div>
              </div>
              <div className="title-border-line"></div>
            </IonRow>

            <div className="user-wallet-filters-row filters-row">
              <div className="date-section">
                <div className="date-filter ml-12">
                  <div className="date-filter-label">From</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      className="date-filter-control"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      minDate={moment().subtract(1, 'months').calendar()}
                      margin="normal"
                      id="from-date"
                      value={fromDate}
                      onChange={(e) => fromDateChangeHandler(e)}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="date-filter mr-12">
                  <div className="date-filter-label">To</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      className="date-filter-control"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      minDate={moment().subtract(1, 'months').calendar()}
                      maxDate={moment()}
                      margin="normal"
                      id="to-date"
                      value={toDate}
                      onChange={(e) => toDateChangeHandler(e)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>

          <div className="content-ctn light-bg">
            <div className="balance-history-tbl-ctn">
              {errorMsg ? <div className="err-msg"> {errorMsg}</div> : ''}
              {loading ? (
                <Spinner />
              ) : (
                <div className="tbl-ctn">
                  <TableContainer className="tbl-paper-ctn" component={Paper}>
                    <Table className="tbl-ctn my-wallet-tbl">
                      <TableHead>
                        {/* <TableRow> */}
                        <TableCell className="web-view" align="center">
                          Date
                          <IconButton
                            aria-label="Change Order"
                            size="medium"
                            onClick={() => sortOrderHandler()}
                          >
                            {sortDesc ? (
                              <TableSortLabel active={true} direction="desc" />
                            ) : (
                              <TableSortLabel active={true} direction="asc" />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="th-col transaction-col"
                        >
                          Transaction
                        </TableCell>
                        <TableCell align="center" className="web-view">
                          {/* From */}
                        </TableCell>
                        <TableCell align="center" className="web-view">
                          {/* <ArrowForwardIcon /> */}
                        </TableCell>
                        <TableCell align="center" className="web-view">
                          {/* To */}
                        </TableCell>
                        <TableCell align="center" className="th-col">
                          Credit/Debit
                        </TableCell>
                        <TableCell align="center" className="th-col">
                          Balance
                        </TableCell>

                        <TableCell align="center" className="web-view">
                          Description
                        </TableCell>
                        {/* </TableRow> */}
                      </TableHead>
                      {records.length > 0 ? (
                        <TableBody className="tbl-body">
                          {records.map((row) => (
                            <TableRow className="tb-row">
                              <TableCell
                                component="th"
                                scope="row"
                                className="web-view"
                                align="center"
                              >
                                {moment(row.transactionTime).format(
                                  'DD-MM-YY, h:mm:ss A'
                                )}
                              </TableCell>
                              <TableCell
                                className="tb-col transaction-col"
                                align="center"
                              >
                                <div className="web-view">
                                  {row.transactionType}
                                </div>

                                <div className="mob-view">
                                  <div className="b-text from-to-cell">
                                    <span className="mob-fs-14">
                                      {row.amount > 0
                                        ? row.addedBy
                                        : row.username}
                                    </span>
                                    {row.amount > 0 ? (
                                      <ArrowForwardIcon />
                                    ) : null}
                                    <span className="mob-fs-14">
                                      {row.amount > 0
                                        ? row.username
                                        : row.addedBy}
                                    </span>
                                  </div>
                                  <div className="tiny-info-text">
                                    {row.description}
                                  </div>
                                  <div className="col-data-desc tiny-info-text">
                                    {moment(row.transactionTime).format(
                                      'DD-MM-YY, h:mm A'
                                    )}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className="web-view" align="center">
                                {row.amount > 0 ? row.addedBy : row.username}
                              </TableCell>
                              <TableCell className="web-view" align="center">
                                {/* <ArrowForwardIcon /> */}
                              </TableCell>

                              <TableCell className="web-view" align="center">
                                {row.amount > 0 ? row.username : row.addedBy}
                              </TableCell>
                              <TableCell align="center" className="mob-fs-14">
                                <span
                                  className={
                                    row.amount > 0
                                      ? 'tb-col profit'
                                      : row.amount < 0
                                      ? 'tb-col loss'
                                      : 'tb-col'
                                  }
                                >
                                  {row.amount > 0
                                    ? '+' +
                                      //   Number(row.amount / cFactor).toFixed(2)
                                      // : Number(row.amount / cFactor).toFixed(2)}
                                      Number(row.amount).toFixed(2)
                                    : Number(row.amount).toFixed(2)}
                                </span>
                              </TableCell>
                              <TableCell className="tb-col" align="center">
                                <span className="mob-fs-13">
                                  {Number(row.balanceAfter).toFixed(2)}
                                </span>
                              </TableCell>

                              <TableCell className="web-view" align="center">
                                {row.description}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <TableCell className="no-data-row" colSpan={9}>
                          <div>You don't have any transactions</div>
                        </TableCell>
                      )}
                    </Table>
                  </TableContainer>

                  <IonRow className="mt-10">
                    {pageNum === 1 || loading ? null : (
                      <Button
                        variant="contained"
                        className="myb-btn-prev"
                        onClick={(e) => {
                          setPageNum(pageNum - 1);
                        }}
                      >
                        Prev({pageNum - 1})
                      </Button>
                    )}
                    {records.length === 10 && !loading ? (
                      <Button
                        variant="contained"
                        className="myb-btn-next btn-primary"
                        onClick={(e) => setPageNum(pageNum + 1)}
                      >
                        Next({pageNum + 1})
                      </Button>
                    ) : null}
                  </IonRow>
                </div>
              )}
            </div>
          </div>
        </>
      </div>
      <Copyright />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    balance: state.auth.balanceSummary.balance,
    allowedConfig: state.common.allowedConfig,
    commissionEnabled: state.common.commissionEnabled,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyWallet);
