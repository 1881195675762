import React from 'react';
import { IonTitle } from '@ionic/react';
import './ElectionPage.scss';

const ElectionPage: React.FC = () => {
  return (
    <div className="election-ctn">
      <IonTitle className="title">Coming soon</IonTitle>
    </div>
  );
};

export default ElectionPage;
