import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { RootState } from '../../models/RootState';
import {
  fetchBalance,
  logout,
  toggleDarkMode,
  setSearchGame,
  handleSearchGame,
  getCurrencyTypeFromToken,
  overwriteBalacne,
} from '../../store';
import { CONFIG_PERMISSIONS } from '../../constants/ConfigPermission';
import './MobileSubHeader.scss';
import { Tabs, Button, Backdrop, Menu, MenuItem, Divider } from '@material-ui/core';
import logo from '../../assets/images/brand/logo.svg';
import { IonButtons, IonImg, IonIcon, IonItem, IonSelect, IonSelectOption, IonLabel } from '@ionic/react';
import themeIcon from '../../assets/images/common/theme.svg';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import RefreshIcon from '@material-ui/icons/RefreshRounded';
import Modal from '../../components/Modal/Modal';
import LoginModal from '../../components/Modal/LoginModal';
import LoginPage from '../../components/LoginForm/LoginPage';
import ChangePwdForm from '../../components/ChangePassword/ChangePassword';
import HomeView from '../../constants/HomeView';
import {ReactComponent as Indian} from '../../assets/images/home/homeview/indian.svg'
import {ReactComponent as Roulette} from '../../assets/images/home/homeview/roulette.svg'
import {ReactComponent as Others} from '../../assets/images/home/homeview/others.svg'
import {ReactComponent as Poker} from '../../assets/images/home/homeview/poker.svg'
import {ReactComponent as Blackjack} from '../../assets/images/home/homeview/blackjack.svg'
import {ReactComponent as Bacarrat} from '../../assets/images/home/homeview/baccarat.svg'
import User from "../../assets/images/home/homeview/user.png"
import Notifications from "../../assets/images/home/homeview/Notifications.svg"
import Balance from "../../assets/images/home/homeview/Balance.svg"
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type StoreProps = {
  balance: number;
  allowedConfig: number;
  loggedIn: boolean;
  logout: Function;
  toggleDarkMode: (val: string) => void;
  prefersDark: string;
  setSearchGame: Function;
  searchGame: string;
  handleSearchGame: () => void;
  exposure: number;
  overwriteBalacne: () => void;
};

const MobileSubHeader: React.FC<StoreProps> = (props) => {
  const {
    allowedConfig,
    loggedIn,
    balance,
    logout,
    exposure,
    toggleDarkMode,
    prefersDark,
    setSearchGame,
    searchGame,
    handleSearchGame,
    overwriteBalacne
  } = props;

  const [casinoGameTabValue, setCasinoGameCatTabValue] = useState<number>(0);
  const [refreshingBalance, setRefreshingBalance] = useState<boolean>(false);
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
  const [changePwdMOdal, setChangePwdMOdal] = useState<boolean>(false);
  const path = useHistory();
  const history = useHistory();
  const [tabValue, setTabValue] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const tabsList = HomeView.CARDS_SLIDER_IMAGES;




  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };





  const setThemeHandler = () => {
    if (prefersDark === 'light') {
      localStorage.setItem('userTheme', 'dark');
      toggleDarkMode('dark');
    } else {
      localStorage.setItem('userTheme', 'light');
      toggleDarkMode('light');
    }
  };

  const loginHandler = () => {
    setModalOpen(true);
    setShowPopover({ open: false, event: undefined });
  };

  const modalCloseHandler = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="mobile-sub-header app-sub-header web-view">
        <Tabs
          value={1}
          variant="scrollable"
          scrollButtons="auto"
          className="actions-list"
        >
          <div className="left-side">
            
            <NavLink
              className={`card`}
              to={'/home/indian'}
            >
              {/*  */}
                <Indian className='indian-svg'/>
              {/* </div> */}
              <div className="label-ctn">
                <div className={'label'}>Indian</div>{' '}
              </div>
            </NavLink>
            <NavLink
              className={`card`}
              to={'/home/roulette'}
            > 
                <Roulette />              
              <div className="label-ctn">
                <div className={'label'}>Roulette</div>{' '}
              </div>
            </NavLink>
            <NavLink
              className={`card`}
              to={'/home/baccarat'}
            >
                <Bacarrat />
              <div className="label-ctn">
                <div className={'label'}>Baccarat</div>{' '}
              </div>
            </NavLink>
            <NavLink
              className={`card`}
              to={'/home/blackjack'}
            >
              <Blackjack />
              <div className="label-ctn">
                <div className={'label'}>Blackjack</div>{' '}
              </div>
            </NavLink>
            <NavLink
              className={`card`}
              to={'/home/poker'}
            >
              <Poker />
              
              <div className="label-ctn">
                <div className={'label'}>Poker</div>{' '}
              </div>
            </NavLink>
            <NavLink
              className={`card`}
              to={'/home/others'}
            >
              <Others />
              <div className="label-ctn">
                <div className={'label'}>Others</div>{' '}
              </div>
            </NavLink>
          </div>
        </Tabs>
      </div>
      <Backdrop className="backdrop-ctn" open={modalOpen}>
        <LoginModal
          open={modalOpen}
          closeHandler={modalCloseHandler}
          noTitle={true}
          size="md"
          customClass={'login-modal-ctn'}
        >
          <LoginPage
            modalCloseHandler={modalCloseHandler}
            tabActive={tabValue}
          ></LoginPage>
        </LoginModal>
      </Backdrop>

      <Backdrop className="backdrop-ctn" open={changePwdMOdal}>
        <Modal
          open={changePwdMOdal}
          closeHandler={() => setChangePwdMOdal(false)}
          title="Change Password"
          size="xs"
        >
          <ChangePwdForm
            closeHandler={() => setChangePwdMOdal(false)}
            backHandler={() => { }}
          />
        </Modal>
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    allowedConfig: state.common.allowedConfig,
    loggedIn: state.auth.loggedIn,
    prefersDark: state.common.prefersDark,
    searchGame: state.indianCasino.searchGame,
    balance: state.auth.balanceSummary.balance,
    exposure: state.auth.balanceSummary.exposure,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    logout: () => dispatch(logout()),
    toggleDarkMode: (val: string) => dispatch(toggleDarkMode(val)),
    setSearchGame: (data: string) => dispatch(setSearchGame(data)),
    handleSearchGame: () => dispatch(handleSearchGame()),
    overwriteBalacne: () => dispatch(overwriteBalacne()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileSubHeader);
