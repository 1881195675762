import React, { useEffect, useState } from 'react';
import { IonRow, IonCol } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import './CasinoNew.scss';
import { RootState } from '../../../models/RootState';
import { GapGame } from '../../../models/dc/DcGame';
import { FatchLiveCasinoGames } from '../../../store';
import { Backdrop } from '@material-ui/core';
import Footer from '../../../components/Footer/Footer';
import inplay from '../../../assets/images/common/Banner/Polygon.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import LoginModal from '../../../components/Modal/LoginModal';
import LoginForm from '../../../components/LoginForm/LoginForm';
import { AxiosResponse } from 'axios';
import API from '../../../api';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
type StoreProps = {
  loggedIn: boolean;
  loggedInUserStatus: any;
  FatchLiveCasinoGames: (type: string) => void;
  liveCasinoGames: GapGame[];
};

const FavouritesGames: React.FC<StoreProps> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [favourites, setFavourites] = useState<any[]>([]);
  const {
    loggedIn,
    loggedInUserStatus,
    FatchLiveCasinoGames,
    liveCasinoGames,
  } = props;

  const history = useHistory();
  const [infoDialog, setInfoDialog] = useState<boolean>(false);
  const setDialogShow = (show: boolean) => {
    setShowDialog(show);
  };

  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    provider: string,
    subProvider: string
  ) => {
    if (loggedInUserStatus === 0 || loggedInUserStatus === 3) {
      history.push(`/home`);
    }
    if (provider === 'Indian Casino') {
      history.push(`/casino/indian/${gameCode}`);
    } else {
      history.push(
        `/gap_casino/game/${gameName.toLowerCase().replace(/\s+/g, '-')}-${btoa(
          gameId.toString()
        )}-${btoa(gameCode)}-${btoa(provider)}-${btoa(subProvider)}`
      );
    }
  };

  const handleGameClick = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    subProvider: string,
    provider?: string
  ) => {
    if (loggedIn) {
      getGameUrl(gameId, gameName, gameCode, provider, subProvider);
    } else {
      setDialogShow(true);
    }
  };

  useEffect(() => {
    getFavourites();
  }, []);

  const getFavourites = async () => {
    if (!loggedIn) {
      return;
    }
    let response;
    try {
      response = await API.post('/user/favorite-games-list', null, {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
      setFavourites(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  const updateFavourites = async (gameId: string, isFavourite: boolean) => {
    if (!loggedIn) {
      return;
    }
    try {
      let response: AxiosResponse = await API.post(
        '/user/update-favorite-game',
        {
          addGame: !isFavourite,
          gameId: gameId,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.data.success) {
        getFavourites();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="dc-page-bg">
      <div className="dc-ctn">
        <IonRow class="casino-filters-row">
          <div className="title">Favourites</div>
        </IonRow>

        {favourites && favourites.length > 0 ? (
          <>
            <IonRow className="dc-games-ctn">
              {favourites.map((g, i) => (
                <IonCol
                  sizeXs="4"
                  sizeSm="4"
                  sizeMd="2"
                  sizeLg="2"
                  key={g.gameName}
                >
                  <div className="container1">
                    <img
                      src={g.urlThumb ? g.urlThumb : ''}
                      alt={g.gameName}
                      className="dc-img"
                    />
                    <div className="game-name">
                      <span className="name">{g.gameName}</span>
                      <span className="p-name">{g.subProviderName}</span>
                    </div>
                    <div className="overlay1">
                      <div className="text1">
                        <div
                          className="custom-btn"
                          onClick={() =>
                            handleGameClick(
                              g.gameId,
                              g.gameName,
                              g.gameCode,
                              g.subProviderName,
                              g.providerName
                            )
                          }
                        >
                          <img src={inplay} alt="inplay" />
                          <div> PLAY NOW </div>
                        </div>
                      </div>
                      {loggedIn && (
                        <div className="fav-icon">
                          <div
                            className="custom-btn"
                            onClick={() => updateFavourites(g.gameId, true)}
                          >
                            <FavoriteIcon htmlColor="red" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </IonCol>
              ))}
            </IonRow>
          </>
        ) : null}
      </div>

      {/* <Footer /> */}

      <Backdrop className="backdrop-ctn" open={showDialog}>
        <LoginModal
          open={showDialog}
          closeHandler={() => setDialogShow(false)}
          noTitle={true}
          size="xs"
          customClass={'login-modal-ctn'}
        >
          <LoginForm modalCloseHandler={() => setDialogShow(false)}></LoginForm>
        </LoginModal>
      </Backdrop>

      {/* <Dialog
        open={infoDialog}
        onClose={() => setInfoDialog(false)}
        aria-labelledby="form-dialog-title"
        className="dialog-div"
      >
        <div className="dialog-div-body">
          <div className="go-corner" onClick={() => setInfoDialog(false)}>
            <div className="go-arrow">
              <ClearIcon />
            </div>
          </div>
          <div className="dialog-title">
            <StarBorderRoundedIcon />
            Welcome
            <StarBorderRoundedIcon />
          </div>

          {getCurrencyTypeFromToken() === 0 ? (
            <div className="dialog-body">
              1 Live casino point is equal to 10 normal points
              <div className="dialog-ex">
                {' '}
                Ex: 100 casino points = 1000 normal points
              </div>
              <p className="dialog-ex">
                Maximum Bet limit is 50000 casino points
              </p>
            </div>
          ) : null}

          {getCurrencyTypeFromToken() === 1 ? (
            <>
              {' '}
              <div className="dialog-body">
                <div className="dialog-ex">
                  {' '}
                  Maximum Bet Limit For All Games is 5000
                </div>
              </div>
            </>
          ) : null}

          <div
            className="dialog-continue"
            onClick={() => {
              getGameUrl(
                selectedEvolutionGame?.gameId,
                selectedEvolutionGame?.gameName,
                selectedEvolutionGame?.gameCode,
                selectedEvolutionGame?.provider,
                selectedEvolutionGame?.subProvider
              );
            }}
          >
            <Button className="btn">Continue</Button>
          </div>
        </div>
      </Dialog> */}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  let status = 0;
  if (state.auth.loggedIn) {
    status = JSON.parse(
      window.atob(sessionStorage.getItem('jwt_token').split('.')[1])
    ).status;
  }
  return {
    loggedIn: state.auth.loggedIn,
    loggedInUserStatus: status,
    liveCasinoGames: state.indianCasino.liveCasinoGames,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    FatchLiveCasinoGames: (type: string) =>
      dispatch(FatchLiveCasinoGames(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FavouritesGames);
