import React from 'react';
import { BRAND_DOMAIN } from '../../constants/Branding';

import './HelpView.scss';

const HelpView: React.FC = () => {
  return (
    <div className="help-text">
      <div className="help-header">HELP</div>
      <div className="help-line">LIVE CHAT</div>
      <div className="help-line">
        Our 24/7 Live Support service is always happy to help you.
      </div>
      <div className="help-line"> EMAIL</div>
      <div className="help-line">
        For all customer queries, please email us at support@{BRAND_DOMAIN} and
        quote your name.
      </div>
      <div className="help-line">
        For all other questions or offers, please email us on info@
        {BRAND_DOMAIN}.
      </div>
    </div>
  );
};

export default HelpView;
