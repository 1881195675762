export interface UserSettings {
  asianView: boolean;
  quickBet: boolean;
  enableDarkTheme: boolean;
  enableEmails: boolean;
  oddsChangeType: OddsChangeType;
  oddsDisplayType: OddsDisplayType;
  timeZone: string;
}

export enum OddsChangeType {
  any = 'any',
  higher = 'higher',
  none = 'none',
}

export enum OddsDisplayType {
  American = 'American',
  Decimal = 'Decimal',
}
