import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { RootState } from '../../../models/RootState';
import './DcIframe.scss';
import API from '../../../api';
import { DcGameUrl } from '../../../models/dc/DcGameUrl';
import { BRAND_DOMAIN } from '../../../constants/Branding';

type StoreProps = {
  gameUrl: string;
  loggedIn: boolean;
};

type RouteParams = {
  gamePath: string;
};

const DcIframe: React.FC<StoreProps> = (props) => {
  const [gameSrc, setGameSrc] = useState<string>('');

  const { loggedIn } = props;

  const { gamePath } = useParams<RouteParams>();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const getGameUrl = async (gameId: string) => {
    if (loggedIn) {
      const claims = sessionStorage.getItem('jwt_token').split('.')[1];
      const userStatus = JSON.parse(window.atob(claims)).status;
      if (userStatus === 0 || userStatus === 3) {
        return history.push(`/home`);
      }

      const tokenResponse = await API.get('/dc/game/token', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });

      const dcToken = tokenResponse.data.message;
      const userId = JSON.parse(window.atob(claims)).userId;
      const response: AxiosResponse<DcGameUrl> = await API.post(
        '/dc/game/url',
        {
          user: userId,
          token: dcToken,
          platform: isMobile ? 'GPL_MOBILE' : 'GPL_DESKTOP',
          lobby_url: `https://www.${BRAND_DOMAIN}/casino`,
          lang: 'en',
          ip: '124.123.175.85',
          game_id: gameId,
          currency: 'INR',
          country: 'IN',
        }
      );

      setGameSrc(response.data.url);
    } else {
      history.push(`/`);
    }
  };

  useEffect(() => {
    const gameId = atob(gamePath.split('-')[gamePath.split('-').length - 1]);
    getGameUrl(gameId);
  }, []);

  return (
    <div className="dc-iframe-ctn">
      <iframe
        src={gameSrc}
        title="DC game"
        sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
      ></iframe>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    gameUrl: state.common.dcGameUrl,
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(DcIframe);
