import React, { useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import ChangePwdForm from '../../components/ChangePassword/ChangePassword';

const ResetPasswordPage: React.FC = () => {
  const [changePwdModal, setChangePwdModal] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  return (
    <>
      <Backdrop className="backdrop-ctn" open={changePwdModal}>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          open={changePwdModal}
          aria-labelledby="responsive-dialog-title"
          maxWidth="xs"
          className='reset-pwd-modal'
        >
          <DialogTitle className="modal-title" id="responsive-dialog-title">
            Set your password
          </DialogTitle>
          <DialogContent className="modal-content-ctn">
            <ChangePwdForm
              closeHandler={() => {
                history.push('/');
                setChangePwdModal(false);
              }}
              backHandler={() => {
                setChangePwdModal(false);
              }}
            />
          </DialogContent>
        </Dialog>
      </Backdrop>
    </>
  );
};

export default ResetPasswordPage;
