import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonCol, IonRow, IonSpinner, IonTitle } from '@ionic/react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import axios from 'axios';
import { useHistory } from 'react-router-dom';

import './LoginForm.scss';
import {
  fetchBalance,
  loginSuccess,
  loginFailed,
  requestStart,
  requestEnd,
} from '../../store';
import { RootState } from '../../models/RootState';
import { IonLabel } from '@ionic/react';
import API from '../../api';
import ForgotPwdForm from '../ForgotPassword/ForgotPassword';
import Modal from '../Modal/Modal';
// import ReCAPTCHA from 'react-google-recaptcha';
// Password Encryptioon
import JSEncrypt from 'jsencrypt';
import { RsaPublicKey } from '../../constants/RSAPublicKey';
import { LogoByTheme, demoLoginWLs, demoUser } from '../../util/stringUtil';
import { ReactComponent as Logo } from "../../assets/images/brand/title.svg";
import { makeStyles } from '@material-ui/core';
import SVLS_API from '../../svls-api';
import { BRAND_NAME } from '../../constants/Branding';

type StoreProps = {
  loginSuccess: Function;
  loginFailed: Function;
  requestStart: Function;
  requestEnd: Function;
  fetchBalance: Function;
};

type LoginProps = StoreProps & {
  errorMsg: string;
  loading: boolean;
  loggedIn: boolean;
  modalCloseHandler: Function;
  redirectUrl?: string;
};

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      fontSize: "16px",
      fontFamily: 'Montserrat',
      fontWeight: "400",
      lineHeight: "16px",
      color: "#fff",
      opacity: "0.5"
    }
  }
})

const LoginForm: React.FC<LoginProps> = (props) => {
  const {
    errorMsg,
    loading,
    loggedIn,
    loginSuccess,
    loginFailed,
    requestStart,
    requestEnd,
    fetchBalance,
    modalCloseHandler,
    redirectUrl,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [loginResponse, setLoginResponse] = useState(null);
  const [showForgotPwdModal, setShowForgotPwdModal] = useState(false);
  const [loadLogin, setLoadLogin] = useState(false); 
  const [demoLoading, setDemoLoading] = useState(false);

  let history = useHistory();

  // const forgotPwdHandler = () => {
  //   setShowForgotPwdModal(true);
  // };

  const handleDemoLogin = async () => {
    setDemoLoading(true);
    setLoadLogin(false);
    requestStart();
    try {
      const response = await SVLS_API.post('/account/v2/login-demo');
      setDemoLoading(false);
      if (response.status == 200) {
        setLoginResponse(response.data);
        requestEnd();
        sessionStorage.setItem('username', demoUser.toLowerCase());
        sessionStorage.setItem('jwt_token', response.data);
        reload();
        localStorage.removeItem(`multiMarket_${demoUser.toLowerCase()}`);
        loginSuccess(response.data);
        let claim = response.data.split('.')[1];
        let status = JSON.parse(window.atob(claim)).sts; sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      }
    } catch (err) {
      setDemoLoading(false);
      console.log(err);
    }
  }

  const reload = () => {
    window.location.reload()
  }

  const formik = useFormik({
    initialValues: { username: '', password: '', code: '' },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string(),
      code: Yup.string(),
    }),
    onSubmit: async (values) => {
      requestStart();

      try {
        const loginRequest = {
          username: values.username.toLowerCase(),
          password: values.password.trim()
        };
        const response = await SVLS_API.post('/account/v2/login', loginRequest);

        setLoginResponse(response.data);
        requestEnd();

        sessionStorage.setItem('username', values.username.toLowerCase());
        sessionStorage.setItem('jwt_token', response.data);

        localStorage.removeItem(
          `multiMarket_${values?.username?.toLowerCase()}`
        );
        reload();
        loginSuccess(response.data);

        let claim = response.data.split('.')[1];
        let status = JSON.parse(window.atob(claim)).sts;

        sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);

        if (status === 2) {
          history.replace('/terms-and-conditions');
        } else if (status === 4) {
          history.replace('/reset-password');
        }

        fetchBalance();

        if (redirectUrl) {
          console.log(redirectUrl);
          history.push(redirectUrl);
        }
      } catch (err) {
        loginFailed(err.response.data.message);
      } finally {
        requestEnd();
      }
    },
  });

  const uniqueGuid = (): string => {
    const id = () => {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      id() +
      id() +
      '-' +
      id() +
      '-' +
      id() +
      '-' +
      id() +
      '-' +
      id() +
      id() +
      id()
    );
  };

  // useEffect(() => {
  //   if (loggedIn) {
  //     fetchBalance();
  //   }
  // }, [fetchBalance, loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      modalCloseHandler();
    }
  }, [loggedIn, modalCloseHandler]);

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  const classes = useStyles();

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="login-form-ctn"
      autoComplete="off"
    >
      <Logo className='login-logo-background' />

      <div className="sign-in-title">
        Sign in with Us
      </div>

      <IonRow>
        <IonCol
          sizeXs="12"
          sizeSm="12"
          sizeMd="12"
          sizeLg="12"
          className="login-col"
        >
          <TextField
            className="login-input-field user-name"
            type="text"
            name="username"
            variant="outlined"
            placeholder="Email / Username"
            classes={{ root: classes.customTextField }}
            error={
              formik.touched.username && formik.errors.username ? true : false
            }
            helperText={
              formik.touched.username && formik.errors.username
                ? formik.errors.username
                : null
            }
            {...formik.getFieldProps('username')}
          />
        </IonCol>

        <IonCol
          sizeXs="12"
          sizeSm="12"
          sizeMd="12"
          sizeLg="12"
          className="login-col"
        >
          <FormControl
            className="pwd-field"
            variant="outlined"
            error={
              formik.touched.password && formik.errors.password ? true : false
            }
          >
            <OutlinedInput
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              classes={{ root: classes.customTextField }}
              {...formik.getFieldProps('password')}
            // endAdornment={
            //   <InputAdornment position="end">
            //     <IconButton
            //       aria-label="toggle password visibility"
            //       onClick={showPasswordClickHandler}
            //       onMouseDown={showPasswordClickHandler}
            //     >
            //       {showPassword ? <Visibility /> : <VisibilityOff />}
            //     </IconButton>
            //   </InputAdornment>
            // }
            />
            {formik.touched.password && formik.errors.password ? (
              <FormHelperText error id="my-helper-text">
                {formik.errors.password}
              </FormHelperText>
            ) : null}
          </FormControl>
        </IonCol>
        <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12">
        <div className='login-demologin-btns'>

          <Button
            className="login-form-btn"
            color="primary"
            endIcon={loading && loadLogin ? <IonSpinner name="lines-small" /> : ''}
            type="submit"
            variant="contained"
          >
            Login
          </Button>
          {demoLoginWLs.includes(BRAND_NAME.toLowerCase()) ?
            <Button
              className="login-form-btn"
              color="primary"
              endIcon={demoLoading ? <IonSpinner name="lines-small" /> : ''}
              onClick={handleDemoLogin}
              variant="contained"
            > Demo Login
            </Button> : null}
          </div>
        </IonCol>
        {/* <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6">
          <div className="link">
            Don't have an account yet?
            <span className="register-link"> Register</span>
          </div>
        </IonCol> */}

        {/* <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6">
          <div className="link ">Forgot password?</div>
        </IonCol> */}
      </IonRow>

      {errorMsg !== '' ? (
        <span className="login-err-msg">{errorMsg}</span>
      ) : null}

      <Modal
        open={showForgotPwdModal}
        closeHandler={() => setShowForgotPwdModal(false)}
        title="Forgot Password"
        size="xs"
      >
        <ForgotPwdForm closeHandler={() => setShowForgotPwdModal(false)} />
      </Modal>
    </form>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    errorMsg: state.auth.loginError,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    loginSuccess: (payload) => dispatch(loginSuccess(payload)),
    loginFailed: (err: string) => dispatch(loginFailed(err)),
    requestStart: () => dispatch(requestStart()),
    requestEnd: () => dispatch(requestEnd()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
