import React, { useState } from 'react';
import { IonImg } from '@ionic/react';
import './Reports.scss';
import proImage from '../../assets/images/common/pro.png';
import transactionIcon from '../../assets/images/common/transaction.svg';
import depositeIcon from '../../assets/images/common/deposite.svg';
import withdrawIcon from '../../assets/images/common/withdraw.svg';
import arrowIcon from '../../assets/images/common/arrow.svg';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from '../../models/RootState';
import { logout } from '../../store';
import LoginModal from '../../components/Modal/LoginModal';
import MyProfile from '../../components/MyProfile/MyProfile';
import Backdrop from '@material-ui/core/Backdrop';
type StoreProps = {
  loggedIn: boolean;
  logout: Function;
};

const SideNav: React.FC<StoreProps> = (props) => {
  const { loggedIn, logout } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const modalCloseHandler = () => {
    setModalOpen(false);
  };

  return (
    <div className="side-nav-ctn">
      <div className="profile-ctn">
        <div className="profile">
          <img src={proImage} />
        </div>
        <div className="name-ctn">
          <span className="small-text">welcome</span>
          <span className="name-text">
            {sessionStorage?.getItem('username')?.toLowerCase()}
          </span>
        </div>
      </div>
      <div className="side-nav-content">
        <div className="list">
          <div className="list-item">
            <div className="icon">
              <IonImg src={transactionIcon} className="icon-img" />
            </div>
            <NavLink to="/reports/transaction" className="navlink">
              <div className="lable">Transaction history</div>
            </NavLink>
            <div className="icon1">
              <IonImg src={arrowIcon} />
            </div>
          </div>
          <div className="list-item">
            <div className="icon">
              <IonImg src={depositeIcon} className="icon-img" />
            </div>{' '}
            <NavLink to="/reports/deposite" className="navlink">
              <div className="lable">Deposite history</div>
            </NavLink>
            <div className="icon1">
              <IonImg src={arrowIcon} />
            </div>
          </div>{' '}
          <div className="list-item">
            <div className="icon">
              <IonImg src={withdrawIcon} className="icon-img" />
            </div>{' '}
            <NavLink to="/reports/withdraw" className="navlink">
              <div className="lable">Withdraw history</div>
            </NavLink>
            <div className="icon1">
              <IonImg src={arrowIcon} />
            </div>
          </div>
        </div>

        <div className="bottom-section-ctn">
          <div className="nav-link">
            <div className="label" onClick={() => setModalOpen(true)}>
              Account settings
            </div>
          </div>
          <div className="nav-link">
            <div className="label" onClick={() => logout()}>
              Logout
            </div>
          </div>
        </div>
      </div>

      <Backdrop className="backdrop-ctn" open={modalOpen}>
        <LoginModal
          open={modalOpen}
          closeHandler={modalCloseHandler}
          noTitle={true}
          size="md"
          customClass={'login-modal-ctn'}
        >
          <MyProfile></MyProfile>
        </LoginModal>
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
