import React, { useState, useEffect, useCallback } from 'react';
import { IonButton, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import Spinner from '../../components/Spinner/Spinner';
import { AuthResponse } from '../../models/api/AuthResponse';
import API from '../../api';
import moment, { Moment } from 'moment';
import './GameReports.scss';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  GameReportBetLevel,
  // GameReportMarketLevelSummary,
} from '../../models/GameReportRecord';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import { getCurrencyTypeFromToken } from '../../store';
import REPORTING_API from '../../reporting-api';

type marketOption = {
  marketId: string;
  marketName: string;
  marketType: string;
};

type PropType = {
  // selectedUsername: string;
  selectedMatchName: string;
  selectedmatchId: string;
  selectedMarketId: string;
  startDate: Moment;
  endDate: Moment;
  selectedMarketName: string;
  selectedMarketType: string;
  selectedSessionId: string;
  // marketSettlementDate: string;
  setShowMatchLevelReport: () => void;
  setShowMarketLevelReport: () => void;
};

const GameReportBetLevelView: React.FC<PropType> = (props) => {
  const {
    selectedMarketId,
    selectedMatchName,
    selectedmatchId,
    startDate,
    endDate,
    selectedMarketName,
    selectedMarketType,
    selectedSessionId,
    // marketSettlementDate,
    setShowMatchLevelReport,
    setShowMarketLevelReport,
  } = props;
  const [reportRecords, setReportRecords] = useState<GameReportBetLevel[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [pageNum, setPageNum] = useState<number>(1);
  const pageSize = 20;
  // const [marketOptions, setMarketOptions] = useState<marketOption[]>([]);
  const [marketName, setMarketName] = useState<string>(selectedMarketName);
  const [marketId, setMarketId] = useState<string>(selectedMarketId);
  const [marketType, setMarketType] = useState<string>(selectedMarketType);
  const [sessionId, setSessionId] = useState<string>(selectedSessionId);
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
  // const [
  //   marketSummary,
  //   setMarketSummary,
  // ] = useState<GameReportMarketLevelSummary>();

  const [pageToken, setPageToken] = useState<string[]>(['']);
  const [nextPageToken, setNextPageToken] = useState<string>('');

  const nextPage = () => {
    setLoading(true);
    setPageNum(pageNum + 1);
    if (nextPageToken !== null && nextPageToken !== '') {
      setPageToken([...pageToken, nextPageToken]);
    }
    setLoading(false);
  };

  const prevPage = () => {
    setLoading(true);
    setPageNum(pageNum - 1);
    if (pageToken.length > 1) {
      let pagetokens = pageToken;
      pagetokens.pop();
      setPageToken([...pagetokens]);
    }
    setLoading(false);
  };

  const fetchMarketLevelReort = useCallback(async () => {
    setLoading(true);
    try {
      const response: AuthResponse = await REPORTING_API.get(
        'reports/v2/orders/:search',
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
          params: {
            reportType: 'GAME_REPORT',
            // username: selectedUsername,
            eventId: selectedmatchId,
            marketId: marketId,
            // marketType:
            //   marketName === 'Match Odds'
            //     ? 0
            //     : marketName === 'Bookmaker'
            //     ? 1
            //     : 2,
            // sessionId: sessionId,
            startDate: startDate.startOf('day').toISOString(),
            endDate: endDate.endOf('day').toISOString(),
            pageSize: pageSize,
            pageToken: pageToken[pageToken.length - 1],
          },
        }
      );
      setReportRecords(response.data?.orders);
      setNextPageToken(response.data?.pageToken);
    } catch (err) {
      console.log(err);
      setReportRecords([]);
    }
    setLoading(false);
  }, [selectedmatchId, marketName, sessionId, startDate, endDate, pageToken]);

  // const fetchGamesList = useCallback(async () => {
  //   try {
  //     const response: AuthResponse = await API.post(
  //       '/user/reports/game-report-market-list',
  //       '',
  //       {
  //         headers: {
  //           Authorization: sessionStorage.getItem('jwt_token'),
  //         },
  //         params: {
  //           eventId: selectedmatchId,
  //         },
  //       }
  //     );
  //     setMarketOptions(response.data);
  //   } catch (err) {
  //     console.log(err);
  //     setMarketOptions([]);
  //   }
  //   setLoading(false);
  // }, [selectedmatchId]);

  const fetchGamesList = useCallback(async () => {
    try {
      const response: AuthResponse = await REPORTING_API.get(
        '/reports/v2/game-report/markets',
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
          params: {
            eventId: selectedmatchId,
            startDate: startDate.startOf('day').toISOString(),
            endDate: endDate.endOf('day').toISOString(),
          },
        }
      );
      // setMarketOptions(response.data);
    } catch (err) {
      console.log(err);
      // setMarketOptions([]);
    }
    setLoading(false);
  }, [selectedmatchId]);

  // useEffect(() => {
  //   fetchGamesList();
  // }, [selectedmatchId]);

  useEffect(() => {
    fetchMarketLevelReort();
  }, [marketName]);

  return (
    <div className="reports-ctn">
      <div className="header-ctn">
        <div className="page-title">
          <div className="img-page-title-row">
            <div className="title">Game Report</div>
          </div>
        </div>

        <div className="breadcrumbs-row">
          <div className="breadcrumbs">
            <span
              className="prev-level-link"
              onClick={() => setShowMarketLevelReport()}
            >
              <ArrowBackIcon />
            </span>
            <span
              className="prev-level-link"
              onClick={() => setShowMarketLevelReport()}
            >
              {selectedMatchName}
            </span>
            <span className="page-lable">{' / ' + marketName}</span>
          </div>
        </div>

        {/* <div className="filters-row">
          {marketOptions.length > 0 ? (
            <div className="select-filter">
              <div className="select-filter-label">Select market</div>
              <IonSelect
                className="select-filter-control"
                value={sessionId}
                placeholder="Select Match"
                interface="popover"
                onIonChange={(e) => {
                  setSessionId(e.detail.value);
                  setMarketId(e.detail.value);
                  setMarketName(
                    marketOptions.find((o) => o.marketId === e.detail.value)
                      .marketName
                  );
                }}
              >
                {marketOptions.map((opt, idx) => (
                  <IonSelectOption key={'option-' + idx} value={opt.marketId}>
                    {opt.marketName}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
          ) : null}
          <div className="date-filter">
            <div className="date-filter-label">From</div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                className="date-filter-control readonly"
                InputProps={{
                  disableUnderline: true,
                }}
                disabled={true}
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="from-date"
                value={startDate}
                onChange={() => console.log('')}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="date-filter">
            <div className="date-filter-label">To</div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                className="date-filter-control readonly"
                InputProps={{
                  disableUnderline: true,
                }}
                disabled={true}
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="to-date"
                value={endDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                onChange={() => console.log('')}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div> */}
      </div>

      <div className="content-ctn light-bg">
        <div className="tbl-ctn">
          {loading ? (
            <Spinner />
          ) : (
            <TableContainer component={Paper} className="gr-user-match-tbl-ctn">
              <Table className="gr-match-level-tbl">
                <TableHead>
                  <TableRow>
                    <TableCell className="web-view">Settlement Date</TableCell>
                    <TableCell>Bet On</TableCell>
                    <TableCell>Bet Type</TableCell>
                    <TableCell>Odds</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Profit/Loss</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportRecords.map((row, idx) => (
                    <TableRow
                      key={'row-' + idx}
                      className={
                        row.betType === 'BACK' ? 'back-odd' : 'lay-odd'
                      }
                    >
                      <TableCell
                        className="web-view"
                        key={'row-' + idx + '-cell-1'}
                      >
                        {moment(row.settlementDate).format(
                          'DD/MM/YYYY, h:mm:ss A'
                        )}
                      </TableCell>

                      <TableCell key={'row-' + idx + '-cell-2'}>
                        <div className="text-ellipsis mob-fs-13">
                          {marketType === 'FANCY'
                            ? row.marketName +
                              ' @ ' +
                              Number(row.oddValue * 100 - 100).toFixed(0)
                            : row.outcomeDesc}
                        </div>
                        <div className="mob-view opc-6">
                          {moment(row.settlementDate).format('DD/MM, h:mm A')}
                        </div>
                      </TableCell>
                      <TableCell
                        className="web-view"
                        key={'row-' + idx + '-cell-3'}
                      >
                        {row.betType === 'BACK' ? 'Back' : 'Lay'}
                      </TableCell>
                      <TableCell key={'row-' + idx + '-cell-4'}>
                        <span className="mob-fs-13">
                          {marketType === 'MATCH_ODDS'
                            ? row.oddValue.toFixed(2)
                            : marketType === 'BOOKMAKER'
                            ? (row.oddValue * 100 - 100).toFixed(2)
                            : row.sessionRuns}
                        </span>
                      </TableCell>
                      <TableCell key={'row-' + idx + '-cell-5'}>
                        <span className="mob-fs-13">
                          {Number(row.stakeAmount / cFactor).toFixed(2)}
                        </span>
                      </TableCell>
                      <TableCell
                        key={'row-' + idx + '-cell-6'}
                        className={row.payOutAmount > 0 ? 'profit' : 'loss'}
                      >
                        <span className="mob-fs-14">
                          {row.payOutAmount > 0 ? '+' : null}
                          {Number(row.payOutAmount / cFactor).toFixed(2)}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <IonRow>
            {pageToken.length === 1 || loading ? null : (
              <IonButton
                className="myb-btn-prev"
                onClick={(e) => {
                  prevPage();
                }}
              >
                Prev({pageNum - 1})
              </IonButton>
            )}
            {(!(nextPageToken === '' || nextPageToken === null) ||
              reportRecords.length === pageSize) &&
            !loading ? (
              <IonButton className="myb-btn-next" onClick={(e) => nextPage()}>
                Next({pageNum + 1})
              </IonButton>
            ) : null}
          </IonRow>
        </div>
      </div>
    </div>
  );
};

export default GameReportBetLevelView;
