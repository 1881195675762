import React, { useEffect, useState } from 'react';
import { IonCol, IonRow } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';
import API from '../../api';
import inplay from '../../assets/images/common/Banner/Polygon.svg';
import { RootState } from '../../models/RootState';
import { GapGame, IndCasinoGame } from '../../models/dc/DcGame';
import { Backdrop } from '@material-ui/core';
import LoginModal from '../../components/Modal/LoginModal';
import LoginForm from '../../components/LoginForm/LoginForm';
import Info from "../../assets/images/home/homeview/info.svg";
import Footer from '../../components/Footer/Footer';
import PageBanner from '../../components/Page-banner/PageBanner';
import SVLS_API from '../../svls-api';

type StoreProps = {
  loggedIn: boolean;
};

const IndianCasinoPage: React.FC<StoreProps> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { loggedIn } = props;
  const [games, setGames] = useState<IndCasinoGame[]>([]);
  const [laserGames, setLaserGames] = useState<GapGame[]>([]);
  const [royalCasinoGames, setRoyalCasinoGames] = useState<GapGame[]>([]);
  const history = useHistory();
  const setDialogShow = (show: boolean) => {
    setShowDialog(show);
  };

  useEffect(() => {
    if (loggedIn) {
      const claims = sessionStorage.getItem('jwt_token').split('.')[1];
      const userStatus = JSON.parse(window.atob(claims)).status;
      if (userStatus === 0 || userStatus === 3) {
        return history.push(`/home`);
      }
    }
  }, [loggedIn]);

  const getFcGames = async () => {
    let response: AxiosResponse<IndCasinoGame[]>;
    if (loggedIn) {
      response = await API.get('/dc/indian-casino/filtered-list', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
    } else {
      response = await API.get('/dc/indian-casino/filtered-list');
    }
    setGames(response.data);
  };

  const getLaserCasino = async () => {
    let response: AxiosResponse<GapGame[]>;
    if (loggedIn) {
      response = await SVLS_API.get('/catalog/v2/indian-casino/games', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      }
      );
    } else {
      response = await SVLS_API.get('/catalog/v2/indian-casino/games');
    }
    setLaserGames(
      response.data.filter((g) => {
        return g.providerName === 'LASER';
      })
    );

    setRoyalCasinoGames(
      response.data
    );
  };

  const handleGameClick = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    subProvider: string,
    provider?: string
  ) => {
    if (loggedIn) {
      getGameUrl(gameId, gameName, gameCode, provider, subProvider);
    } else {
      setDialogShow(true);
    }
  };

  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    provider: string,
    subProvider: string
  ) => {
    history.push(
      `/gap_casino/game/${gameName.toLowerCase().replace(/\s+/g, '-')}-${btoa(
        gameId.toString()
      )}-${btoa(gameCode)}-${btoa(provider)}-${btoa(subProvider)}`
    );
  };

  const getIndianCasinoGameUrl = (gameCode) => {
    if (loggedIn) {
      history.push(`/indian_casino/live/${gameCode}`);
    } else {
      setDialogShow(true);
    }
  };

  useEffect(() => {
    getFcGames();
    getLaserCasino();
  }, [loggedIn]);

  return (
    <div className="dc-page-bg">
      <PageBanner />
      <div className="dc-ctn">
        <IonRow class="casino-filters-row">
          <div className="title">Fun Games</div>
        </IonRow>
        <div className="title-border-line"></div>
        <>
          <IonRow className="dc-games-ctn">
            {laserGames && laserGames.length > 0 ? (
              <>
                {laserGames.map((g, i) => (
                  <>
                    <IonCol
                      sizeXs="4"
                      sizeSm="4"
                      sizeMd="2"
                      sizeLg="2"
                      key={g.gameName}
                      className="dc-ion-col"
                    >
                      <div className="container1"
                        onClick={() =>
                          handleGameClick(
                            g.gameId,
                            g.gameName,
                            g.gameCode,
                            g.subProviderName,
                            g.providerName
                          )
                        }>
                        <img
                          alt={g.gameName}
                          src={g.urlThumb ? g.urlThumb : ''}
                          title={g.gameName}
                          className="dc-img"
                        />

                        <div className="game-name">
                          <span className="name">
                            {g?.gameName.length > 21 ? g?.gameName?.slice(0, 20) + ".." : g?.gameName}

                          </span>
                          <span className="p-name">{g.subProviderName}</span>
                          {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                        </div>
                        {/* <div className="overlay1">
                          <div className="text1">
                            <div
                              className="custom-btn"
                              onClick={() =>
                                handleGameClick(
                                  g.gameId,
                                  g.gameName,
                                  g.gameCode,
                                  g.subProviderName,
                                  g.providerName
                                )
                              }
                            >
                              <img src={inplay} alt="inplay" />
                              <div> PLAY NOW </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </IonCol>
                  </>
                ))}
              </>
            ) : null}

            {royalCasinoGames && royalCasinoGames.length > 0 ? (
              <>
                {royalCasinoGames.map((g, i) => (
                  <>
                    <IonCol
                      sizeXs="4"
                      sizeSm="4"
                      sizeMd="2"
                      sizeLg="2"
                      key={g.gameName}
                      className="dc-ion-col"
                    >
                      <div className="container1"
                        onClick={() =>
                          handleGameClick(
                            g.gameId,
                            g.gameName,
                            g.gameCode,
                            g.subProviderName,
                            g.providerName
                          )
                        }>
                        <img
                          alt={g.gameName}
                          src={g.urlThumb ? g.urlThumb : ''}
                          title={g.gameName}
                          className="dc-img"
                        />

                        <div className="game-name">
                          <span className="name">{g?.gameName.length > 21 ? g?.gameName?.slice(0, 20) + ".." : g?.gameName}</span>
                          <span className="p-name">{g.subProviderName}</span>
                          {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                        </div>
                        {/* <div className="overlay1">
                          <div className="text1">
                            <div
                              className="custom-btn"
                              onClick={() =>
                                handleGameClick(
                                  g.gameId,
                                  g.gameName,
                                  g.gameCode,
                                  g.subProviderName,
                                  g.providerName
                                )
                              }
                            >
                              <img src={inplay} alt="inplay" />
                              <div> PLAY NOW </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </IonCol>
                  </>
                ))}
              </>
            ) : null}

            {games && games.length > 0 ? (
              <>
                {games.map((g, i) => (
                  <>
                    {g.Enable == 'true' ? (
                      <IonCol
                        sizeXs="4"
                        sizeSm="4"
                        sizeMd="2"
                        sizeLg="2"
                        key={g.Game_name}
                        className="dc-ion-col"
                      >
                        <div className="container1">
                          <img
                            alt={g.Game_name}
                            src={g.Url ? g.Url : ''}
                            title={g.Game_name}
                            className="dc-img"
                          />

                          <div className="game-name">
                            <span className="name">{g?.Game_name.length > 21 ? g?.Game_name?.slice(0, 20) + ".." : g?.Game_name}</span>
                            <span className="p-name">{g.Provider}</span>
                            {/* <img title={g.Provider} className='info-img' src={Info} /> */}
                          </div>
                          <div className="overlay1">
                            <div className="text1">
                              <div
                                className="custom-btn"
                                onClick={() =>
                                  getIndianCasinoGameUrl(g.Game_id)
                                }
                              >
                                <img src={inplay} alt="inplay" />
                                <div> PLAY NOW </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </IonCol>
                    ) : null}
                  </>
                ))}{' '}
              </>
            ) : null}
          </IonRow>
        </>
      </div>
      {/* <Footer /> */}

      <Backdrop className="backdrop-ctn" open={showDialog}>
        <LoginModal
          open={showDialog}
          closeHandler={() => setDialogShow(false)}
          noTitle={true}
          size="xs"
          customClass={'login-modal-ctn'}
        >
          <LoginForm modalCloseHandler={() => setDialogShow(false)}></LoginForm>
        </LoginModal>
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  let status = 0;
  if (state.auth.loggedIn) {
    status = JSON.parse(
      window.atob(sessionStorage.getItem('jwt_token').split('.')[1])
    ).status;
  }
  return {
    loggedIn: state.auth.loggedIn,
    loggedInUserStatus: status,
  };
};

export default connect(mapStateToProps, null)(IndianCasinoPage);
