import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import './TermsConditions.scss';
import { IonTitle } from '@ionic/react';
import { TableHead } from '@material-ui/core';
import { BRAND_NAME } from '../../constants/Branding';
import Copyright from '../../components/Copyright/Copyright';

const TermsConditions: React.FC = () => {
  return (
    <div className="terms-ctn">
      <IonTitle>Terms And Conditions</IonTitle>
      <div className="terms-conditions-ctn">
        <div className="content-ctn">
          <h4 className="terms-desc-heading">
            1. General Introduction, definitions and stipulations
          </h4>
          <p>
            1.1 An <b>“Account Holder”</b> is an individual having a contractual
            relationship with {BRAND_NAME} by creating a {BRAND_NAME} Account on
            the Website.
          </p>
          <p>
            1.2 A Taash Account” is an account held by an Account Holder, for
            bona fide transactions, with a strict aim to establish a normal
            commercial relationship with {BRAND_NAME} and with the strict
            purpose of conducting betting and other gaming and gambling
            transactions.
          </p>
          <p>
            1.3 The “Contract” is the contractual relationship between{' '}
            {BRAND_NAME} and an Account Holder and shall be constituted under
            and governed by the
            {BRAND_NAME} Rules, i.e. the “Terms and Conditions”.
          </p>
          <p>
            1.4 The “Website” is the internet gateway accessible through the
            internet address www.{BRAND_NAME}.com where all current and relevant
            information regarding {BRAND_NAME} operations is published, and
            through which the Services are provided to the Account Holders.
          </p>
          <p>
            1.5 The “Services” are the gaming and betting offers provided by
            {BRAND_NAME} to the Account Holder through the Website.
          </p>
          <p>
            1.6 A “Card” refers to all types of cards with a function of
            “payment”, “charge”, “debit”, “credit”, “virtual” and/or similar.
          </p>
          <p>
            1.7 A “Payment Solution Provider” is an intermediary acting as a
            payment agent for various different payment methods.
          </p>
          <p>
            1.8 A “Financial Institution” is a bank and/or other institution
            regulated by an applicable domestic financial services Act or
            similar.
          </p>
          <p>
            1.9 “Force Majeure” refers to any occurrence or condition beyond
            one’s reasonable control which leads to a delay or default in the
            performance of the affected party’s contractual obligation and
            shall, for the purposes of the {BRAND_NAME} Rules, include Acts of
            God, government restrictions (including the denial or cancellation
            of any necessary license where such denial or cancellation is made
            through no fault of the affected party), wars, outbreak of
            hostilities, riots, civil disturbances, insurrections, acts of
            terrorism, fire, explosions, floods, theft, malicious damage,
            strikes, lockouts, and/or any other cause beyond the reasonable
            control of the party whose performance is affected.
          </p>
          <p>
            1.10 “Client Application” shall mean the web browser application
            opened by the Account Holder in order to use the Services.
          </p>
          <p>
            1.11 “Software” shall mean all software used by {BRAND_NAME} to
            offer and/or operate the Services and/or run the Client Application.
          </p>
          <p>
            1.12 A “Prize” is an amount, a bonus or a reward that can be won by
            the Account Holder.
          </p>
          <p>
            1.13 An “Inactive Account” is a {BRAND_NAME} Account which has not
            recorded any log-in and/or log-out for a period exceeding 12
            consecutive months.
          </p>
          <p>
            1.14 All trademarks, service marks and trade names as well as
            images, graphics, text, concepts or methodologies (collectively the
            “Intellectual Assets”) found on the Website, the Client Application
            and the material contained therein are the exclusive property of
            {BRAND_NAME} and/or {BRAND_NAME}'s suppliers and partners. The
            Account Holder is not entitled to exploit or otherwise use any
            Intellectual Asset for whatever purpose, except for what is allowed
            by law.
          </p>
          <h4 className="terms-desc-heading">2. Account Rules</h4>
          <h2 className="terms-desc-heading">
            2.1 Creation of a {BRAND_NAME} Bet Account
          </h2>
          <p>
            2.1.1 An individual cannot participate in a game for money unless
            that individual is an Account Holder. To be registered as a player,
            an individual must register personally and submit an application for
            registration. The following information must be provided:
          </p>
          <ul>
            <li>
              date of birth and provide valid identification showing that the
              player is over eighteen (18) years of age or the applicable legal
              age of majority as stipulated in the jurisdiction of your
              residence (identification documents which must be submitted
              include: copy of a valid identity card, passport or driving
              license);
            </li>
            <li>player’s first and last name;</li>
            <li>player’s full residential address;</li>
            <li>player’s valid email address;</li>
            <li>a username and a password.</li>
          </ul>
          <p>
            2.1.2 An individual applying to become an Account Holder warrants
            and represents not to be under the age of eighteen (18) years.
            Minors cannot register as a player and cannot hold a {BRAND_NAME}{' '}
            Account.
            {BRAND_NAME} reserves the right to request additional proof of age
            and perform additional checks in order to verify the information
            provided. A {BRAND_NAME} Account may be suspended until satisfactory
            proof of age is provided.
          </p>
          <p>
            2.1.3 An individual applying to become an Account Holder furthermore
            warrants and represents:{' '}
          </p>
          <ul>
            <li>
              to be a physical person (a legal entity will not be accepted as an
              Account Holder);
            </li>
            <li>
              not to be a resident of Afghanistan, Australia, Denmark, Estonia,
              Ethiopia, France and other French territories, Hong Kong, Iran,
              Iraq, Italy, Jordan, Kuwait, North Korea, Pakistan, Philippines,
              Spain, Syria, Turkey, United States of America and other U.S.
              territories, or Yemen (please be aware of particular country
              exclusions for Casino – Games – Live Casino, Poker and Bingo);
              {BRAND_NAME} reserves the right to block the access to{' '}
              {BRAND_NAME}.com and not to pay the winning to the players from
              the mentioned countries;
            </li>
            <li>
              not to be a professional player in any sport, competition or
              league where {BRAND_NAME} offers betting;
            </li>
            <li>not to be restricted by limited legal capacity;</li>
            <li>not to be acting on behalf of another party;</li>
            <li>
              not to be classified as a compulsive problem gambler, and/or be
              included (whether voluntarily or involuntarily) on any register or
              database of excluded players;
            </li>
            <li>
              not to be depositing monies originating from criminal and/or other
              illegal activities;
            </li>
            <li>
              not to be depositing monies through a Card which the Account
              Holder is not authorized to use and/or utilizing a Card in a
              jurisdiction in which betting and gaming are prohibited;
            </li>
            <li>
              not to be conducting criminal activities whereby a {BRAND_NAME}{' '}
              Account is directly or indirectly involved;
            </li>
            <li>
              not to use the Services if it is illegal in his/her country of
              residence or otherwise restricted for him/her to open a gaming
              account, purchase or use services from {BRAND_NAME} and/or
              otherwise participate in the games offered. It is the Account
              Holder’s responsibility to ensure his/her use of {BRAND_NAME}'s
              Website and Services is legal;
            </li>
            <li>
              not to find the Website or the Services offensive, objectionable,
              unfair, nor indecent; and
            </li>
            <li>
              to maintain his/her {BRAND_NAME} Account details up-to-date in
              terms of the following: first and last name,
            </li>
          </ul>
          country of residence, valid email address and phone number. 2.1.4 An
          individual applying for a {BRAND_NAME} Account acknowledges and
          accepts:
          <ul>
            <li>
              all definitions and stipulations as stated under the {BRAND_NAME}{' '}
              Rules;
            </li>
            <li>
              the {BRAND_NAME} Rules as currently published on the Website, as
              well as any possible future changes to the {BRAND_NAME} Rules;
            </li>
            <li>
              that the Contract is governed by: (i) the {BRAND_NAME} Rules as
              published in English; (ii) any regulatory provisions or other
              decisions made from time to time by the Governing Authorities; and
              (iii) the Governing Law of the Place of the Contract.
            </li>
          </ul>
          Rules of acceptance of bets on sports events
          <p>
            2.2 The Organizer has a right to cancel any bonus, jackpot and/or
            any prize if the Organizer is confident the Player has implemented a
            system usage fraud. In this case the Organizer freezes the Player's
            special gaming account, and if there are any funds, makes a refund
            to the payment system that was used by the Player to replenish the
            gaming account.
          </p>
          <p>2.3. Arranged game</p>
          <p>
            If the Organizer has sufficient facts to suspect the case of an
            arranged game, they can terminate the prize remunerations and make a
            re-payment of the already carried out payments to the Player's
            special gaming accounts. The mentioned suspicions can include data
            referring to sums and characteristics, as well as amounts of all
            entry payments made via all the possible methods.
          </p>
          <h4 className="terms-desc-heading">3. Betting rules</h4>
          <p>
            3.1 The betting company accepts bets on the outcome of sporting and
            other events with a deliberately unknown result, including the use
            of telecommunication networks.
          </p>
          <p>
            3.2 Every time downloading the client’s application, regardless of
            the use of the media resource (via Internet or mobile services), by
            opening an account and using an existing account, betting or
            participating in any games, taking any prize, the client confirms
            his agreement with the current version of the Rules and the
            obligation to act in accordance with these Rules.Anytime a user
            downloads the client, registers an account, uses an existing
            account, bets on any games, takes any winnings, etc., this signifies
            the User’s agreement and obligation to act in accordance with the
            current rules.
          </p>
          <p>
            3.3 {BRAND_NAME} has the right to make any changes and additions to
            the rules set forth herein, the provisions and procedures of
            payments without an individual prior written notice. Users are
            notified about this with appropriate announcements. The conditions
            of previously staked (placed) bets remain unchanged, and bets which
            are accepted are subject to the posted rules at the time of
            acceptance.
          </p>
          <p>
            3.4 {BRAND_NAME} reserves the right to refuse service to any person
            without disclosing the reason(s).
          </p>
          <h4 className="terms-desc-heading">4. Policy of site use</h4>
          <p>
            4.1 {BRAND_NAME} does not bear any responsibility for any losses or
            damages claimed as resulting from the use of this site or from its
            content. This provision equally applies to the use or misuse of the
            content of the site by any person, inability to access the site or
            use it, to delay in functioning or transmission of data, failures in
            communication lines, any errors, misprints or omissions in the
            content of the site.
          </p>
          <p>
            4.2 Loading photos and video from the website or its sub-sites is
            permitted only for private use. Publication, transmission or
            reproduction of this data for any other purpose is strictly
            prohibited.
          </p>
          <p>
            4.3 {BRAND_NAME} monitors traffic of its website actively, and
            reserves the right to block access in cases of suspected automated
            betting (bots).
          </p>
          <h4 className="terms-desc-heading">5. Glossary</h4>
          <p>5.1 Basic terms accepted in this edition of the rules:</p>
          <p>“Single” – a bet on a single event;</p>
          <p>
            “Express” – this is a bet on the outcome of several different events
            simultaneously. When you place a bet on the outcome of two or more
            events (for example, two different football matches), you can unite
            them in “Express”. To win an express bet it is necessary for you to
            not have any failed predictions (losses) on any of the outcomes
            which are included in the Express. Loss on one of the outcomes of an
            express bet means loss throughout the entire bet. Winnings of the
            express bets is equal the product of the bet amount at the
            coefficients of played outcomes included in the express (if on one
            of the bets included in the express occurs a return, then the
            coefficient on it equals to 1).
          </p>
          <p>
            “System” – a set of “express bets” of a particular set of events.
            Each combination of the system is calculated as a separate express
            bet. To win in the system it is sufficient that the indicated number
            of predictions of the total number be correct (it is defined by
            name, that is, for example, in the system “3 of 4′′ it is necessary
            that 3 of the 4 predictions prove correct, for “5 of 7′′ it is
            necessary properly to predict the outcome of the five events of 7
            options, etc.). It must be taken into account that because of the
            fact that only a part of the total bet amount is specified on each
            of the express of the system bet (proportional to the number of
            options) then the winnings by such a system bet will be less than
            the possible general winnings by the express bet. In some cases,
            when not all outcomes are predicted, the amount of winnings may be
            less than the amount of the bet, but in contrast to express bets, a
            part of the amount will be offset on account of correctly predicted
            outcomes.
          </p>
          <p>
            “Chain” – bet which is a combination of “single” bets including
            events independent from each other. The sum of single bet of chain‘s
            each event is equal to the sum of chain. The client is given a
            chance to determine independently the order of the bets included in
            the chain and stake only on the first event of the chain. Thus the
            concept of “chain account” is imported. After the tournament of each
            single bet included in the chain the sum of that account is
            calculated. Initially it is equal to the sum of the first bet. If
            the sum on the chain account is less than its initial sum, the
            chain’s next event’s single bet is calculated by the account
            balance. The procedure of chain’s calculation depends on the order
            of bets mentioned in the given ticket but not on time. The sum which
            remains on the account after calculation of all bets in the chain is
            a subject to payment. If the sum on the chain account reaches zero –
            the chain breaks and is considered to be lost.
          </p>
          <p>
            “Handicap” – the advantage or the leeway of the entrant of
            competition, expressed in goals (points/) sets, etc., which is
            provided by the bookmaker office to the entrant with bets on it. The
            result of the event subject to the handicap is determined in this
            way: the handicap given to the entrant is added to the corresponding
            result shown by the entrant in the competition. If the results thus
            obtained are in favor of the elected entrant, he is considered as a
            winner, and the bets on his win subject to the handicap win.
            Payments are carried out with the coefficient of winnings indicated
            in the description of bets. If the result is in favor of the
            opponent – the bets are lost. If the result subject to the handicap
            is a draw, then the coefficient of winnings under such a result will
            be equal to 1 (return of the bet);
          </p>
          <p>For example:</p>
          <div className="table-ctn">
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow></TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Team1</TableCell>
                    <TableCell>Team2</TableCell>
                    <TableCell>Handicap1</TableCell>
                    <TableCell>Handicap2</TableCell>
                    <TableCell>Coefficient1</TableCell>
                    <TableCell>Coefficient2</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Inter</TableCell>
                    <TableCell>Cagliari</TableCell>
                    <TableCell> -1.5</TableCell>
                    <TableCell> 2.25</TableCell>
                    <TableCell>+1.5</TableCell>
                    <TableCell> 1.9</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <p>
            1) Suppose you want to bet upon the win of Cagliari subject to
            handicap. The handicap betting on Cagliari (Handicap 2) is equal to
            “+1.5′′. To win with the bet on the handicap “+1.5′′ it is necessary
            that Cagliari will not lose by more than 1 goal, as handicap +1.5
            will be added to goals from Cagliari. Let us analyze all the
            options:
          </p>
          <ul>
            <li>
              Cagliari won or the teams drew or Cagliari lost by the difference
              of 1 goal. For example, the final score is 2-1. Taking into
              account the handicap (+1.5) the result 2:2.5 in favor of Cagliari
              (the handicap of Inter when determining the result on the rates at
              Cagliari is not taken into account). The bets win.
            </li>
            <li>
              Cagliari lost by more than 1 goal difference. For example, the
              final score is 2-0. Taking into account the handicap (+1.5) the
              result is 2:1.5 in favor of Inter. In this case, the rates on
              winning of Cagliari taking into account the handicap (+1.5) lose.
            </li>
          </ul>
          <p>
            2) Similarly, the following variants are possible when betting on a
            win for Inter taking into account the handicap “-1.5′′:
          </p>
          <li>
            Inter won having the advantage of two or more goals. For example,
            the final score is 3-0. Taking into account the handicap (- 1.5) the
            result is 1.5:0 in favor of Inter. The bets win.
          </li>
          <li>
            Inter won having the advantage of 1 goal, teams drew or Cagliari
            won. For example, the final score is 2-1. Taking into account the
            handicap -1.5, the result of the bets on Inter at handicap (-1.5)
            gives 0.5:1, in favor of Cagliari. In this case, the bets on Inter
            with the handicap -1.5 lose.
          </li>
          <p>
            Double bet of handicap is offered (Asian handicap). Bet on victory
            or total taking into account the handicap, which is the multiple of
            0.25 (but not of 0.5). Such bets are calculated as 2 bets with the
            half of sum: with the same odd and “common handicaps” and “common
            totals” multiple of 0.5’s near- by common value. In case when the
            Asian handicap is included in parlay or system, it is calculated
            with that odd which would be in case of single bet. In case of
            winning of two common bets, the bet’s odd “O” is taken into account
            in the calculations. If one common bet is won and the other is a
            subject to refund, during the calculation is taken the odd
            (“O”+1)/2. If both common bets are lost, the whole bet considered
            lost.
          </p>
          <div className="table-ctn">
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow>
                    <TableCell>Event’s participant </TableCell>
                    <TableCell>
                      Handicap’s double bet (Asian handicap)
                    </TableCell>
                    <TableCell>Odd</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Real</TableCell>
                    <TableCell>-0.25 (0,-0.5)</TableCell>
                    <TableCell> 2.0</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Barcelona</TableCell>
                    <TableCell> +0.25 (0,+0.5)</TableCell>
                    <TableCell> 1.8</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <p>
            In case 200 euro stake is made on “Real” and “Real” won: two common
            bets with the half of sum won and the winning is equal to 100*2.0 +
            100*2.0 = 400 euros.The match was over in drawn: one common bet with
            the half of sum lost, and the other won with the odd “1′′ and the
            winning is equal to 100*0.0 + 100*1.0 = 100 euros. “Real” lost: two
            common bets with the half of sum lost and the winning is equal to
            100*0.0 + 100*0.0 = 0 euro. In case 200 euros stake is made on
            “Barcelona” and ”Barcelona” won: two common bets with the half of
            sum won and the winning is equal to 100*1.8 + 100*1.8 = 360 euros.
            The match was over in draw: one common bet with the half of sum
            lost, and the other won with the odd “1′′ and the winning is equal
            to 100*1.8 + 100*1.0 = 280 euros. “Barcelona” lost: two common bets
            with the half of sum lost and the winning is equal to 100*0.0 +
            100*0.0 = 0 euro.
          </p>
          <p>
            “Total” – the number of goals, pucks, points or the games, scored
            (thrown, gained or played) by an entrant or entrants of events.
          </p>
          <p>For example:</p>
          <p>
            Suppose the total of the soccer game is set (the amount of goals
            scored) equal to “4′′, and a wagering is offered on the total at two
            outcomes: more or less of the given total.
          </p>
          <p>
            If the match is scored with a total of less than 4 goals (0-0, 1-0,
            0-1, 1-1, etc.), bets lower than “4′′ win.
          </p>
          <p>
            If the match is scored with a total of more than 4 goals (4-1, 4-2,
            3-2, etc.), bets lower than “4′′ lose and bets more than “4′′ win.
          </p>
          <p>
            If the match is scored with a total of exactly 4 goals (3-1, 4-0,
            2-2, 1-3, 0-4), all bets on more than “4′′ and less than “4′′ will
            be refunded.
          </p>
          <h4 className="terms-desc-heading">6. Policy of Bets</h4>
          <p>
            6.1 The values of the parameters (including handicaps, totals) and
            coefficients of the events specified in the line of the current day
            may change after any bet, but conditions before bets are the same,
            except in cases of technical and obvious mistakes in the line.
          </p>
          <p>
            6.2 As an amount of bet only the amount confirmed and registered by
            the company is accepted.
          </p>
          <p>
            6.3 The User is the only person who is responsible for the bet. Once
            a bet is made and its acceptance is confirmed, it cannot be changed
            or canceled by the User. {BRAND_NAME} does not provide any
            guarantees with respect to the “lost” or “double” bets made by
            customers. Please carefully check all the bets you have made.
          </p>
          <p>
            6.4 Loss of your password cannot be a reason for the withdrawal
            rates or cancellation of the request for payment of winnings.
          </p>
          <p>
            6.5 A bet is accepted as soon as it is confirmed by the company. All
            accepted bets are displayed in the “Bet History”. In some cases
            there may be a delay until a bet is listed in your history. In case
            of any dispute the time when the bet was registered in the system of
            the company is considered to be its true submission time. If the
            User did not receive any notification about the acceptance of bet,
            the rate is still considered to be accepted if it is displayed in
            the “Bet History.”
          </p>
          <p>
            6.6 Failures of communication or other technical failures in
            customer communications are not a reason for the cancellation of a
            bet if the bet has been registered in the server.
          </p>
          <h4 className="terms-desc-heading">7. Displaying Data</h4>
          <p>
            7.1 The Company is not responsible for the accuracy, completeness
            and timeliness of the proposed information services, including data
            on the events of Live and mailing services.
          </p>
          <p>
            7.2 {BRAND_NAME} makes every effort to make the information on Live
            events (such as results, the time of a game and the statistics of
            teams /players) be correct. However, this information is displayed
            only for informational purposes. {BRAND_NAME} is not responsible in
            case of any errors in this data.
          </p>
          <p>
            7.3 {BRAND_NAME} is not responsible and does not accept any claims
            regarding the accuracy of the translation from foreign languages of
            team names and the names of the players.
          </p>
          <p>
            7.4 If in the indicated event/match more than one member with the
            same surname is present and during bet acceptance the full name of
            the athlete or other features identifying them uniquely have not
            been indicated, all bets are refundable.
          </p>
          <p>
            7.5 If in the name of the match or in the name of the position one
            or more entrants are indicated in the line incorrectly (except for
            errors in translation), that is if the other team/entrant of
            represented tournament is indicated instead of the necessary one,
            all the bets on that event are recognized as invalid and a return is
            made. Only {BRAND_NAME} has the right to determine the events
            belonging to this category.
          </p>
          <p>
            7.6 In cases when the specific details of the team such as a
            particular age group (for example 18 years/to 21 years/youth teams),
            gender (women) or the status of the team (reserve) are not indicated
            in the line resulting in the marking of the other team in the line
            instead of the declared one, all bets on this match are refundable.
            In other cases when incomplete name or possible grammar errors are
            indicated in the line, all bets remain in force. If the gender of
            teams is not indicated in the line, it is considered that the gender
            of teams is male. In case women’s teams are participating in a match
            it is necessary to have a detailed indication about it in the line
            otherwise bets on this match are a subject to return.
          </p>
          <p>
            7.7 Date and time indicated in the line are the date and time of
            discontinuation of betsˈ acceptance on the event (except for Live
            events) and do not necessarily coincide with the date and time of
            the actual start of the event. The actual time of the event while
            counting the bets is considered as the time of the start of the
            event, which is determined based on the official documents of the
            organization conducting the competition, as well as the data of
            {BRAND_NAME} .
          </p>
          <p>
            7.8 If the place of conducting a meeting is not indicated in the
            line, in the list of events the host of the field is indicated
            firstly, except for competitions of American types of sports. All
            the information contained in the “cap” to a particular sport,
            carries an ancillary character. Possible errors in the given
            information are not a ground for the return of bets.
          </p>
          <p>
            7.9 On acceptance of bets for Live Events there is a set delay, the
            value of which may vary depending on the sport, market, and other
            features, and it is determined exclusively by {BRAND_NAME} for
            security and protection from subsequent bets.
          </p>
          <p>
            7.10 For clients convenience, taking into account the specificity of
            Live Events in which coefficients change very often a client can
            choose one of the following options: to “Accept all changes in the
            coefficients”, “Accept only marked-up bets” or “Do not accept bets
            if the coefficients change,” which determines respectively whether
            the bet is accepted if during the time of its arrangement the
            coefficient changed, at any changes of it, or just in the case when
            the changed bet exceeds the estimated coefficient of the bet of the
            client.
          </p>
          <p>
            7.11 Incorrectly indicated date of the conducted match is not a
            ground for cancellation of the bet.
          </p>
          <p>
            7.12 If the match began from the moment at which it was previously
            interrupted, and in the line that was not stipulated, the bets are
            refundable.
          </p>
          <h4 className="terms-desc-heading">
            8. Interlocking events and outcomes
          </h4>
          <p>
            8.1 In “express” and “system” bets it is forbidden to include
            different events that relate to the same match, not even the ones
            which are interdependent directly. If in such bets were included any
            events that relate to the given match, this bet is refundable, even
            if the computer program of accepting the bettings did not block this
            bet during its submission. {BRAND_NAME} reserves the exclusive right
            to determine whether the events are connected.
          </p>
          <p>
            8.2 In the game on output-absence from the group in “express” and
            “system” bets it is allowed only to bet on one team from the given
            group. If in such bets qualify-not qualify of the teams from the
            same group were included then the given bets are refundable, even if
            the computer program accepting the bets did not block this bet
            during its submission. {BRAND_NAME} reserves the unique right to
            decide whether to accept bets on the position of qualify or not
            qualify in “express”, “system” and in “single” bets.
          </p>
          <h4 className="terms-desc-heading">
            9. Obvious and technical errors /
          </h4>
          <p>
            9.1 The Company is not responsible for errors in typing, transfer or
            counting of bets. In particular, {BRAND_NAME} reserves the right to
            correct obvious errors made while entering information on betting
            coefficients and/or calculation of the amounts at the bets (for
            example, errors in the coefficients indicated in teams incorrectly,
            incorrectly indicated events, etc.), or declare invalid the
            corresponding bets even after completion of the event. In the cases
            of correcting obvious errors bets are returned. The most common
            types of technical errors are the following:
          </p>
          <p>
            9.1.1 Errors of input of coefficients – when the coefficient (or the
            setting of the handicap/total, etc.) is displayed without a
            separator (dot) or with any additional number.
          </p>
          <p>
            Example: instead of the coefficient 1.8, 18 is displayed, instead of
            2, 23 is displayed, instead of 3, 30 is displayed etc.
          </p>
          <p>
            9.1.2 “Mirror Errors” – when the coefficients for players are
            displayed in a diametrically opposite manner. In this case, the
            favorite of the game (including current favorite of live event) is
            displayed by a coefficient exceeding the coefficient of the outsider
            of the given event.
          </p>
          <p>
            Example: Basketball. Barcelona-Unicaja. The winner of the match.
            Instead of 1.2 and 4, 4and 1.2 is displayed.
          </p>
          <p>
            9.1.3 Writing errors – if all the coefficients are indicated
            correctly, however, teams are written in an incorrect order (usually
            occurs for events taking place on a neutral field).
          </p>
          <p>
            Meaning for the previous example: record: Basketball.
            Unicaja-Barcelona. The winner of the match. 1.2 and 4 instead of
            Basketball. Barcelona-Unicaja. The winner of the match. 1,2 and 4
          </p>
          <p>
            Clear indicators of obvious errors are: very large difference in the
            coefficients of {BRAND_NAME} and other bookmakers, the coefficients
            that are nearly impossible to give in the current situation of the
            match.
          </p>
          <p>
            The right to determine whether or not an obvious or a technical
            error was made in any case belongs exclusively to {BRAND_NAME} .
          </p>
          <p>
            9.2 {BRAND_NAME} reserves the right to abolish the bets made on
            deliberately incorrect coefficients and obvious errors. The customer
            will be informed about changes in the balance immediately.
          </p>
          <p>
            9.3 {BRAND_NAME} reserves the right to cancel the bets even after
            the end of the event if these bets are won by the User as a result
            of a technical failure or an obvious error.
          </p>
          <p>
            9.4 In cases when due to technical reasons the User’s bets were not
            provided with the necessary amount of funds in the account, the bets
            are considered as invalid.
          </p>
          <p>
            9.5 In case of mistakenly having credited the means to the
            customer’s account the latter shall immediately notify {BRAND_NAME}{' '}
            about the mistake. If due to an error a negative balance has been
            formed at the client, the betting company has the right to cancel
            the uncalculated bets despite the fact that they were made before
            the error occurred.
          </p>
          <p>
            9.6 The Company reserves the right to oblige the User to liability
            for any damage caused to it if the user used a technical or
            administrative error intentionally when making a deposit and/or
            during the receipt of money on the account.
          </p>
          <h4 className="terms-desc-heading">10. Bets on credit</h4>
          <p>
            10.1 {BRAND_NAME} does not accept any bets or provide services to
            customers on credit. All bets must be confirmed with funds existing
            in the account of the User.
          </p>
          <h4 className="terms-desc-heading">11. Legal Aspects</h4>
          <p>
            11.1 {BRAND_NAME} states explicitly that bet placement and betting
            organization, as well as participation in sports betting,
            multiplayer poker, game in casinos and games like lottery may be
            restricted by law or even prohibited in some countries. Such
            restrictions or prohibitions may be imposed even if the company
            obtained the necessary permission (license for betting or organizing
            gaming) to place bets and betting organizations.
          </p>
          <p>
            The User should note that if the placement of bets or participation
            in sports betting, multiplayer poker and games such as lotteries is
            prohibited or permitted only under certain conditions, which are not
            followed, the responsibility for any damage caused by this rests
            solely with the User.
          </p>
          <p>
            The client must also take note that the company is not required to
            provide clients with information,
          </p>
          <p>
            instructions and warnings in a wider range than in the present
            paragraph. In particular, the company is not liable for damages
            suffered by the User due to the violation of the relevant
            legislative prohibitions functioning in his/her country.
          </p>
          <p>
            11.2 You should register on one of the websites which belong to the
            group of {BRAND_NAME} companies according to your country of
            residence. By rating or participating in the games, the User
            confirms that he has reached the lowest age of consent limited by
            law of his country, and confirms his/her capacity to enter into the
            agreement with the company. In case of non-fulfillment of these
            requirements the User’s account will be closed and necessary
            measures will be applied.
          </p>
          <p>
            11.3 The User agrees that his personal information provided to the
            company during the pool betting, games in multiplayer poker, casino
            games and games such as lotteries, as well as in any other game is
            stored and used by automatic means.
          </p>
          <p>
            11.4 You have registered on websites which belong to the group of
            {BRAND_NAME} companies more than once and/or you have more than one
            account. In this case, the Company has the right to block all the
            accounts at its discretion and to leave only one account
            corresponding to the country of residence.By registering the User
            confirms the exclusive intellectual property of the company’s
            regulations, as well as the system controlled by them.
          </p>
          <p>
            11.5 The Company reserves the right to inform Users about special
            promotions and new products by telephone and e-mail.
          </p>
          <p>
            11.6 Legal relations between the User and the company are a subject
            to laws of Curacao and are regulated by it with the exception of the
            appropriate legal rules of international private law. The place of
            performance of all obligations at betting and gaming, as well as at
            the appropriate bets, is Curacao. All disputes arising over or
            regarding bets made by customers, are resolved if it does not
            contradict the law, by the court in Curacao, having the relevant
            territorial and subject matter jurisdiction.
          </p>
          <p>
            11.7 The Company expressly states that it does not provide users
            with any advice (consultation) on tax matters and/or legal matters.
          </p>
          <p>
            11.8 No disputes about the bets can lead to a lawsuit, legal action,
            as well as claims on bookmaker licenses if the company complies with
            the provisions of arbitration.
          </p>
          <p>
            11.9 In case of any claims and disputes from Users’ side and people
            representing their parties, the company’s liabilities are limited by
            the size of bets or its possible winnings.
          </p>
          <p>
            11.10 In case of any disputes or claims the database of {BRAND_NAME}{' '}
            has priority over any other data.
          </p>
          <p>
            11.11 If there is discrepancy between the English version of these
            rules and the translation into another language, the English version
            is considered to be the correct one.
          </p>
          <p>
            11.12 Any matters of dispute including claims about the results of
            the event are accepted to be reviewed by the bookmaker office within
            5 days from the end of the sporting event.
          </p>
          <p>
            11.13 {BRAND_NAME} does not hold Users’ funds in segregated or
            separately protected bank accounts. It is important for the User to
            understand that deposits of {BRAND_NAME} are not in banks and no
            interest is accrued upon them.
          </p>
          <h4 className="terms-desc-heading">12. Restrictions on rates</h4>
          <p>
            12.1 Bookmaker company has the right not to accept bets from any
            person without giving any reason and reserves the right to close or
            temporarily block the accounts of individual Users without prior
            notice.
          </p>
          <p>
            12.2 Minimum and maximum size of bets for all sporting events is
            defined by the bookmaker office and is a subject to changes without
            prior written notice. In addition, {BRAND_NAME} reserves the right
            to impose special restrictions on the accounts of individual Users.
          </p>
          <p>
            12.3 {BRAND_NAME} does not recommend two or more Users to place bets
            from one IP-address (from the same computer or the same local area
            network) in order to avoid suspicion of collusion (cheating). In
            such cases it is necessary to reconcile the possibility of the game
            with {BRAND_NAME} by explaining the circumstances. {BRAND_NAME} has
            the right to block the account of such a User and ask them to send
            the documents verifying their registered identity.
          </p>
          <p>
            12.4 Winnings are paid only within the limits set with maximum
            limits of the company. If the User has made a bet and the winning
            exceeds the maximum, the company will not pay any amount of money
            exceeding the maximum limit.
          </p>
          <p>
            12.5 Results for sports statistics are published on the official
            website of {BRAND_NAME} within 3 days after the end of a sporting
            event.
          </p>
          <h4 className="terms-desc-heading">13. Late bets</h4>
          <p>
            13.1 Bets placed after the start of the event, except the bets for
            Live Events, in all cases, are invalid and are returnable, and are
            excluded from the “express” and “system” bets. Any bet (including
            bets for Live Events) made on an event, the result of which is
            already known by the time of betting, is considered invalid and will
            be refunded.
          </p>
          <p>
            13.2 If the User wishes to challenge the time of the start of the
            event, he must provide an official document (or a link to the
            website of regulatory bodies of the given event) which confirms the
            actual time of the start of the match on which the given bet was
            made.
          </p>
          <p>
            13.3 While arising disputes regarding the time of the start of the
            event, if the official regulatory bodies of the appropriate
            tournament/event do not provide (or lack) such information, the
            information of {BRAND_NAME} about the start time of the event is
            considered the only correct one while calculating the bets.
          </p>
          <h4 className="terms-desc-heading">14. Return</h4>
          <p>
            14.1 If a “return” is declared about the bet, such bet is considered
            as won with the coefficient of 1. In case of “Single” bets this
            means that the User will get back the amount of money placed to this
            bet. In the case of “express” or “system” bets the total coefficient
            will be changed accordingly (in the event, where a return occurred,
            the coefficient will get the value 1, the coefficients on the
            remaining events will remain unchanged, and the final coefficient
            will be counted according to similar scheme; only the coefficient of
            the outcome on the event, on which was given a return – one of the
            factors (multipliers) of the final coefficient of “express” or
            “system” – will get the value 1).
          </p>
          <p>EXAMPLE: Express</p>
          <div className="table-ctn">
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow>
                    <TableCell>Position </TableCell>
                    <TableCell>Outcome</TableCell>
                    <TableCell>Coefficient</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Finland - Moldova</TableCell>
                    <TableCell>Win 1</TableCell>
                    <TableCell> 1.55</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="table-ctn">
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow>
                    <TableCell>Malta-Croatia </TableCell>
                    <TableCell>Croatia Handicap (-2)</TableCell>
                    <TableCell>1.7</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Bulgaria-England</TableCell>
                    <TableCell>Win 2</TableCell>
                    <TableCell> 1.5</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <p>Total coefficient of Express = 1.55 * 1.7 * 1.5 = 3.95</p>
          <p>
            In case Finland and England won in the above mentioned games, and
            Croatia won by 2 goals advantage, and the given express plays, but
            when calculating the total coefficient there will be 1 instead of
            1.7 (coefficient of winning) and the final coefficient of the given
            express will be equal to 1.55*1*1.5 = 2.325
          </p>
          <p>
            14.2 If as a result of the match the total number of scored goals is
            equal to the total of the bet and among the proposed outcomes of the
            given total bets there has not been the option equal ... (the
            indicated number) of goals, a return is made. Similarly, for the
            individual totals, totals of the player, totals of cards/corners
            /removals, fouls and other parameters if the final value of the
            match and the proposed parameter of the total are equal a return is
            made.
          </p>
          <h4 className="terms-desc-heading">15. Privacy of User’s data</h4>
          <p>
            15.1 The User is responsible for maintaining the confidentiality of
            any information related to their account, and shall take all
            measures to prevent the use of personal accounts by unauthorized
            persons. Any operations confirmed by the username and password of
            the account holder shall be valid and have legal force.
          </p>
          <p>
            15.2 If the User suspects that his username and password are known
            to a third person, at any time they have the right to send a request
            to {BRAND_NAME} to block the account temporarily for all
            circumstances to be made clear.
          </p>
          <p>
            15.3 To ensure the security of personal data of Users, {BRAND_NAME}
            communicates with Users via the e-mail indicated by the User during
            registration.
          </p>
          <p>
            15.4 The User is responsible for maintaining the confidentiality of
            any information related to their e- mail, and shall take all
            measures to prevent the use of e-mail address by third parties.
            {BRAND_NAME} is not responsible for any loss or damage resulting
            from the connection between the bookmaker office and the User using
            a registered e-mail address.
          </p>
          <h4 className="terms-desc-heading">16. Match-fixing</h4>
          <p>
            16.1 In case of possible interference in the match result and the
            formation of the coefficients for the match (including cases of
            suspicion in match-fixing), {BRAND_NAME} reserves the right to
            suspend the payments on this event or to cancel the bets on this
            match, as well as hand over the information on relevant side to the
            appropriate authorities for further investigation and prevention of
            similar incidents.
          </p>
          <p>
            16.2 {BRAND_NAME} reserves the right to suspend the payment of the
            rates and make a return on the rates to the event, if there are
            solid grounds to believe this match was negotiable. Such grounds may
            be based on the size, number and nature of the bets made by one or
            all of the possible methods of betting.
          </p>
          <p>
            The decision of the governing bodies of relevant federations and
            regulatory bodies are also taken into account. In such cases{' '}
            {BRAND_NAME}
            reserves the right to hand over the information on related party to
            the governing bodies for further investigation and preventing
            similar incidents.
          </p>
          <h4 className="terms-desc-heading">17. Neutral field</h4>
          <p>
            17.1 In cases when the match is transferred to a neutral field (NF)
            rates persist. They are refundable in cases when the match is
            transferred to the opponent’s field. In case of the play when the
            teams are from the same city and are transferred to another field,
            bets are valid and are not refundable. In international matches, the
            refund of bets is made only when the match transfers to the field of
            an opponent state.
          </p>
          <h4 className="terms-desc-heading">18. Interrupted events</h4>
          <p>
            18.1 An event is considered interrupted if there was no play for an
            indispensable amount of playing time specified according the rules
            of the company, even if the result at which the event was
            interrupted is later confirmed to be final.
          </p>
          <p>
            18.2 The matches consider to be taken place, if they have been
            interrupted, not finished in the time- frame specified in the points
            6 and 7 and if the played time is the following:
          </p>
          <ul>
            <li>Soccer - 90 min.</li>
            <li>Futsal (duration 2 x 20 minutes.) - 35 minutes</li>
            <li>Futsal (duration 2 x 25 minutes.) - 45 minutes</li>
            <li>
              Basketball NBA (National Basketball Association and PBA, CBA) - 43
              min.
            </li>
            <li>Eurobasketball - 35min.</li>
            <li>Hockey NHL (National Hockey League) - 55 min.</li>
            <li>Bandy - 60 min</li>
            <li>Eurohockey - 50 min.</li>
            <li>American football - 55 min.</li>
            <li>Baseball - 5 periods (innings)</li>
            <li>Handball - 50 min</li>
          </ul>
          <p>
            18.3 For interrupted events recognized as cancelled, if not
            otherwise stipulated in the rules on sports, all bets are
            refundable, except for ones for which the result is exactly defined
            at the time of the stop of the event. For interrupted events bets on
            the relative performance time (periods, etc.) are a subject to
            unconditional return.
          </p>
          <h4 className="terms-desc-heading">19. Events transfer</h4>
          <p>
            19.1 If the start time of the event has been changed and otherwise
            is not stipulated in the rules of sports, all bets on the event are
            refundable.
          </p>
          <p>
            19.2 During a game on the passage to the next round, in cases of
            transferring the game period onto subsequent days, or changes of the
            field, the bets remain valid. If the “passage” of the team which
            goes to the next round later has been canceled because of any
            reason, the cancellation is disregarded, and the payment is made
            according the results of played matches. If the second match did not
            take place or was interrupted, then all bets on it, including the
            passage, are refundable.
          </p>
          <p>
            Payments on the passage are made only at bets made before the first
            match. If none of the matches has taken place, the bets will be
            refunded.
          </p>
          <h4 className="terms-desc-heading">20. Calculation of bets</h4>
          <p>
            20.1 {BRAND_NAME} validates the results on the basis of official
            reports and alternative sources of information (including videos of
            the event) after finishing the event. Calculation of bets is based
            on the results published on the website www.{BRAND_NAME} .com in the
            section “Results”. In case of discrepancy of results from different
            sources of information, as well as in the case of their obvious
            mistakes the final decision to determine the results belongs to{' '}
            {BRAND_NAME} .
          </p>
          <p>
            20.2 All inquiries of Users regarding to the correctness of the
            results of {BRAND_NAME} site should be based only on the basis of
            official sources of information (websites of regulatory bodies for
            these championships). Information from other sources cannot be
            considered a basis for the reviewing/reappraisal of bets.
          </p>
          <p>
            20.3 Valid (factual) results considered to be the results, which are
            announced on the basis of official protocols or other sources of
            information, right after the end of the event.The organizer uses the
            following official sources:
          </p>
          <p>www.uefa.com</p>
          <p>www.fifa.com</p>
          <p>www.beto.gr</p>
          <p>www.bundesliga.at</p>
          <p>www.turkish-soccer.com</p>
          <p>www.rfpl.org</p>
          <p>www.premierleague.com</p>
          <p>www.sportinglife.com</p>
          <p>www.gazzetta.it</p>
          <p>www.bundesliga.de</p>
          <p>www.laliga.es</p>
          <p>www.lfp.fr</p>
          <p>www.mlssoccer.com</p>
          <p>www.nfl.com</p>
          <p>www.russiabasket.ru</p>
          <p>www.nba.com</p>
          <p>www.euroleague.net</p>
          <p>www.nhl.com</p>
          <p>www.fhr.ru</p>
          <p>www.rusbandy.ru</p>
          <p>www.atptennis.com</p>
          <p>www.wtatour.com</p>
          <p>www.itftennis.com</p>
          <p>www.daviscup.com</p>
          <p>www.fedcup.com</p>
          <p>www.ausopen.com</p>
          <p>www.rolandgarros.com</p>
          <p>www.wimbledon.com</p>
          <p>www.usopen.org</p>
          <p>www.mlb.com</p>
          <p>www.pgatour.com</p>
          <p>www.iccworldtwenty20.com</p>
          <p>www.fide.com</p>
          <p>www.volley.ru</p>
          <p>www.nyse.com</p>
          <p>www.nasdaq.com</p>
          <p>
            The organizer can use other official sources when there is need. In
            exceptional cases, the organizer reserves the right to use video
            viewing to adjudge the true result.
          </p>
          <p>
            20.4 Live event bet results are calculated based on what is known
            immediately after the end of the event/competition. All following
            changes (for example, changes by decision of a jury after the game)
            are not taken into account while deciding the bets. The company
            itself calculates these bets based on its statistical data about the
            actual course of the game. If not otherwise indicated, all bets on
            non- participating athletes in the competition will be void.
          </p>
          <p>
            20.5 In cases when the company’s statistics (based on TV coverage)
            is different from the official statistics, the bets are calculated
            according to the company’s statistics.
          </p>
          <p>
            20.6 If more than one participant or team is declared as a winner of
            the competition, the coefficient of winnings is divided by the
            number of winners (the coefficient can’t be less than 1)
          </p>
          <p>
            20.7 All operations on playing accounts are made in the currency
            chosen by the User during registration.
          </p>
          <p>
            20.8 All bets for which the outcomes are already known (in
            particular bets on who will score the next
          </p>
          <p>
            goal or win a certain segment of the match), are considered valid
            and are calculated even if the event or tournament was suspended.
          </p>
          <p>
            20.9 If a match is not completed and is considered as incomplete,
            then all bets on it are refundable, (except what is mentioned in
            point 16.1) even if the result, at which the event was suspended,
            later is confirmed as final.
          </p>
          <p>
            20.10 If it does not contradict the special rules of individual
            sports, the result announced on the day of the match is taken for
            the result of the match. Any possible changes in the result,
            conditioned by the revision of the results of the event by governing
            bodies and disciplinary penalties of teams, for the purpose of
            betting, are not taken into account, and the initial results of the
            calculation of bets remain in force.
          </p>
          <p>
            20.11 In our system, the deposit limit logic works with fixed
            calendar day hours with (GMT+4) time zone. Meaning that the user
            will be able to deposit a maximum amount of his limit during 24
            hours from 12 am (GMT+4) till 12 am (GMT+4) of the next day.
          </p>
          <h4 className="terms-desc-heading">
            21. Betting groups/syndicates and Match/Event limit
          </h4>
          <p>
            21.1 Users are required to bet only on their own behalf. Recurring
            bets containing the same selections from one or more clients can be
            recognized as invalid and get canceled by the company. Even after
            the official outcome of such bets is known, in cases when the
            company is confident that Users have acted in collusion or as a
            syndicate, or suspicious bets have been made by one or more Users in
            a short period of time, such bets may be considered invalid and get
            canceled by the company.
          </p>
          <p>
            21.2 Bet limits by sport events are set accordingly to the player’s
            account status and individual bet/event. If the player makes an
            attempt to place a bet outside the limits specified for an event, a
            warning message ” Max. Stake – Limit Reached / Official limit
            exception” will be displayed in the bet slip as a notification.
            Multiple bets which combine same selection within the same bet event
            are not accepted. Avoiding the Warning Message and Non-compliance
            with this rule will result with cancelation of all placed bets and a
            refund of the initial stake.
          </p>
          <h4 className="terms-desc-heading">
            22. Non-participation in the event
          </h4>
          <p>
            22.1 If it does not contradict the special rules of individual
            sports, when an event participant for whatever reason cannot take
            part in the event which was bet on, the bet is considered valid and
            lost by the client. The exceptions are the following cases:
          </p>
          <p>1.The event/tournament is canceled;</p>
          <p>2.The event /tournament is considered invalid;</p>
          <p>3.The location of the event was changed;</p>
          <p>4.The event/tournament is suspended.</p>
          <p>
            22.2 In case of bets “Who is taller” meaning bets with two outcomes,
            if one of the participants refuses to take part before the
            event/tournament, the bet is refundable.
          </p>
          <h4 className="terms-desc-heading">23. Rules on sports</h4>
          <p>
            23.1 Additional information about types of bets on different sports
            is indicated in the rules of sports.
          </p>
          <p>23.2 Sport rules take precedence over the general rules.</p>
          <p>23.3 Some types of bets</p>
          <p>
            23.4 In case of betting on the “Odd number/Even number of Total”,
            regardless of the sports and the proposed position (including total
            goals/cards/fouls etc.) if the result is “0′′, the bets are
            calculated as “Even number”
          </p>
          <p>
            23.5 All bets on the number of medals are calculated according to
            the official medal table at the end of the event. Any changes
            undertaken by the governing bodies later are not taken into account.
          </p>
          <p>
            23.6 Any medals won by the country team in any competitions shall be
            considered as one regardless of the number of participants in this
            team.
          </p>
          <p>
            23.7 In case if any market has the potential outcome “Any other”,
            this outcome includes all participants of the event, not listed in
            the options of that market separately.
          </p>
          <p>
            23.8 In case of game on any event in the “additional markets” of
            tours (total number of goals, wins, losses, draws, angular, yellow
            cards etc.), only those matches of the tour are taken into account
            which are included in the daily line.
          </p>
          <h4 className="terms-desc-heading">24. Registration</h4>
          <p>
            24.1 Only registered Users can participate in bonus-programs of the
            company. To register, fill in the registration form by providing
            your first name, last name and email. After receiving a verification
            email, follow the instructions to verify your account to be able to
            make deposits and place bets.
          </p>
          <p>
            24.2 Registration and betting is allowed exclusively to people over
            18 years old (or the allowed age range in the given country from
            which the user has opened a deposit and made bets). Users have
            complete responsibility as to dealing with the legality of Internet
            gambling in the region where they live and/or for providing the
            appropriate authorities of their country with information on
            winnings. The Company reserves the right to require proof of age and
            block the User’s account before receiving the appropriate
            documentation.
          </p>
          <p>
            24.3 All information provided during the registration must be
            accurate and complete. In particular, when using credit or debit
            cards, the cardholder’s name and surname must match the name and
            surname indicated in the registration form, otherwise the account
            will be blocked. All bets made before locking the account are
            recognized as valid.
          </p>
          <p>
            24.4 The Company reserves the right to block the accounts of Users
            who have reported false data, as well as to deny them the payment of
            any winnings. At the request of the company the User must present an
            official document with a photograph, identifying his identity (a
            passport copy), and also proof of authenticity of the indicated
            address data and telephone.
          </p>
          <p>
            24.5 Each User can have only one account. Registered Users cannot
            re-register as a new client with a new name or a new email address.
            In case of violation of this rule, the company has the right to void
            all bets made by the User.
          </p>
          <p>
            24.6 The User has no right to permit any third party use their game
            account.
          </p>
          <p>
            24.7 Please note that you should not send us the details about your
            credit card account or other sensitive financial information via an
            unencrypted e-mail.
          </p>
          <p>
            24.8 {BRAND_NAME} allows all its Users to choose their own
            combination of username and password. Users should keep such
            information in secret. If your username and password are entered
            correctly while accessing the site, all bets remain in force and
            cannot be canceled or changed by the User. If you suspect that
            someone other than you knows your password, change it immediately on
            our website. If you have forgotten the password or a part of it,
            please send a request to Live Support for a new
          </p>
          <p>password.</p>
          <p>
            24.9 Users who place their bets in {BRAND_NAME} via a cell phone
            should remember that {BRAND_NAME} is not responsible for any loss of
            data in the mobile phone of the client, and is not responsible for
            any commission of mobile and internet operators. By registering, the
            client confirms that he accepts and agrees to these Terms.
          </p>
          <h4 className="terms-desc-heading">25. Currency</h4>
          <p>
            25.1 Currently Users have the right to place bets in the following
            currencies: EUR and USD. {BRAND_NAME} reserves the right to block
            the reception of bets and operating activities in any of the
            indicated currencies. In this case, all the necessary payments on
            the accounts of blocked currency would be held in another currency
            equivalent at the interbank exchange rate for that day.
          </p>
          <h4 className="terms-desc-heading">26. The bonus program</h4>
          <p>
            26.1 All bonuses of Users are limited individually to one person,
            home address, telephone number and e-mail address, one bill of
            payments (such as by card number or Skrill account), as well as the
            computer being used (including the institute, Internet club and
            other public institutions). The company has the right to refuse the
            bonus to any User or group of User. Bonus programs are available
            only to Users who have made a deposit in real currency to their
            account in {BRAND_NAME} .
          </p>
          <p>
            26.2 In case of violation of any requirement of the bonus programs,
            and also if there is any evidence of recurrent bets at one and the
            same event from a group of customers, or conspiracy, regardless of
            the result of the given bets, {BRAND_NAME} reserves the right to
            deprive these Users from a bonus and to consider the corresponding
            bets as invalid. For the purposes of protection against fraud the
            company has the right to demand a document from the client proving
            identity before transferring a bonus.
          </p>
          <h4 className="terms-desc-heading">27. Deposits</h4>
          <p>
            27.1 Available methods of payments are determined by the country and
            the currency selected during registration. A complete list of fees,
            limits on them and other items is displayed on the Deposits and
            Withdrawals page. {BRAND_NAME} reserves the right to change these
            terms and details.
          </p>
          <p>
            27.2 When conducting any financial transactions it is necessary that
            the name of the owner of the debit/credit card or bank account
            exactly match the name of the owner of the appropriate account of
            {BRAND_NAME} . Otherwise {BRAND_NAME} reserves the right to cancel
            all transactions and make a return on all bets made while using
            someone else’s account or credit/debit card.
          </p>
          <h4 className="terms-desc-heading">28. Entry of money on account</h4>
          <p>
            28.1 If any funds have been transferred to the User erroneously, the
            User is obliged to notify the company immediately. Any winnings of
            the client arising from such an error shall be considered invalid,
            and such bets are refundable, regardless of the delay between the
            origin of the error and the time it was seen.
          </p>
          <p>
            28.2 If the deposits to the account were made for any other purpose
            than bets, poker, casino and financial betting, the company
            (particularly in case of suspected fraudulent money turnover)
            reserves the right to cancel a deposit and collect from the User all
            costs incurred as a result of processing the deposit.
          </p>
          <p>
            28.3 If the User’s deposit exceeds the bet’s amount, at the client’s
            request for withdrawal {BRAND_NAME}
          </p>
          <p>
            reserves the right to charge the User all costs incurred as a result
            of processing deposits and withdrawals.
          </p>
          <h4 className="terms-desc-heading">29. Financial Limitations</h4>
          <p>
            29.1 The maximum winning of one ticket is 100 000 USD. If the bet
            comes with a bonus and thereby the winning sum exceeds 100 000 USD,
            the bonus is not paid. -The maximum coefficient of a bet is 1000. In
            case it exceeds 1000, the winning is calculated with 1000
            coefficient.
          </p>
          <p>
            29.2 The minimum single bet on any event is €0.1 or equivalent
            amount in players account currency. The minimum bet in express
            or/and system bet is €0.1 or equivalent amount in players account
            currency. Unless otherwise stated, only one bet per sport event or
            match is allowed. Maximum stake per bet must be equal or smaller
            than the last deposit amount.
          </p>
          <p>
            29.3 The maximum coefficient of express bets is 1000. Express bets,
            in which the coefficient of winnings exceeds the maximum, are
            calculated with a coefficient of 1000. In these cases, the sum of
            winnings should not exceed the maximum payment for a single bet. The
            maximum coefficient of winnings for each version of the system is
            1000. Variants of systems on which the coefficient of winnings
            exceeds the maximum, are calculated with coefficient 1000.
          </p>
          <p>
            29.4 The maximum amount of the bet on the event depends on the sport
            and the events and is defined by the bookmaker network specifically
            for each event and each type of bet and is subject to change without
            prior written notice. {BRAND_NAME} reserves the right to limit the
            maximum bet on individual events, as well as the introduction and
            removal of specific restrictions on the accounts of individual Users
            without notice or explanation of reasons.
          </p>
          <p>
            29.5 All financial limits apply to any individual customer, or/and
            syndicates group of players acting as a collective unit, who have
            placed two or more bets containing the same match selections,
            including where placed in a series of bets, at a different range of
            odds markets, over a number of days using various players’ accounts
            and/or across different betting channels. Should we identify that
            two or more bets have been placed this way, all those bets will be
            considered as void and only first betting slip submitted will be
            considered as a legitimate bet.
          </p>
          <h4 className="terms-desc-heading">30. Payments / Withdrawals</h4>
          <p>
            30.1 Payments are processed in a time not exceeding 72 hours
            starting from the moment that the request was made. Before the first
            payment will be made to the User by electronic payment methods
            (Skrill, credit or debit card, etc.), the client is obliged to
            attach an electronic copy of a passport to the Documents section to
            confirm their identity. Remember that forgery is severely punished
            by law, and in cases of suspicion of placing a counterfeit or an
            edited copy of the documents by electronic methods, {BRAND_NAME}{' '}
            reserves the right to send such documents to the appropriate
            regulatory authorities.
          </p>
          <p>
            30.2 Before making the payment, the employees of the company will
            verify the correspondence of the name, surname, father’s name, birth
            dates of the client and also other data. If differences are found
            between the actual data and the data provided by the client,{' '}
            {BRAND_NAME}
            reserves the right to make a refund for all bets by the User and
            refuse to pay out winnings to the User unless they prove the
            identity and accuracy of entered data.
          </p>
          <p>
            30.3 If it turns out that the User has opened several accounts in
            the company, {BRAND_NAME} reserves the right to refuse to pay out
            for these accounts (except the User’s assets legitimately
            transferred to the account of {BRAND_NAME} after his payment of a
            fine in the measure of 10% of the total amount of deposits).
          </p>
          <p>
            30.4 In the first order for a withdrawal in cash the User must enter
            valid passport details exactly as in the passport in the language of
            the country that issued the passport (or in the case of foreign
            passports – in English).
          </p>
          <p>
            30.5 Group and family members should regulate personal relationships
            with each other – payments
          </p>
          <p>
            are made ONLY in the name of the owner of the appropriate account.
          </p>
          <p>
            30.6 The User agrees to provide {BRAND_NAME} with information about
            his bank account from which the bets will be made in particular, in
            order to transfer his winnings.
          </p>
          <p>
            30.7 {BRAND_NAME} is not responsible for changes in the amount of
            payments related to fluctuations of currency (rate of exchange).
          </p>
          <p>
            30.8 If the User has requested a withdrawal in the amount of 1,000
            Euros or more (or the equivalent in another currency at the
            interbank rate), {BRAND_NAME} pays a commission on the transfer and
            subsequent operations of withdrawals in the given calendar month.
            Otherwise, the commission is paid to the bank by the User.
          </p>
          <p>
            30.9 The Company reserves the right of funds’ withdrawal using a
            priority for itself method of payment for winning players (including
            credit/debit card or to the player’s bank account).
          </p>
          <p>
            30.10 Before ordering the first payment, the player must bet on at
            least 50% of the payment as a deposit of money amount. This
            requirement is introduced to combat fraud and “money laundering” by
            players.
          </p>
        </div>
      </div>
      <Copyright />
    </div>
  );
};

export default TermsConditions;
