import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useHistory } from 'react-router-dom';

type BetCashOutProps = {
  open: boolean;
  handleClose: () => void;
  message: string;
};

const MessageDialog: React.FC<BetCashOutProps> = (props) => {
  const { open, handleClose, message } = props;
  let history = useHistory();

  const submitHandler = () => {
    handleClose();
    history.push('/');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <div className="bcd-body">
          <div className="bcd-amount-row">
            <span className="bcd-label">{message} </span>
          </div>
          <div className="bcd-submit-row">
            <Button
              className="bcd-cancel-btn"
              color="primary"
              variant="contained"
              onClick={() => submitHandler()}
            >
              OK
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MessageDialog;
