import React, { useState, useEffect, useCallback } from 'react';
import { IonButton, IonRow, IonIcon } from '@ionic/react';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment, { Moment } from 'moment';

import { GameReportMatchLevel } from '../../models/GameReportModels';
import Spinner from '../../components/Spinner/Spinner';
import { AuthResponse } from '../../models/api/AuthResponse';
import API from '../../api';
import GameReportMarketLevelView from '../../components/GameReportBets/GameReportMarketLevel';
import CricketIcon from '../../assets/images/icons/cricket-icon.svg';
import FootballIcon from '../../assets/images/icons/soccer-icon.svg';
import TennisIcon from '../../assets/images/icons/tennis-icon.svg';
import './GameReport.scss';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import { getCurrencyTypeFromToken } from '../../store';
import REPORTING_API from '../../reporting-api';

const GameReport: React.FC = () => {
  const [showByMaketLevel, setShowMaketLevelSummary] = useState<boolean>(false);
  const [reportRecords, setReportRecords] = useState<GameReportMatchLevel[]>(
    []
  );
  const [loading, setLoading] = useState<Boolean>(false);
  const [startDate, setStartDate] = useState<Moment>(moment().subtract(7, 'd'));
  const [endDate, setEndDate] = useState<Moment>(moment());
  const [pageNum, setPageNum] = useState<number>(1);
  const [matchInfo, setMatchInfo] = useState<{ id: string; name: string }>();
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];

  const [pageToken, setPageToken] = useState<string[]>(['']);
  const [nextPageToken, setNextPageToken] = useState<string>('');
  const pageSize = 25;

  const nextPage = () => {
    setLoading(true);
    setPageNum(pageNum + 1);
    if (nextPageToken !== null && nextPageToken !== '') {
      setPageToken([...pageToken, nextPageToken]);
    }
    setLoading(false);
  };

  const prevPage = () => {
    setLoading(true);
    setPageNum(pageNum - 1);
    if (pageToken.length > 1) {
      let pagetokens = pageToken;
      pagetokens.pop();
      setPageToken([...pagetokens]);
    }
    setLoading(false);
  };

  const fetchReportSummary = useCallback(async () => {
    setLoading(true);
    try {
      const response: AuthResponse = await REPORTING_API.get(
        '/reports/v2/game-report',
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
          params: {
            startDate: startDate.startOf('day').toISOString(),
            endDate: endDate.endOf('day').toISOString(),
            pageSize: pageSize,
            pageToken: pageToken[pageToken.length - 1],
          },
        }
      );
      setReportRecords(response.data?.gameReport);
      setNextPageToken(response.data?.nextPageToken);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, [startDate, endDate, pageToken]);

  useEffect(() => {
    if (!showByMaketLevel) {
      fetchReportSummary();
    }
  }, [showByMaketLevel, fetchReportSummary]);

  const sportIconsMap = {
    cricket: CricketIcon,
    soccer: FootballIcon,
    football: FootballIcon,
    tennis: TennisIcon,
  };

  return loading ? (
    <Spinner />
  ) : !showByMaketLevel ? (
    <>
      <div className="reports-ctn">
        <div className="header-ctn">
          <IonRow>
            <div className="img-page-title-row">
              <div className="title">Game Report</div>
            </div>

            <div className="filters-row">
              <div className="date-section">
                <div className="date-filter">
                  <div className="date-filter-label">From</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      className="date-filter-control"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                      disableToolbar
                      minDate={moment().subtract(1, 'months').calendar()}
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="from-date"
                      value={startDate}
                      onChange={(e) => setStartDate(e)}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="date-filter">
                  <div className="date-filter-label">To</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      className="date-filter-control"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                      minDate={moment().subtract(1, 'months').calendar()}
                      maxDate={moment()}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="to-date"
                      value={endDate}
                      onChange={(e) => setEndDate(e)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </IonRow>
        </div>

        <div className="content-ctn light-bg">
          {reportRecords && reportRecords.length > 0 ? (
            <>
              <div className="tbl-ctn game-rep-summary">
                <TableContainer component={Paper} className="apl-table-ctn">
                  <Table className="apl-table">
                    <TableHead className="apl-table-header">
                      <TableRow>
                        <TableCell align="left" className="web-view">
                          Match Date
                        </TableCell>
                        <TableCell align="left" className="event-name-col">
                          Match Name
                        </TableCell>
                        <TableCell className="web-view" align="right">
                          Markets Count
                        </TableCell>
                        <TableCell className="web-view" align="right">
                          Bets
                        </TableCell>
                        <TableCell align="left" className="web-view">
                          Won
                        </TableCell>
                        <TableCell align="left" className="lost-col">
                          Lost
                        </TableCell>
                        <TableCell align="left" className="lost-col">
                          Void
                        </TableCell>
                        <TableCell align="left">Profit / Loss</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody className="apl-table-body">
                      {reportRecords.map((row, index) => (
                        <TableRow
                          className="apl-table-row"
                          key={index}
                          onClick={() => {
                            setMatchInfo({
                              id: row.eventId,
                              name: row.eventName,
                            });
                            setShowMaketLevelSummary(true);
                          }}
                        >
                          <TableCell
                            className="web-view"
                            align="left"
                            scope="row"
                          >
                            {moment(row.eventDate).format(
                              'DD-MM-YY, h:mm:ss A'
                            )}
                          </TableCell>
                          <TableCell align="left" scope="row">
                            <div className="web-view b-text m-link text-ellipsis">
                              {row.eventName}
                            </div>
                            <div className="mob-view icon-text-section">
                              <span className="icon-div">
                                <IonIcon
                                  className="img"
                                  color="primary"
                                  src={
                                    row.sportId
                                      ? sportIconsMap[row.sportId.toLowerCase()]
                                      : CricketIcon
                                  }
                                />
                              </span>
                              <span className="text-div">
                                <div className="b-text m-link text-ellipsis mob-fs-13">
                                  {row.eventName}
                                </div>
                                <div>
                                  Bets:{' '}
                                  <span className="b-text">
                                    {row.ordersCount}
                                  </span>
                                </div>
                                <div className="tiny-info-text">
                                  {moment(row.eventDate).format(
                                    'DD-MM-YY, h:mm:ss A'
                                  )}
                                </div>
                              </span>
                            </div>
                          </TableCell>
                          <TableCell
                            className="web-view"
                            align="right"
                            scope="row"
                          >
                            {row.marketsCount}
                          </TableCell>
                          <TableCell
                            className="web-view"
                            align="right"
                            scope="row"
                          >
                            {row.ordersCount}
                          </TableCell>
                          <TableCell
                            align="left"
                            scope="row"
                            className="web-view"
                          >
                            <span className="txt-bldin-mob">
                              {row.winCount}
                            </span>
                          </TableCell>

                          <TableCell
                            align="left"
                            scope="row"
                            className="lost-col"
                          >
                            <span className="txt-bldin-mob">
                              {row.lostCount}
                            </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            scope="row"
                            className="lost-col"
                          >
                            <span className="txt-bldin-mob">
                              {row.voidCount}
                            </span>
                          </TableCell>
                          <TableCell align="left" scope="row">
                            <span
                              className={
                                row.profit > 0
                                  ? 'profit mob-fs-16'
                                  : 'loss mob-fs-16'
                              }
                            >
                              {row.profit > 0 ? '+' : null}
                              {Number(row.profit / cFactor).toFixed(2)}
                            </span>

                            <div className="mob-view">
                              Won:{' '}
                              <span className="mob-fs-14">
                                {row.winCount}/{row.lostCount}
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <IonRow>
                {pageToken.length === 1 || loading ? null : (
                  <IonButton
                    className="myb-btn-prev"
                    onClick={(e) => {
                      prevPage();
                    }}
                  >
                    Prev({pageNum - 1})
                  </IonButton>
                )}
                {(!(nextPageToken === '' || nextPageToken === null) ||
                  reportRecords.length === pageSize) &&
                !loading ? (
                  <IonButton
                    className="myb-btn-next"
                    onClick={(e) => nextPage()}
                  >
                    Next({pageNum + 1})
                  </IonButton>
                ) : null}
              </IonRow>
            </>
          ) : (
            <IonRow className="empty-msg">
              You don't have any game report.
            </IonRow>
          )}
        </div>
      </div>
    </>
  ) : (
    <>
      <GameReportMarketLevelView
        selectedMatchName={matchInfo.name}
        selectedmatchId={matchInfo.id}
        startDate={startDate}
        endDate={endDate}
        setShowMatchLevelReport={() => setShowMaketLevelSummary(false)}
      ></GameReportMarketLevelView>
    </>
  );
};

export default GameReport;
