export const CommissionFancyMarketsList = [
  {
    category: 'T10 Match Markets',
    rules: [
      'Below markets are eligible for commission',
      'Bookmaker ( over all loss )',
      'Fancy markets ( over all turnover )',
      '5 Overs',
      '10 Overs',
      '5 Overs (second innings)',
      "The Site's Decision In Such Integrity Cases Shall Be ﬁnal And Binding Upon The Customer.",
    ],
  },
  {
    category: 'T20 Match Markets',
    rules: [
      'Below markets are eligible for commission',
      'Bookmaker ( over all loss )',
      'Fancy markets ( over all turnover )',
      '6 Overs',
      '10 Overs',
      '15 Overs',
      '20 Overs',
      '6 Overs (second innings) ',
      "The Site's Decision In Such Integrity Cases Shall Be ﬁnal And Binding Upon The Customer.",
    ],
  },
  {
    category: 'One Day Match makets',
    rules: [
      'Below markets are eligible for commission',
      'Bookmaker ( over all loss )',
      'Fancy markets ( over all turnover )',
      'Bookmaker',
      '10 Overs',
      '20 Overs',
      '30 Overs',
      '35 Overs',
      '40 Overs',
      '50 Overs',
      '10 Overs (second innings)',
      "The Site's Decision In Such Integrity Cases Shall Be ﬁnal And Binding Upon The Customer.",
    ],
  },
];
