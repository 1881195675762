import React from 'react';
import './Alert.scss';

type AlertProps = {
  message: string;
};

const Alert: React.FC<AlertProps> = (props) => {
  const { message } = props;
  return <div className="alert-message">{message}</div>;
};

export default Alert;
