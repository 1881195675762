import React, { useState, useCallback, useEffect } from 'react';
import {
  IonRow,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonIcon,
} from '@ionic/react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import moment, { Moment } from 'moment';

import API from '../../api/index';
import { logout, getCurrencyTypeFromToken } from '../../store';
import { LedgerRecord } from '../../models/LedgerRecord';
import Spinner from '../../components/Spinner/Spinner';
import Alert from '../../components/Alert/Alert';

import { Currency } from '../../models/Currency';
import { RootState } from '../../models/RootState';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import './AccountStatement.scss';
import {
  getTransactionNameByID,
  MarketTypeByID,
  MarketTypeMap,
  OutcomeDescMap,
  SportIdByNameMap,
  TransactionTypeMap,
} from '../../util/stringUtil';
import AccountStatementRecord from './AccountStatementRecord';
import Modal from '../../components/Modal/Modal';
import SportSvg from '../../assets/images/home/footer/sport1.png';
import CasinoImg from '../../assets/images/home/footer/casino1.png';
import AccStmtImg from '../../assets/images/icons/acc_stmt.svg';
import { useHistory } from 'react-router-dom';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import REPORTING_API from '../../reporting-api';
import { Button } from '@material-ui/core';

type LedgerProps = {
  logout: Function;
};

const Ledger: React.FC<LedgerProps> = (props) => {
  const history = useHistory();
  const { logout } = props;
  const [errorMsg, setErrorMsg] = useState(null);
  const [dataflag, setdataflag] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  // const [filter, setFilter] = useState<string>('all');
  const filter = 'all';
  const [records, setRecords] = useState<LedgerRecord[]>([]);
  const [currpage, setcurrpage] = useState<number>(1);
  const [sortDesc, setsortDesc] = useState<boolean>(true);
  const [fromDate, setfromDate] = useState<Moment>(moment().subtract(7, 'd'));
  const [transaction, setTransaction] = useState<string>('-1');
  const [toDate, setToDate] = useState<Moment>(moment());
  const [showTransactionDetailsModal, setShowTransactionDetailsModal] =
    useState<boolean>(false);
  const [transactionDetails, setTransactionDetails] =
    useState<LedgerRecord>(null);

  // Pagenation
  const [pageToken, setPageToken] = useState<string[]>(['']);
  const [nextPageToken, setNextPageToken] = useState<string>('');
  const [paginationFlag, setPaginationFlag] = useState<boolean>(false);

  const pageSize = 25;
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];

  const fromDateChangeHandler = (d: Moment) => {
    setfromDate(d);
  };

  const toDateChangeHandler = (d: Moment) => {
    setToDate(d);
  };

  // const filterHandler = (
  //   event: React.ChangeEvent<{}>,
  //   newValue: 'all' | 'settled' | 'unsettled'
  // ) => {
  //   setTabValue(newValue);
  //   setFilter(newValue);
  //   setLoading(true);
  //   setcurrpage(1);
  // };

  const nextpageHandler = () => {
    setLoading(true);
    setcurrpage(currpage + 1);
  };

  const prevpageHandler = () => {
    setLoading(true);
    setcurrpage(currpage - 1);
  };

  const sortOrderHandler = () => {
    setLoading(true);
    setsortDesc(!sortDesc);
  };

  const fetchRecords = useCallback(
    async (filter: string, currpage: number) => {
      setLoading(true);
      try {
        const response: any = await REPORTING_API.post(
          '/reports/v2/account-statement-report',
          {
            user: '',
            transactionGroup: 'all',
            startDate: fromDate.startOf('day').toISOString(),
            endDate: toDate.endOf('day').toISOString(),
            pageSize: 25,
            transactionType: transaction,
            pageToken: pageToken[pageToken.length - 1],
          },
          {
            headers: {
              Authorization: sessionStorage.getItem('jwt_token'),
              Accept: 'application/json',
            },
          }
        );
        let statements = response.data?.accountStatement;
        if (response.status === 200) {
          for (const statement of statements) {
            statement.amount = statement.amount / cFactor;
            statement.balanceAfter = statement.balanceAfter / cFactor;
          }
          setRecords([]);
          setRecords(statements);
          if (response.data.nextPageToken != '') {
            setNextPageToken(response.data.nextPageToken);
          }
          setdataflag(1);
          setLoading(false);
        } else {
          throw new Error(response);
        }
      } catch (err) {
        setLoading(false);
        if (err?.response) {
          setErrorMsg(err.response.data.message);
        }
        if (err.response && err.response.status === 401) {
          logout();
        }
      }
    },
    [logout, transaction, fromDate, toDate, sortDesc, pageToken]
  );

  const onShowTransactionDetails = (row: LedgerRecord) => {
    console.log('aaaaaaaaaaaaaa');
    setShowTransactionDetailsModal(true);
    setTransactionDetails(row);
  };

  useEffect(() => {
    // if (currpage !== 1) {
    //   setcurrpage(1);
    //   setPageToken(['']);
    // }
    fetchRecords(filter, currpage);
  }, [fetchRecords, filter, sortDesc]);

  const nextPage = () => {
    setLoading(true);
    setcurrpage(currpage + 1);
    if (nextPageToken !== null && nextPageToken !== '') {
      setPageToken([...pageToken, nextPageToken]);
    }
    setLoading(false);
  };

  const prevPage = () => {
    setLoading(true);
    setcurrpage(currpage - 1);
    setPaginationFlag(true);
    if (pageToken.length > 1) {
      let pagetokens = pageToken;
      pagetokens.pop();
      setPageToken([...pagetokens]);
    }
    setLoading(false);
    setPaginationFlag(false);
  };

  return (
    <>
      <div className="account-summary-ctn">
        <div className="header-ctn">
          <IonRow className="header-ctn-row">
            <div className="img-page-title-row">
              <div className="title">Account Statement</div>
            </div>
            <div className="title-border-line"></div>

            <div className="filters-row">
              <div className="select-filter web-view">
                <div className="select-filter-label">Transaction Type</div>
                <IonSelect
                  className="select-filter-control"
                  value={transaction}
                  placeholder="Select One"
                  interface="popover"
                  onIonChange={(e) => setTransaction(e.detail.value)}
                >
                  <IonSelectOption value="-1">All</IonSelectOption>
                  <IonSelectOption value="0">Game Report</IonSelectOption>
                  <IonSelectOption value="1">Balance Report</IonSelectOption>
                </IonSelect>
              </div>

              <div className="date-section">
                <div className="date-filter mr-11">
                  <div className="date-filter-label">From</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      className="date-filter-control"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                      disableToolbar
                      variant="inline"
                      minDate={moment().subtract(1, 'months').calendar()}
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="from-date"
                      value={fromDate}
                      onChange={(e) => fromDateChangeHandler(e)}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="date-filter">
                  <div className="date-filter-label">To</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      className="date-filter-control"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                      disableToolbar
                      variant="inline"
                      minDate={moment().subtract(1, 'months').calendar()}
                      maxDate={moment()}
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="to-date"
                      value={toDate}
                      onChange={(e) => toDateChangeHandler(e)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div className="select-filter mob-view">
                <div className="select-filter-label">Transaction Type</div>
                <IonSelect
                  value={transaction}
                  className="select-filter-control"
                  placeholder="Select One"
                  interface="popover"
                  onIonChange={(e) => setTransaction(e.detail.value)}
                >
                  <IonSelectOption value="-1">All</IonSelectOption>
                  <IonSelectOption value="0">Game Report</IonSelectOption>
                  <IonSelectOption value="1">Balance Report</IonSelectOption>
                </IonSelect>
              </div>
            </div>
          </IonRow>
        </div>

        <div>
          {errorMsg ? <Alert message={errorMsg} /> : ''}

          {loading ? (
            <>
              <Spinner />
            </>
          ) : (
            <div>
              <TableContainer component={Paper} className="web-view">
                <Table>
                  <TableHead>
                    {/* <TableRow> */}
                    <TableCell className="date-col" align="center">
                      Place Date
                      <IconButton
                        aria-label="Change Order"
                        size="medium"
                        onClick={() => sortOrderHandler()}
                      >
                        {/* {sortDesc ? (
                          <TableSortLabel active={true} direction="desc" />
                        ) : (
                          <TableSortLabel active={true} direction="asc" />
                        )} */}
                      </IconButton>
                    </TableCell>
                    <TableCell className="transaction-col" align="center">
                      Transaction
                    </TableCell>
                    <TableCell className="hid-mob" align="center">
                      Event
                    </TableCell>
                    <TableCell className="hid-mob" align="center">
                      Market Type
                    </TableCell>
                    <TableCell align="center">Credit/Debit</TableCell>
                    <TableCell align="center">Closing Balance</TableCell>
                    {/* <TableCell align="right">Debit</TableCell> */}
                    {/* <TableCell className="hid-mob" align="right">
                      Balance
                    </TableCell> */}
                    <TableCell className="transaction-id" align="center">
                      Transaction ID
                    </TableCell>
                    {/* <TableCell className="hid-mob" align="center">
                      Txn Count
                    </TableCell> */}
                    {/* </TableRow> */}
                  </TableHead>
                  {records.length > 0 || dataflag === 0 ? (
                    <TableBody className="acc-statement-body">
                      {records.map((row, idx) => (
                        <Row
                          row={row}
                          transaction={transaction}
                          showTransactionDetails={() => {
                            onShowTransactionDetails(row);
                          }}
                          idx={idx}
                          cFactor={cFactor}
                        />
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell className="no-data-row" colSpan={8}>
                          <div> You have no transactions</div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

              <div className="account-ctn mob-view">
                {records.length > 0 || dataflag === 0 ? (
                  <>
                    {records.map((i, idx) => (
                      <div className="account-card">
                        <div className="closing-balance">
                          {i.amount.toFixed(2)}
                        </div>
                        <div className="account-info">
                          <div className="transaction-info">
                            {TransactionTypeMap[i.transactionType]} {' | '} Txn
                            ID: {i.transactionId}
                          </div>
                          <div className="transaction-date">
                            {moment(i.transactionTime).format(
                              'DD-MM-YY, h:mm:ss A'
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
            </div>
          )}

          <Modal
            open={showTransactionDetailsModal}
            title="Transaction Details"
            closeHandler={() => {
              setTransactionDetails(null);
              setShowTransactionDetailsModal(false);
            }}
            size="md"
          >
            {transactionDetails ? (
              <AccountStatementRecord ledgerRecord={transactionDetails} />
            ) : null}
          </Modal>
        </div>

        <IonRow>
          {currpage === 1 || loading ? null : (
            <IonButton className="led-btn-prev" onClick={(e) => prevPage()}>
              Prev({currpage - 1})
            </IonButton>
          )}
          {records.length === pageSize && !loading ? (
            <IonButton className="led-btn-next" onClick={(e) => nextPage()}>
              Next({currpage + 1})
            </IonButton>
          ) : null}
        </IonRow>
      </div>
      {/* <Copyright /> */}
    </>
  );
};

type RowProps = {
  row: LedgerRecord;
  transaction: string;
  showTransactionDetails: Function;
  idx: number;
  cFactor: number;
};

const Row: React.FC<RowProps> = (props) => {
  const { row, transaction, showTransactionDetails, idx, cFactor } = props;

  return (
    <React.Fragment>
      <TableRow className="tb-row">
        <TableCell
          className="date-col"
          component="th"
          scope="row"
          align="center"
        >
          {moment(row.transactionTime).format('DD-MM-YY, h:mm:ss A')}
        </TableCell>
        <TableCell align="center">
          <div className="b-text m-link web-view">
            <Button onClick={() => showTransactionDetails()}>
              {getTransactionNameByID(row.transactionType)}
            </Button>

            {/* {getTransactionNameByID(row.transactionType)} */}
          </div>
          <div className="mob-view">
            <div className="mob-col-section">
              <div>
                <span className="transacction-img">
                  {!getTransactionNameByID(row.transactionType)?.includes(
                    'Casino'
                  ) ? (
                    <IonIcon
                      color="primary"
                      className="img"
                      src={SportSvg}
                    ></IonIcon>
                  ) : (
                    <IonIcon
                      color="primary"
                      className="img"
                      src={CasinoImg}
                    ></IonIcon>
                  )}
                </span>
              </div>
              <div>
                <div className="b-text m-link text-elips">
                  {/* {['0', '1', '2', '3'].includes(row.transactionType.toString())
                  ? row.upLineUser +
                    ' / ' +
                    getTransactionNameByID(row.transactionType)
                  : getTransactionNameByID(row.transactionType)} */}

                  <Button onClick={() => showTransactionDetails()}>
                    {getTransactionNameByID(row.transactionType)}
                  </Button>
                </div>
                <div className="tiny-info-text">
                  TXN ID: {row.transactionId}
                </div>
                <div className="tiny-info-text">
                  {moment(row.transactionTime).format('DD-MM-YY, h:mm:ss A')}
                </div>
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell className="hid-mob" align="center">
          <span className="txt-bldin-mob">
            {row?.eventName ? row.eventName : '-'}
          </span>
        </TableCell>
        <TableCell className="hid-mob" align="center">
          <span className="txt-bldin-mob">
            {row.marketType === 1
              ? row?.marketName || MarketTypeByID(row.marketType.toString())
              : row.marketType === -1
              ? ''
              : MarketTypeByID(row.marketType.toString())}

            {/* {row?.marketType ? MarketTypeMap[row.marketType.toString()] : '-'} */}
          </span>
        </TableCell>
        <TableCell align="center">
          <div
            className={
              row.amount > 0
                ? 'profit mob-fs-16'
                : row.amount < 0
                ? 'loss mob-fs-16'
                : null
            }
          >
            {row.amount > 0
              ? '+' + row.amount.toFixed(2)
              : row.amount.toFixed(2)}
          </div>
        </TableCell>

        <TableCell align="right">{row.balanceAfter.toFixed(2)}</TableCell>
        <TableCell className="transaction-id" align="left">
          <div className="b-text m-link">{row.transactionId}</div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ledger);
