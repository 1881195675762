import React, { useEffect, useState } from 'react';
import { IonRow, IonSelect, IonSelectOption, IonCol } from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import './CasinoNew.scss';
import { RootState } from '../../../models/RootState';
import { GapGame } from '../../../models/dc/DcGame';
import { FatchLiveCasinoGames, getCurrencyTypeFromToken } from '../../../store';
import { Backdrop } from '@material-ui/core';
import Footer from '../../../components/Footer/Footer';
import inplay from '../../../assets/images/common/Banner/Polygon.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import LoginModal from '../../../components/Modal/LoginModal';
import LoginForm from '../../../components/LoginForm/LoginForm';
import { AxiosResponse } from 'axios';
import API from '../../../api';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ClearIcon from '@material-ui/icons/Clear';
import { Dialog, Button } from '@material-ui/core';
import Info from "../../../assets/images/home/homeview/info.svg";

type StoreProps = {
  loggedIn: boolean;
  loggedInUserStatus: any;
  FatchLiveCasinoGames: (type: string) => void;
  liveCasinoGames: GapGame[];
};

const DcPageNew: React.FC<StoreProps> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [filteredProvider, setFilteredProvider] = useState<string>('');
  const [providerList, setProviderList] = useState<string[]>();
  const [favourites, setFavourites] = useState<string[]>([]);
  const [selectedEvolutionGame, setSelectedEvolutionGame] = useState(null);
  const [selectFavourite, setSelectFavouriteFavourites] = useState<{
    gameId: string;
    gameName: string;
    favourites: boolean;
  }>({
    gameId: '',
    gameName: '',
    favourites: false,
  });
  const pathParams = useParams();
  const Selectedprovider = pathParams['gameName'];

  const {
    loggedIn,
    loggedInUserStatus,
    FatchLiveCasinoGames,
    liveCasinoGames,
  } = props;

  const history = useHistory();
  const [infoDialog, setInfoDialog] = useState<boolean>(false);

  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    provider: string,
    subProvider: string
  ) => {
    if (loggedInUserStatus === 0 || loggedInUserStatus === 3) {
      history.push(`/home`);
    }
    if (provider === 'Indian Casino') {
      history.push(`/casino/indian/${gameCode}`);
    } else {
      history.push(
        `/gap_casino/game/${gameName.toLowerCase().replace(/\s+/g, '-')}-${btoa(
          gameId.toString()
        )}-${btoa(gameCode)}-${btoa(provider)}-${btoa(subProvider)}`
      );
    }
  };

  const handleGameClick = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    subProvider: string,
    provider?: string
  ) => {
    if (loggedIn) {
      if (
        getCurrencyTypeFromToken() === 0 &&
        subProvider.toLowerCase() === 'evolution gaming'
      ) {
        setSelectedEvolutionGame({
          gameId,
          gameName,
          gameCode,
          provider,
          subProvider,
        });
        setInfoDialog(true);
        return;
      }
      getGameUrl(gameId, gameName, gameCode, provider, subProvider);
    }
  };

  useEffect(() => {
    FatchLiveCasinoGames('live-casino');
  }, []);

  useEffect(() => {
    if (Selectedprovider) {
      setFilteredProvider(Selectedprovider);
    } else {
      setFilteredProvider('');
    }
  }, [Selectedprovider]);
  useEffect(() => {
    getFavourites();
    if (liveCasinoGames?.length > 0)
      setProviderList(
        liveCasinoGames
          .map((g) => g.subProviderName)
          .filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
      );
  }, [liveCasinoGames]);

  const updateFavourites = async (gameId: string, isFavourite: boolean) => {
    setShowDialog(false);
    if (!loggedIn) {
      return;
    }
    try {
      let response: AxiosResponse = await API.post(
        '/user/update-favorite-game',
        {
          addGame: !isFavourite,
          gameId: gameId,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.data.success) {
        getFavourites();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getFavourites = async () => {
    if (!loggedIn) {
      return;
    }
    let response: AxiosResponse<string[]>;
    try {
      response = await API.post('/user/get-favorite-game', null, {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
      setFavourites(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  let allList = liveCasinoGames.filter(
    (g) => g.category !== 'Video Slots' && g.category !== 'VIRTUAL'
  );

  return (
    <div className="dc-page-bg">
      <div className="dc-ctn">
        <IonRow class="casino-filters-row">
          <div className="title">
            {filteredProvider ? filteredProvider : 'Live'} Games
          </div>

          <IonSelect
            className="select-filter"
            value={filteredProvider}
            placeholder="Select One"
            interface="popover"
            onIonChange={(e) => setFilteredProvider(e.detail.value)}
          >
            <IonSelectOption key={'option--1'} value={''}>
              {'All'}
            </IonSelectOption>
            {providerList &&
              providerList.map((opt, idx) => (
                <IonSelectOption key={'option-' + idx} value={opt}>
                  {opt}
                </IonSelectOption>
              ))}
          </IonSelect>
        </IonRow>

        {allList && allList.length > 0 ? (
          <>
            <IonRow className="dc-games-ctn">
              {allList.map((g, i) => (
                <>
                  {filteredProvider &&
                    g.subProviderName?.toLowerCase() !==
                    filteredProvider.toLowerCase() ? null : (
                    <IonCol
                      sizeXs="4"
                      sizeSm="4"
                      sizeMd="2"
                      sizeLg="2"
                      key={g.gameName}
                      className="dc-ion-col"
                    >
                      <div className="container1"
                        onClick={() =>
                          handleGameClick(
                            g.gameId,
                            g.gameName,
                            g.gameCode,
                            g.subProviderName,
                            g.providerName
                          )
                        }>
                        <img
                          src={g.urlThumb ? g.urlThumb : ''}
                          alt={g.gameName}
                          className="dc-img"
                        />
                        <div className="game-name">
                          <span className="name">
                            {g?.gameName.length > 21? g?.gameName?.slice(0, 20) + "..": g?.gameName}

                            {favourites.includes(g.gameId) ? (
                              <FavoriteIcon
                                htmlColor="red"
                                className="favoriteIcon"
                              />
                            ) : null}

                          </span>
                          {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}

                          {/* <span className="p-name">{g.subProviderName}</span> */}
                        </div>

                        {loggedIn && (
                          <div className="fav-icon">
                            <div
                              className="custom-btn"
                              onClick={() => {
                                setShowDialog(true);
                                setSelectFavouriteFavourites({
                                  gameId: g.gameId,
                                  gameName: g.gameName,
                                  favourites: favourites.includes(g.gameId)
                                    ? true
                                    : false,
                                });
                              }}
                            >
                              {favourites.includes(g.gameId) ? (
                                <>
                                  {' '}
                                  <FavoriteIcon htmlColor="red" />
                                </>
                              ) : (
                                <FavoriteBorderIcon htmlColor="white" />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </IonCol>
                  )}
                </>
              ))}
            </IonRow>
          </>
        ) : null}
      </div>

      {/* <Footer /> */}

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="form-dialog-title"
        className="dialog-div"
      >
        <div className="dialog-div-body">
          <div className="go-corner" onClick={() => setShowDialog(false)}>
            <div className="go-arrow">
              <ClearIcon />
            </div>
          </div>
          <div className="dialog-title">
            Welcome {sessionStorage.getItem('username')}
          </div>

          <div className="dialog-body">
            {selectFavourite?.favourites ? (
              <p className="dialog-ex">
                Do you want to remove {selectFavourite?.gameName} to favorite games
              </p>
            ) : (
              <p className="dialog-ex">
                Do you want to add {selectFavourite?.gameName} to favorite games
              </p>
            )}
          </div>

          <div className="dialog-continue">
            <Button
              className="btn"
              onClick={() =>
                updateFavourites(
                  selectFavourite?.gameId,
                  selectFavourite?.favourites
                )
              }
            >
              Yes
            </Button>
            <Button className="cancel-btn" onClick={() => setShowDialog(false)}>
              No
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={infoDialog}
        onClose={() => {
          setInfoDialog(false);
          setSelectedEvolutionGame(null);
        }}
        aria-labelledby="form-dialog-title"
        className="dialog-div"
      >
        <div className="dialog-div-body">
          <div className="dialog-title">Game Info</div>
          <div className="dialog-body">
            1 Casino Point is equal to 10 Normal Points.
            <div className="dialog-ex">
              {' '}
              Ex: 100 Casino Points = 1000 Points
            </div>
          </div>
          <div className="dialog-continue">
            <div
              className="custom-btn btn"
              onClick={() =>
                getGameUrl(
                  selectedEvolutionGame?.gameId,
                  selectedEvolutionGame?.gameName,
                  selectedEvolutionGame?.gameCode,
                  selectedEvolutionGame?.provider,
                  selectedEvolutionGame?.subProvider
                )
              }
            >
              Continue
            </div>

            <div
              className="custom-btn-close"
              onClick={() => {
                setInfoDialog(false);
                setSelectedEvolutionGame(null);
              }}
            >
              Close
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  let status = 0;
  if (state.auth.loggedIn) {
    status = JSON.parse(
      window.atob(sessionStorage.getItem('jwt_token').split('.')[1])
    ).status;
  }
  return {
    loggedIn: state.auth.loggedIn,
    loggedInUserStatus: status,
    liveCasinoGames: state.indianCasino.liveCasinoGames,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    FatchLiveCasinoGames: (type: string) =>
      dispatch(FatchLiveCasinoGames(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DcPageNew);
