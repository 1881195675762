import React, { useEffect, useState } from 'react';
import './Copyright.scss';
import instagram from '../../assets/images/footer/Instagram.svg';
import skype from '../../assets/images/footer/skype.svg';
import telegram from '../../assets/images/footer/Telegram.svg';
import whatsopp from '../../assets/images/header/whatsup-flot.svg';
import mail from '../../assets/images/footer/Mail.svg';
import { IonIcon } from '@ionic/react';
import { BRAND_NAME } from '../../constants/Branding';
import { UpdateContactWhiteLabels } from '../../constants/WhitelabelConfig';
import { AuthResponse } from '../../models/api/AuthResponse';
import SVLS_API from '../../svls-api';

type customerSupportDTO = {
  customerSupportNumber: string;
};

type whatsappDTO = {
  whatsappNumber: string;
};

type telegramDTO = {
  telegramNumber: string;
};

const PaymentView: React.FC = (props) => {
  const [whatsappDetails, setWhatsappDetails] = useState<whatsappDTO[]>();
  const [telegramDetails, setTelegramDetails] = useState<telegramDTO[]>();
  const [customerSupport, setCustomerSupport] =
    useState<customerSupportDTO[]>();
  const [instagramLink, setInstagramLink] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [skypeLink, setSkypeLink] = useState<string>();
  const [transactionPassword, setTransactionPassword] = useState<string>();

  const getContactDetails = async () => {
    let hostname: string[] = window.location.hostname.split('.');
    let DOMAIN_URL =
      hostname[hostname?.length - 2] + '.' + hostname[hostname.length - 1];

    try {
      const response: AuthResponse = await SVLS_API.get(
        `/account/v2/books/${DOMAIN_URL}/contact-list`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        let customerSupportArr = [],
          whatsappArr = [],
          telegramArr = [];
        response.data.map((i) => {
          if (i.contactType === 'SKYPE_LINK') setSkypeLink(i.details);
          if (i.contactType === 'EMAIL_LINK') setEmail(i.details);
          if (i.contactType === 'INSTAGRAM_LINK') setInstagramLink(i.details);

          if (i.contactType === 'CUSTOMER_SUPPORT_LINK' && i.details !== '') {
            if (
              customerSupport?.length > 0 &&
              customerSupport[0].customerSupportNumber
            ) {
              customerSupportArr = [...customerSupport];
            }
            customerSupportArr.push({ customerSupportNumber: i.details });
            setCustomerSupport(customerSupportArr);
          }
          if (i.contactType === 'WHATSAPP_NUMBER' && i.details !== '') {
            if (whatsappArr[0]?.whatsappNumber == '') whatsappArr.pop();
            whatsappArr.push({
              whatsappNumber: i.details,
            });
            setWhatsappDetails([...whatsappArr]);
          }
          if (i.contactType === 'TELEGRAM_NUMBER' && i.details !== '') {
            if (telegramArr[0]?.telegramNumber == '') telegramArr.pop();
            telegramArr.push({
              telegramNumber: i.details,
            });
            setTelegramDetails([...telegramArr]);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getContactDetails();
  }, []);

  return (
    <>
      {UpdateContactWhiteLabels[BRAND_NAME.toLowerCase()] !== false ? (
        <div className="payment-footer">
          <div className="w-70">
            <div className="payment-ctn">
              {customerSupport ? (
                <div className="payment-card">
                  <a href={`mailto:${email}`} target="_blank" className="grid">
                    <IonIcon color="primary" src={mail} className="icon-ctn" />
                  </a>
                  {customerSupport?.length > 0 ? (
                    <>
                      {customerSupport[0]?.customerSupportNumber !== '' ? (
                        <>
                          <a
                            href={`https://t.me/+${customerSupport[0]?.customerSupportNumber}`}
                            target="_blank"
                            className="grid"
                          >
                            Customer support 1 |
                          </a>
                        </>
                      ) : null}
                      {customerSupport?.length > 1 ? (
                        <>
                          {customerSupport[1]?.customerSupportNumber ? (
                            <>
                              <a
                                href={`https://wa.me/${customerSupport[1]?.customerSupportNumber}`}
                                target="_blank"
                                className="grid"
                              >
                                support 2
                              </a>
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              ) : null}

              {telegramDetails ? (
                <div className="payment-card">
                  <span className="grid">
                    <IonIcon
                      color="primary"
                      src={telegram}
                      className="icon-ctn"
                    />
                  </span>
                  {telegramDetails?.length > 0
                    ? telegramDetails?.map((telegram, idx) => (
                        <a
                          href={`${telegram?.telegramNumber}`}
                          target="_blank"
                          className="grid"
                        >
                          Telegram {idx + 1} {idx == 0 ? '|' : null}
                        </a>
                      ))
                    : null}
                </div>
              ) : null}

              {whatsappDetails ? (
                <div className="payment-card">
                  <span className="grid">
                    <img
                      color="primary "
                      src={whatsopp}
                      className="icon-ctn whatsup-col"
                    />
                  </span>
                  {whatsappDetails?.length > 0
                    ? whatsappDetails?.map((whatsapp, idx) => (
                        <>
                          {whatsapp?.whatsappNumber ? (
                            <a
                              href={`https://api.whatsapp.com/send?phone=${whatsapp?.whatsappNumber}`}
                              target="_blank"
                              className="grid"
                            >
                              Whatsapp {idx + 1} {idx == 0 ? '|' : null}
                            </a>
                          ) : null}
                        </>
                      ))
                    : null}
                </div>
              ) : null}
            </div>

            <div className="payment-ctn1">
              {instagramLink ? (
                <div className="payment-card">
                  <a
                    href={`https://www.instagram.com/${instagramLink}/`}
                    target="_blank"
                    className="grid"
                  >
                    <IonIcon
                      color="primary"
                      src={instagram}
                      className="icon-ctn"
                    />
                  </a>
                  <a
                    href={`https://www.instagram.com/${instagramLink}/`}
                    target="_blank"
                    className="grid"
                  >
                    {instagramLink ? instagramLink : '-'}
                  </a>
                </div>
              ) : null}

              {email ? (
                <div className="payment-card">
                  <a href={`mailto:${email}`} target="_blank" className="grid">
                    <IonIcon color="primary" src={mail} className="icon-ctn" />
                  </a>
                  <a href={`mailto:${email}`} target="_blank" className="grid">
                    {email ? email : '-'}
                  </a>
                </div>
              ) : null}
            </div>

            {skypeLink ? (
              <div className="payment-ctn2">
                <div className="payment-card">
                  <a
                    href={`skype:${skypeLink}?chat`}
                    target="_blank"
                    className="grid"
                  >
                    <IonIcon color="primary" src={skype} className="icon-ctn" />
                  </a>
                  <a
                    href={`skype:${skypeLink}?chat`}
                    target="_blank"
                    className="grid"
                  >
                    {skypeLink ? skypeLink : '- '}
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PaymentView;
