import React, { useState, useEffect } from 'react';
import { IonCol, IonRow } from '@ionic/react';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import API from '../../api/index';
import { Currency } from '../../models/Currency';
import { RootState } from '../../models/RootState';
import { PLStatement } from '../../models/PLStatement';
import { AuthResponse } from '../../models/api/AuthResponse';
import Spinner from '../../components/Spinner/Spinner';
import Alert from '../../components/Alert/Alert';
import { getCurrencyTypeFromToken } from '../../store';
import ProfitLossStatement from '../../components/ProfitLossStatement/ProfitLossStatement';
import { connect } from 'react-redux';
import { CONFIG_PERMISSIONS } from '../../constants/ConfigPermission';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import AccStmtImg from '../../assets/images/icons/dollar.svg';
import './UserPLStatement.scss';
import Copyright from '../../components/Copyright/Copyright';

type StoreProps = {
  allowedConfig: number;
};

const UserPLStatement: React.FC<StoreProps> = (props) => {
  const { allowedConfig } = props;

  const [errorMsg, setErrorMsg] = useState(null);
  const [progress, setProgress] = useState<Boolean>(false);
  const [plStatement, setplStatement] = useState<Map<string, PLStatement[]>>(
    new Map()
  );
  const [totalPL, setTotalPL] = useState<Map<string, number>>(new Map());

  const [myTotalProfit, setMyTotalProfit] = useState<number>(0);
  // const [gameType, setGameType] = useState<string>('all');
  const [fromDate, setfromDate] = useState<Moment>(moment().subtract(7, 'd'));
  const [toDate, setToDate] = useState<Moment>(moment());
  const checkedSports = true;
  const checkedBinary = true;
  const checkedIndCasino = true;
  const checkedCasino = true;
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];

  // useEffect(() => {
  //   fetchBettingCurrency();
  // }, [fetchBettingCurrency]);

  const fetchPLStatement = async () => {
    setProgress(true);
    setErrorMsg(null);
    try {
      // if (!bettingCurrency) return false;

      const response: AuthResponse = await API.get(
        '/sap/reports/pl-statement',
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
          params: {
            startDate: fromDate.startOf('day').toISOString(),
            endDate: toDate.endOf('day').toISOString(),
            sportsBook: checkedSports,
            casino: checkedCasino,
            binary: checkedBinary,
            indianCasino: checkedIndCasino,
          },
        }
      );
      if (response.status === 200) {
        const pl_records = response?.data;
        setplStatement(new Map());
        for (let item in pl_records) {
          let total = pl_records[item].reduce((a, b) => a + b.profit, 0);
          let totalPlMap = totalPL;
          totalPlMap.set(item, total);
          setTotalPL(totalPlMap);
          setMyTotalProfit(myTotalProfit + total);
          // let plRecords = await getRecordsByPermision(pl_records[item]);
          let plRecords = pl_records[item];
          console.log('plRecords', plRecords);
          for (const pl of plRecords) {
            pl.profit = pl.profit / cFactor;
            pl.commission = pl.commission / cFactor;
          }
          let plRecordsMap = plStatement;
          plRecordsMap.set(item, plRecords);
          setplStatement(plRecordsMap);
        }
        setProgress(false);
      } else {
        setProgress(false);
        throw new Error(response.data);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setErrorMsg(err.response.data.error);
      }
      setProgress(false);
    }
  };

  useEffect(() => {
    fetchPLStatement();
  }, [fromDate, toDate]);

  const getRecordsByPermision = (plRecords: PLStatement[]) => {
    let records: PLStatement[] = [];
    for (const item of plRecords) {
      if (
        (allowedConfig & CONFIG_PERMISSIONS.sports) !== 0 &&
        item.gameType != '3'
      ) {
        records.push(item);
      }

      if (
        (allowedConfig & CONFIG_PERMISSIONS.live_casino) !== 0 &&
        item.gameType != '3'
      ) {
        records.push(item);
      }
    }
    return records;
  };

  const fromDateChangeHandler = (d: Moment) => {
    setfromDate(d);
  };

  const toDateChangeHandler = (d: Moment) => {
    setToDate(d);
  };

  return (
    <>
    <div className="reports-ctn reports-ctn-pl">
      <div className="header-ctn">
        <IonRow className="header-ctn-row">
          <div className="img-page-title-row">
            <div className="title">Profit/Loss Statement</div>
          </div>
          <div className="title-border-line"></div>
        </IonRow>
        <div className="filters-row">
          <div className="date-section">
            <div className="date-filter mr-11">
              <div className="date-filter-label">From</div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableFuture
                  className="date-filter-control"
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                  minDate={moment().subtract(1, 'months').calendar()}
                  disableToolbar
                  variant="inline"
                  format="DD/MM/yyyy"
                  margin="normal"
                  id="from-date"
                  value={fromDate}
                  onChange={(e) => fromDateChangeHandler(e)}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className="date-filter">
              <div className="date-filter-label">To</div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableFuture
                  className="date-filter-control"
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                  minDate={moment().subtract(1, 'months').calendar()}
                  maxDate={moment()}
                  disableToolbar
                  variant="inline"
                  format="DD/MM/yyyy"
                  margin="normal"
                  id="to-date"
                  value={toDate}
                  onChange={(e) => toDateChangeHandler(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
      </div>
      <div className="pl-content-ctn light-bg">
        {errorMsg ? <Alert message={errorMsg} /> : ''}

        {progress ? (
          <Spinner />
        ) : (
          <ProfitLossStatement
            items={plStatement}
            totalPL={totalPL}
            startDate={fromDate}
            endDate={toDate}
            searchName={''}
          />
        )}
      </div>
    </div>
    <Copyright />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    tvGamesEnabled: state.common.tvGamesEnabled,
    allowedConfig: state.common.allowedConfig,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    // fetchBettingCurrency: () => dispatch(fetchBettingCurrency()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPLStatement);
