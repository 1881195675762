import SockJS from 'sockjs-client';
import { getBaseUrl } from '../api-services/environment-url/environment-url';
import moment from 'moment';
import Stomp, { Frame } from 'stompjs';
import store from '../store/store';
import { NotificationType } from '../models/NotificationTypes';
import { triggerFetchBalance } from '../store/common/commonActions';

export let pushNotClient: Stomp.Client = null;
let pnLastMsgTime = null;

// Push notifications Subscription
let wsPushNotSubscriptions = {};

// Check WS connection.
export const isPushNotificationConnected = () =>
  pushNotClient && pushNotClient.connected;

export const connectToPushNotification = () => {
  if (pushNotClient == null) {
    const baseUrl = getBaseUrl(
      process.env.REACT_APP_NODE_ENV,
      'REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS'
    );
    const socket = new SockJS(baseUrl);
    pushNotClient = Stomp.over(socket);
    pushNotClient.heartbeat.outgoing = 5000;
    pushNotClient.connect(
      {},
      function (frame) {
        console.log('PN Connected: ' + frame + ' time ' + moment.now());
      },
      function (error: Frame | string) {
        console.log('pn ws_error: ' + error + ' time ' + moment.now());
      }
    );
    pnLastMsgTime = moment.now();
  }
};

export const disConnectToPushNotificationWS = () => {
  if (pushNotClient != null) {
    for (const houseId of Object.keys(wsPushNotSubscriptions)) {
      wsPushNotSubscriptions[houseId]?.unsubscribe();
      console.log('pn unsubscribed ' + houseId);
      delete wsPushNotSubscriptions[houseId];
    }

    wsPushNotSubscriptions = {};

    if (pushNotClient.connected) {
      pushNotClient.disconnect(() =>
        console.log('PN Disconnected - time ' + moment.now())
      );
    }
    pushNotClient = null;
  }
};

export const checkPNStompClientSubscriptions = () => {
  if (!(pushNotClient && pushNotClient.connected)) {
    connectToPushNotification();
  }
  if (moment.now() - pnLastMsgTime > 300000) {
    console.log(
      'PN last message is > 300000' + (moment.now() - pnLastMsgTime) + 'ms'
    );
    disConnectToPushNotificationWS();
    // Getting balance when websocket is geeting disconnected
    store.dispatch(triggerFetchBalance(moment.now()));
    connectToPushNotification();
  }
};

export const subscribeWsForNotificationsPerAdmin = (
  houseId: string,
  parentId: string,
  accountId: string
) => {
  if (pushNotClient !== null && pushNotClient.connected) {
    if (wsPushNotSubscriptions[parentId] === undefined) {
      wsPushNotSubscriptions[parentId] = pushNotClient.subscribe(
        `/topic/notifications/${houseId}/${parentId}`,
        function (message) {
          try {
            const notification = JSON.parse(message.body);
            if (notification.data.accountId === accountId) {
              switch (notification.notification_type) {
                case NotificationType.BALANCE_CHANGED: {
                  store.dispatch(triggerFetchBalance(moment.now()));
                  break;
                }
                default:
                  console.log(
                    'Invalid notification type: ',
                    notification.notification_type
                  );
              }
            }
            pnLastMsgTime = moment.now();
          } catch (ex) {
            console.error('Failed process notification ', ex);
          }
        }
      );
    }
  } else {
    setTimeout(
      () => subscribeWsForNotificationsPerAdmin(houseId, parentId, accountId),
      1000
    );
  }
};

export const unsubscribePNWsforEvents = (id: string) => {
  if (pushNotClient !== null && pushNotClient.connected) {
    wsPushNotSubscriptions[id]?.unsubscribe();
    console.log('unsubscribed PN ' + id);
    delete wsPushNotSubscriptions[id];
  }
};
