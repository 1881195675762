import {
  IS_ADMIN_REPORTS_URL,
  IS_ADMIN_RISKMGMT_URL,
  IS_HOUSE_URL,
  TOGGLE_DARK_MODE,
  CASINO_GAME_SELECTED,
  SET_DC_GAME_URL,
  SET_LIVESTREAM_URL,
  SET_PLAY_STREAM,
  SET_ALLOWED_CONFIG,
  ENABLE_COMMISSION,
  TRIGGER_FETCH_BALANCE,
} from './commonActionTypes';
import { CommonState } from '../../models/CommonState';
import { CONFIG_PERMISSIONS } from '../../constants/ConfigPermission';

type Action = {
  type: string;
  payload: any;
};

const initialState: CommonState = {
  isAdminReportsUrl: false,
  isAdminRiskMgmtUrl: false,
  isHouseUrl: false,
  prefersDark: localStorage.getItem('userTheme')
    ? localStorage.getItem('userTheme')
    : 'dark',
  // bettingCurrency: null,
  currenciesAllowed: null,
  selectedCasinoGame: null,
  tvGamesEnabled: false,
  dcGameUrl: '',
  streamUrl: '',
  playStream: false,
  allowedConfig: CONFIG_PERMISSIONS.sports + CONFIG_PERMISSIONS.casino,
  commissionEnabled: false,
  balanceChanged: null,
};

const commonReducer = (state = initialState, action: Action): CommonState => {
  switch (action.type) {
    case IS_ADMIN_REPORTS_URL:
      return {
        ...state,
        isAdminReportsUrl: action.payload,
      };
    case IS_ADMIN_RISKMGMT_URL:
      return {
        ...state,
        isAdminRiskMgmtUrl: action.payload,
      };
    case IS_HOUSE_URL:
      return {
        ...state,
        isHouseUrl: action.payload,
      };
    case TOGGLE_DARK_MODE:
      return {
        ...state,
        prefersDark: action.payload,
      };

    case SET_PLAY_STREAM:
      return {
        ...state,
        playStream: action.payload,
      };

    case CASINO_GAME_SELECTED:
      return {
        ...state,
        selectedCasinoGame: action.payload,
      };
    case SET_DC_GAME_URL:
      return {
        ...state,
        dcGameUrl: action.payload,
      };
    case SET_LIVESTREAM_URL:
      return {
        ...state,
        streamUrl: action.payload,
      };
    case SET_ALLOWED_CONFIG:
      return {
        ...state,
        allowedConfig: action.payload,
      };
    case ENABLE_COMMISSION:
      return {
        ...state,
        commissionEnabled: action.payload,
      };
    case TRIGGER_FETCH_BALANCE:
      return {
        ...state,
        balanceChanged: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
