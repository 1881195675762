import { IndianCasinoState } from '../../models/IndianCasinoState';
import {
  UPDATE_CASINO_GAMES,
  SET_CASINO_GAME,
  UPDATE_GAME_DATA,
  UPDATE_GAME_RESULTS,
  FATCH_LIVE_CASINO_GAMES,
  SET_SEARCH_GAME,
  HANDLE_SEARCH_GAME,
} from './CasinoActionTypes';

type Action = {
  type: string;
  payload: any;
};

const initailState: IndianCasinoState = {
  casinoGames: [],
  searchGame: '',
  selectedGmae: { id: '', name: '' },
  gameData: {
    gType: '',
    mId: '',
    roundDetails: [],
    roundCardDetails: [],
  },
  gameResults: [],
  liveCasinoGames: [],
  allLiveCasinoGames: [],
};

const indianCasinoReducer = (
  state = initailState,
  action: Action
): IndianCasinoState => {
  switch (action.type) {
    case FATCH_LIVE_CASINO_GAMES:
      return {
        ...state,
        allLiveCasinoGames: action.payload,
        liveCasinoGames: action.payload,
      };

    case UPDATE_CASINO_GAMES:
      return {
        ...state,
        casinoGames: action.payload,
      };

    case SET_SEARCH_GAME: {
      const searchGame = state.searchGame
      if(searchGame && !action.payload){
        return{
          ...state,
          searchGame:action.payload,
          liveCasinoGames:state.allLiveCasinoGames
        }
      }
      return {
        ...state,
        searchGame: action.payload,
      };
    }

    case HANDLE_SEARCH_GAME: {
      let searchGame = state.searchGame,
        data;
      if (searchGame && state?.allLiveCasinoGames)
        data = state?.allLiveCasinoGames?.filter(
          (item: any) =>
            item?.gameName
              ?.toLowerCase()
              ?.includes(searchGame?.toLocaleLowerCase()) ||
            item?.subProviderName
              ?.toLowerCase()
              ?.includes(searchGame?.toLocaleLowerCase())
        );
      else data = state?.allLiveCasinoGames;
      return {
        ...state,
        liveCasinoGames: data,
      };
    }

    case SET_CASINO_GAME:
      return {
        ...state,
        selectedGmae: action.payload,
        gameData: {
          gType: '',
          mId: '',
          roundDetails: [],
          roundCardDetails: [],
        },
      };

    case UPDATE_GAME_DATA: {
      const ogData = { ...state.gameData };
      const gData = {
        gType: action.payload.gType,
        mId: action.payload.mId !== '0' ? action.payload.mId : ogData.mId,
        roundDetails: action.payload.roundDetails,
        roundCardDetails: action.payload.roundCardDetails,
      };
      return {
        ...state,
        gameData: gData,
      };
    }

    case UPDATE_GAME_RESULTS: {
      return {
        ...state,
        gameResults: action.payload,
      };
    }

    default:
      return state;
  }
};

export default indianCasinoReducer;
