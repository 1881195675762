import React, { useState, useEffect, useCallback } from 'react';
import { IonButton, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import Spinner from '../../components/Spinner/Spinner';
import { AuthResponse } from '../../models/api/AuthResponse';
import API from '../../api';
import moment, { Moment } from 'moment';
// import './GameReports.scss';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { GameReportMarketLevel } from '../../models/GameReportRecord';
import GameReportBetLevelView from './GameReportBetLevel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import { getCurrencyTypeFromToken } from '../../store';
import REPORTING_API from '../../reporting-api';
import './GameReports.scss';

type gameOption = { eventId: string; eventName: string };

type PropType = {
  selectedMatchName: string;
  selectedmatchId: string;
  startDate: Moment;
  endDate: Moment;
  setShowMatchLevelReport?: () => void;
};

const GameReportMarketLevelView: React.FC<PropType> = (props) => {
  const {
    selectedMatchName,
    selectedmatchId,
    startDate,
    endDate,
    setShowMatchLevelReport,
  } = props;
  const [reportRecords, setReportRecords] = useState<GameReportMarketLevel[]>(
    []
  );
  const [loading, setLoading] = useState<Boolean>(false);
  const [matchName, setmatchName] = useState<string>(selectedMatchName);
  const [matchId, setmatchId] = useState<string>(selectedmatchId);
  const [marketName, setMarketName] = useState<string>(selectedmatchId);
  const [setMarketID, setSetMarketID] = useState<string>();
  const [marketType, setMarketType] = useState<string>('MATCH_ODDS');
  const [sessionId, setSessionId] = useState<string>(selectedmatchId);
  // const [settlementDate, setSettlementDate] = useState<string>(selectedmatchId);
  const [pageNum, setPageNum] = useState<number>(1);
  const pageSize = 20;
  const [showBetLevelView, setShowBetLevelView] = useState<boolean>(false);
  const gameOptions: gameOption[] = [];
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];

  const [pageToken, setPageToken] = useState<string[]>(['']);
  const [nextPageToken, setNextPageToken] = useState<string>('');

  const nextPage = () => {
    setLoading(true);
    setPageNum(pageNum + 1);
    if (nextPageToken !== null && nextPageToken !== '') {
      setPageToken([...pageToken, nextPageToken]);
    }
    setLoading(false);
  };

  const prevPage = () => {
    setLoading(true);
    setPageNum(pageNum - 1);
    if (pageToken.length > 1) {
      let pagetokens = pageToken;
      pagetokens.pop();
      setPageToken([...pagetokens]);
    }
    setLoading(false);
  };

  const fetchMarketLevelReort = useCallback(async () => {
    setLoading(true);
    try {
      const response: AuthResponse = await REPORTING_API.get(
        '/reports/v2/game-report',
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
          params: {
            eventId: matchId,
            startDate: startDate.startOf('day').toISOString(),
            endDate: endDate.endOf('day').toISOString(),
            pageSize: pageSize,
            pageToken: pageToken[pageToken.length - 1],
          },
        }
      );
      const items = response.data?.gameReport;
      for (const item of items) {
        item.profit = item.profit / cFactor;
        // item.balanceBefore = item.balanceBefore / cFactor;
        // item.balanceAfter = item.balanceAfter / cFactor;
      }
      setReportRecords(response.data?.gameReport);
      setNextPageToken(response.data?.nextPageToken);
    } catch (err) {
      console.log(err);
      setReportRecords([]);
    }
    setLoading(false);
  }, [matchId, startDate, endDate, pageToken, cFactor]);

  useEffect(() => {
    setPageNum(1);
  }, [matchId]);

  useEffect(() => {
    fetchMarketLevelReort();
  }, [matchId, startDate, endDate, pageNum]);

  return (
    <div className="reports-ctn game-report">
      {!showBetLevelView ? (
        <>
          <div className="header-ctn">
            <div className="page-title">
              <div className="img-page-title-row">
                <div className="title">Game Report</div>
              </div>
            </div>

            <div className="breadcrumbs-row">
              <div className="breadcrumbs">
                <span
                  className="prev-level-link"
                  onClick={() => setShowMatchLevelReport()}
                >
                  <ArrowBackIcon />
                </span>
                <span
                  className="prev-level-link"
                  onClick={() => setShowMatchLevelReport()}
                >
                  {matchName}
                </span>
              </div>
            </div>

            {/* <div className="filters-row">
              {gameOptions.length > 0 ? (
                <div className="select-filter">
                  <div className="select-filter-label">Select match</div>
                  <IonSelect
                    className="select-filter-control"
                    value={matchId}
                    placeholder="Select Match"
                    interface="popover"
                    onIonChange={(e) => {
                      setmatchId(e.detail.value);
                      setmatchName(
                        gameOptions.find((o) => o.eventId === e.detail.value)
                          .eventName
                      );
                    }}
                  >
                    {gameOptions.map((opt, idx) => (
                      <IonSelectOption
                        key={'option-' + idx}
                        value={opt.eventId}
                      >
                        {opt.eventName}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </div>
              ) : null}

              <div className="date-section">
                <div className="date-filter">
                  <div className="date-filter-label">From</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      className="date-filter-control readonly"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={true}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="from-date"
                      value={startDate}
                      onChange={() => console.log('')}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="date-filter">
                  <div className="date-filter-label">To</div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      className="date-filter-control readonly"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={true}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="to-date"
                      value={endDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      onChange={() => console.log('')}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div> */}
          </div>

          <div className="content-ctn light-bg">
            <div className="tbl-ctn">
              {loading ? (
                <Spinner />
              ) : (
                <TableContainer component={Paper}>
                  <Table className="gr-match-level-tbl">
                    <TableHead className="apl-table-header">
                      <TableRow>
                        <TableCell className="web-view">
                          Settlement Date
                        </TableCell>
                        <TableCell>Market</TableCell>
                        <TableCell className="bet-count-col">Bets</TableCell>
                        <TableCell className="win-count-col">Won</TableCell>
                        <TableCell className="loss-count-col">Lost</TableCell>
                        <TableCell className="loss-count-col">Void</TableCell>
                        <TableCell>
                          <span className="web-view">Profit/Loss</span>
                          {/* <span className="mob-view">Balance</span> */}
                        </TableCell>
                        {/* <TableCell className="web-view">Open. Bal.</TableCell>
                        <TableCell className="web-view">Close. Bal.</TableCell> */}
                        <TableCell className="result-col">Result</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {reportRecords.map((row, idx) => (
                        <TableRow
                          key={'row-' + idx}
                          onClick={() => {
                            setMarketName(row.marketName);
                            setSetMarketID(row.marketId);
                            setSessionId(row.sessionId);
                            setMarketType(row.marketType);
                            // setSettlementDate(row.settledDate);
                            setShowBetLevelView(true);
                          }}
                          className="m-link"
                        >
                          <TableCell
                            className="web-view"
                            key={'row-' + idx + '-cell-1'}
                          >
                            {moment(row.settlementDate).format(
                              'DD/MM/YYYY, h:mm:ss A'
                            )}
                          </TableCell>
                          <TableCell key={'row-' + idx + '-cell-2'}>
                            <div className="next-level-link">
                              <div className="next-level-link text-ellipsis">
                                {row.marketName}
                              </div>
                            </div>

                            <div className="mob-view">
                              <div className="d-flex-column left-align">
                                <div className="tiny-msg-info text-ellipsis">
                                  RES: {row.outcomeResult}
                                </div>
                                <div className="tiny-msg-info">
                                  {moment(row.settlementDate).format(
                                    'DD/MM, h:mm A'
                                  )}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            className="web-view"
                            key={'row-' + idx + '-cell-3'}
                          >
                            {row.ordersCount}
                          </TableCell>
                          <TableCell
                            className="web-view"
                            key={'row-' + idx + '-cell-4'}
                          >
                            {row.winCount}
                          </TableCell>
                          <TableCell
                            className="loss-count-col"
                            key={'row-' + idx + '-cell-5'}
                          >
                            {row.lostCount}
                          </TableCell>
                          <TableCell
                            className="loss-count-col"
                            key={'row-' + idx + '-cell-5'}
                          >
                            {row.voidCount}
                          </TableCell>
                          <TableCell key={'row-' + idx + '-cell-6'}>
                            <span
                              className={
                                row.profit > 0
                                  ? 'profit web-view'
                                  : 'loss web-view'
                              }
                            >
                              {row.profit > 0 ? '+' : null}
                              {row.profit.toFixed(2)}
                            </span>

                            <div className="mob-view">
                              <div className="d-flex-column">
                                <div
                                  className={
                                    row.profit > 0
                                      ? 'profit mob-fs-16'
                                      : 'loss mob-fs-16'
                                  }
                                >
                                  {row.profit > 0 ? '+' : null}
                                  {row.profit.toFixed(2)}
                                </div>

                                {/* <div>Bal: {row.balanceAfter.toFixed(2)}</div> */}
                              </div>
                            </div>
                          </TableCell>
                          {/* <TableCell
                            className="web-view"
                            key={'row-' + idx + '-cell-7'}
                          >
                            {row.balanceBefore.toFixed(2)}
                          </TableCell> */}
                          {/* <TableCell
                            className="web-view"
                            key={'row-' + idx + '-cell-8'}
                          >
                            {row.balanceAfter.toFixed(2)}
                          </TableCell> */}
                          <TableCell
                            className="result-col web-view"
                            key={'row-' + idx + '-cell-9'}
                          >
                            {row.outcomeResult}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <IonRow>
                {pageToken.length === 1 || loading ? null : (
                  <IonButton
                    className="myb-btn-prev"
                    onClick={(e) => {
                      prevPage();
                    }}
                  >
                    Prev({pageNum - 1})
                  </IonButton>
                )}
                {(!(nextPageToken === '' || nextPageToken === null) ||
                  reportRecords.length === pageSize) &&
                !loading ? (
                  <IonButton
                    className="myb-btn-next"
                    onClick={(e) => nextPage()}
                  >
                    Next({pageNum + 1})
                  </IonButton>
                ) : null}
              </IonRow>
            </div>
          </div>
        </>
      ) : (
        <GameReportBetLevelView
          selectedMatchName={matchName}
          selectedmatchId={matchId}
          selectedMarketName={marketName}
          selectedMarketId={setMarketID}
          selectedMarketType={marketType}
          selectedSessionId={sessionId}
          startDate={startDate}
          endDate={endDate}
          // marketSettlementDate={settlementDate}
          setShowMatchLevelReport={() => setShowMatchLevelReport()}
          setShowMarketLevelReport={() => setShowBetLevelView(false)}
        />
      )}
    </div>
  );
};

export default GameReportMarketLevelView;
