import API from '../../api';
import { CasinoGameDTO } from '../../models/IndianCasinoState';
import SVLS_API from '../../svls-api';
import {
  SET_CASINO_GAME,
  UPDATE_CASINO_GAMES,
  UPDATE_GAME_DATA,
  UPDATE_GAME_RESULTS,
  FATCH_LIVE_CASINO_GAMES,
  SET_SEARCH_GAME,
  HANDLE_SEARCH_GAME,
} from './CasinoActionTypes';

export const FatchCasinoGamesSuccess = (value: string) => {
  return {
    type: FATCH_LIVE_CASINO_GAMES,
    payload: value,
  };
};

export const FatchLiveCasinoGames = (gametype: string) => {
  return async (dispatch: Function) => {
    try {
      let response = null;
      if (sessionStorage.getItem('jwt_token')) {
        response = await API.post('/gap/enabled-games', null, {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        });
      } else {
        response = await API.post('/gap/enabled-games', null);
      }
      if (response.data) {
        dispatch(FatchCasinoGamesSuccess(response.data));
      } else {
        console.log('fatch casino error');
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateCasinoGames = (casinoGames: CasinoGameDTO[]) => {
  return {
    type: UPDATE_CASINO_GAMES,
    payload: casinoGames,
  };
};

export const setCasinoGame = (game: CasinoGameDTO) => {
  return {
    type: SET_CASINO_GAME,
    payload: game,
  };
};

export const setSearchGame = (payload) => {
  return {
    type: SET_SEARCH_GAME,
    payload,
  };
};

export const handleSearchGame = () => {
  return {
    type: HANDLE_SEARCH_GAME,
  };
};

export const fetchGameData = (gameCode: string) => {
  return async (dispatch: Function) => {
    try {
      if (gameCode !== '') {
        const result = await API.get('/indian-casino/round', {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
          params: {
            indianCasinoGameType: gameCode,
          },
        });
        if (result.data && result.data.mId) {
          dispatch(updateGameData(result.data));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateGameData = (gameData) => {
  return {
    type: UPDATE_GAME_DATA,
    payload: gameData,
  };
};

export const updateGameResults = (gameResults) => {
  return {
    type: UPDATE_GAME_RESULTS,
    payload: gameResults,
  };
};
