import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IonImg } from '@ionic/react';
import {
  AppBar,
  Backdrop,
  Button,
  Divider,
  Menu,
  MenuItem,
  Toolbar,
} from '@material-ui/core';
import Modal from '../../components/Modal/Modal';

import './Header.scss';
import { RootState } from '../../models/RootState';
import { ReactComponent as Logo } from '../../assets/images/brand/title.svg';
import MobileSideNav from './MobileSideNav';
import {
  toggleDarkMode,
  setSearchGame,
  handleSearchGame,
  fetchBalance,
  logout,
  getCurrencyTypeFromToken,
  setOpenWithdrawModal,
  setOpenDepositModal,
} from '../../store';

import User from '../../assets/images/home/homeview/user.png';
import Notifications from '../../assets/images/home/homeview/Notifications.svg';
import Balance from '../../assets/images/home/homeview/Balance.svg';
import { ReactComponent as DownArrow } from '../../assets/images/home/homeview/downArrow.svg';
import { ReactComponent as Terms } from '../../assets/images/home/homeview/terms.svg';
import { ReactComponent as GameRule } from '../../assets/images/home/homeview/gameRule.svg';
import { ReactComponent as PlayHistory } from '../../assets/images/home/homeview/history.svg';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import LoginModal from '../Modal/LoginModal';
import LoginPage from '../LoginForm/LoginPage';
import ChangePwdForm from '../../components/ChangePassword/ChangePassword';
import NotificationPage from '../Home/Notification';
import {
  ShowSelfRagistration,
  ShowWhatsopIcon,
} from '../../constants/WhitelabelConfig';
import { BRAND_DOMAIN, BRAND_NAME } from '../../constants/Branding';
import SVLS_API from '../../svls-api';
import WhatsupImg from '../../assets/images/header/whatsup-flot.svg';
import AGPAY_API from '../../api-services/agpay-api';
import { demoUsers } from '../../util/stringUtil';

type StoreProps = {
  balance: number;
  fetchBalance: Function;
  loggedIn: boolean;
  setSearchGame: Function;
  searchGame: string;
  handleSearchGame: () => void;
  toggleDarkMode: (val: string) => void;
  prefersDark: string;
  openDepositModal: boolean;
  openWithdrawModal: boolean;
  setOpenDepositModal: Function;
  setOpenWithdrawModal: Function;
  logout: () => void;
};

const MobileHeader: React.FC<StoreProps> = (props) => {
  const history = useHistory();
  const {
    fetchBalance,
    loggedIn,
    setSearchGame,
    searchGame,
    handleSearchGame,
    balance,
    logout,
    prefersDark,
    toggleDarkMode,
    openDepositModal,
    openWithdrawModal,
    setOpenDepositModal,
    setOpenWithdrawModal,
  } = props;

  const [tabValue, setTabValue] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [changePwdMOdal, setChangePwdMOdal] = useState<boolean>(false);
  const [amdinWhatsAppNumber, setAmdinWhatsAppNumber] = useState<string>();
  const [showDeposit, setShowDeposit] = useState<boolean>(true);
  const [showWithdrawal, setShowWithdrawal] = useState<boolean>(true);

  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loginHandler = () => {
    setModalOpen(true);
    setShowPopover({ open: false, event: undefined });
  };

  const modalCloseHandler = () => {
    setModalOpen(false);
  };

  // const getPaymentProviders = async () => {
  //   const accountId = sessionStorage.getItem('aid');
  //   try {
  //     const response = await AGPAY_API.get(
  //       `/agpay/v2/payment-settings/accounts/${accountId}`,
  //       {
  //         headers: {
  //           Authorization: sessionStorage.getItem('jwt_token'),
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       if (response?.data?.withdraw_provider_list && response?.data?.withdraw_provider_list?.length > 0) {
  //         setShowWithdrawal(true);
  //       } else {
  //         setShowWithdrawal(false);
  //       }

  //       if (response?.data?.deposit_provider_list && response?.data?.deposit_provider_list?.length > 0) {
  //         setShowDeposit(true);
  //       } else {
  //         setShowDeposit(false);
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const setThemeHandler = () => {
    if (prefersDark === 'light') {
      localStorage.setItem('userTheme', 'dark');
      toggleDarkMode('dark');
    } else {
      localStorage.setItem('userTheme', 'light');
      toggleDarkMode('light');
    }
  };

  useEffect(() => {
    getAdminWhatsAppNumber();
  }, []);

  const getAdminWhatsAppNumber = async () => {
    try {
      const response = await SVLS_API.get(
        `/account/v2/books/${BRAND_DOMAIN}`,
        {}
      );
      if (response.status === 200) {
        setAmdinWhatsAppNumber(response?.data?.contact?.whatsappNumber);
      }
    } catch (err) {}
  };

  return (
    <>
      <AppBar position="static" className={'main-header-ctn'}>
        <Toolbar className="toolbar" variant="dense">
          <div className="header-actions">
            {loggedIn ? (
              <>
                <div className="header-left-actions">
                  <Logo height={24} width={70} />
                </div>
                <div className="header-right-actions">
                  <div className="theme-toggle-mobile">
                    <div className="mob-theme-text">Theme</div>
                    <Button
                      onClick={() => setThemeHandler()}
                      className="mob-theme-toggle"
                    ></Button>
                  </div>
                  {/* <Button className='notifications-img'><img height={19.5} width={20} src={Notifications} /></Button> */}
                  {!ShowSelfRagistration[BRAND_NAME.toLocaleLowerCase()] ? (
                    <Button className="signup-btn" size="small">
                      <img src={Balance} style={{ paddingRight: '7.5px' }} />{' '}
                      {balance !== null ? balance.toFixed(2) : '-.--'}{' '}
                    </Button>
                  ) : null}

                  {ShowSelfRagistration[BRAND_NAME.toLocaleLowerCase()] ? (
                    <>
                      {showDeposit ? (
                        <Button
                          className="signup-btn mob-view"
                          onClick={() => history.push('/transaction/deposit')}
                        >
                          D
                        </Button>
                      ) : null}
                      {showWithdrawal ? (
                        <Button
                          className="signup-btn mob-view"
                          onClick={() => history.push('/transaction/withdraw')}
                        >
                          W
                        </Button>
                      ) : null}
                    </>
                  ) : null}

                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    className="login-btn p-0"
                    disableElevation
                    onClick={handleClick}
                    // endIcon={<KeyboardArrowDownIcon />}
                  >
                    <img
                      height={28}
                      src={User}
                      style={{ borderRadius: '4px' }}
                    />
                    <span className="username-text">
                      {sessionStorage.getItem('username')}
                    </span>
                    <DownArrow className="downArrow" />
                  </Button>

                  <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {/* <MenuItem className='menu-item-text' onClick={handleClose} disableRipple>
                      <PlayHistory />
                      Play History
                    </MenuItem>
                    <div className="menu-border-bottom"></div> */}

                    <MenuItem
                      className="menu-item-text"
                      onClick={() => {
                        history.push('/responsible-gambling');
                        handleClose();
                      }}
                      disableRipple
                    >
                      <GameRule />
                      Game Rules
                    </MenuItem>
                    <div className="menu-border-bottom"></div>

                    <MenuItem
                      className="menu-item-text"
                      onClick={() => {
                        history.push('/account/my_bets');
                        handleClose();
                      }}
                      disableRipple
                    >
                      <Terms />
                      My Bets
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    <MenuItem
                      className="menu-item-text"
                      onClick={() => {
                        history.push('/account/my_wallet');
                        handleClose();
                      }}
                      disableRipple
                    >
                      <Terms />
                      My Wallet
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    <MenuItem
                      className="menu-item-text"
                      onClick={() => {
                        history.push('/account/account_statement');
                        handleClose();
                      }}
                      disableRipple
                    >
                      <Terms />
                      Acc Statement
                    </MenuItem>

                    {ShowSelfRagistration[BRAND_NAME.toLocaleLowerCase()] ? (
                      <>
                        <div className="menu-border-bottom"></div>
                        <MenuItem
                          className="menu-item-text"
                          onClick={() => {
                            history.push('/my_transactions');
                            handleClose();
                          }}
                          disableRipple
                        >
                          <Terms />
                          My Transactions
                        </MenuItem>
                        <div className="menu-border-bottom mob-view"></div>
                        <MenuItem
                          className="menu-item-text mob-view"
                          onClick={() => {
                            history.push('/transaction/deposit');
                            handleClose();
                          }}
                          disableRipple
                        >
                          <Terms />
                          Deposit
                        </MenuItem>
                        <div className="menu-border-bottom mob-view"></div>
                        <MenuItem
                          className="menu-item-text mob-view"
                          onClick={() => {
                            history.push('/transaction/withdraw');
                            handleClose();
                          }}
                          disableRipple
                        >
                          <Terms />
                          Withdraw
                        </MenuItem>
                      </>
                    ) : null}

                    <div className="menu-border-bottom"></div>
                    <MenuItem
                      className="menu-item-text"
                      onClick={() => {
                        history.push('/account/pl_statement');
                        handleClose();
                      }}
                      disableRipple
                    >
                      <Terms />
                      PL Statement
                    </MenuItem>
                    {sessionStorage.getItem('username') &&
                    demoUsers.includes(
                      sessionStorage.getItem('username').toLowerCase()
                    ) ? null : (
                      <>
                        <div className="menu-border-bottom"></div>
                        <MenuItem
                          className="menu-item-text"
                          onClick={() => setChangePwdMOdal(true)}
                          disableRipple
                        >
                          <Terms />
                          Change Password
                        </MenuItem>
                      </>
                    )}
                    <div className="menu-border-bottom"></div>
                    <MenuItem
                      className="menu-item-text"
                      onClick={() => {
                        history.push('/terms-conditions');
                        handleClose();
                      }}
                      disableRipple
                    >
                      <Terms />
                      T&C
                    </MenuItem>
                    <div className="menu-border-bottom"></div>
                    <Button
                      onClick={() => {
                        logout();
                      }}
                      className="logout-btn"
                    >
                      Logout
                    </Button>
                  </Menu>
                </div>
              </>
            ) : (
              <>
                <div className="header-left-actions">
                  <Logo height={24} width={70} />
                </div>
                <div className="header-right-actions">
                  <div className="theme-toggle-mobile">
                    <div className="mob-theme-text">Theme</div>
                    <Button
                      onClick={() => setThemeHandler()}
                      className="mob-theme-toggle"
                    ></Button>
                  </div>
                  <Button className="actions-btn whatsapp-btn">
                    <div className="btn-content">
                      <a href={`https://wa.link/skycasino`} target="_blank">
                        <img src={WhatsupImg} className="social-icon1" />
                      </a>
                    </div>
                  </Button>
                  <Button
                    className="login-btn"
                    onClick={() => {
                      loginHandler();
                      setTabValue(0);
                    }}
                    size="small"
                  >
                    Login
                  </Button>
                  <a
                    className="login-link"
                    href="https://wa.link/skycasino"
                    target="_blank"
                  >
                    Register
                  </a>
                  {/* <Button
                    className="signup-btn"
                    onClick={() => {
                      loginHandler();
                      setTabValue(1);
                    }}
                    size="small"
                  >
                    Sign Up
                  </Button> */}
                </div>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>

      <Backdrop className="backdrop-ctn" open={modalOpen}>
        <LoginModal
          open={modalOpen}
          closeHandler={modalCloseHandler}
          noTitle={true}
          size="md"
          customClass={'login-modal-ctn'}
        >
          <LoginPage
            modalCloseHandler={modalCloseHandler}
            tabActive={tabValue}
          ></LoginPage>
        </LoginModal>
      </Backdrop>

      <Backdrop className="backdrop-ctn" open={changePwdMOdal}>
        <Modal
          open={changePwdMOdal}
          closeHandler={() => setChangePwdMOdal(false)}
          title="Change Password"
          size="xs"
        >
          <ChangePwdForm
            closeHandler={() => setChangePwdMOdal(false)}
            backHandler={() => {}}
          />
        </Modal>
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    allowedConfig: state.common.allowedConfig,
    loggedIn: state.auth.loggedIn,
    balance: state.auth.balanceSummary.balance,
    searchGame: state.indianCasino.searchGame,
    prefersDark: state.common.prefersDark,
    openDepositModal: state.auth.openDepositModal,
    openWithdrawModal: state.auth.openWithdrawModal,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    logout: () => dispatch(logout()),
    setSearchGame: (data: string) => dispatch(setSearchGame(data)),
    handleSearchGame: () => dispatch(handleSearchGame()),
    toggleDarkMode: (val: string) => dispatch(toggleDarkMode(val)),
    setOpenDepositModal: (val) => dispatch(setOpenDepositModal(val)),
    setOpenWithdrawModal: (val) => dispatch(setOpenWithdrawModal(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
function alpha(white: any, arg1: number) {
  throw new Error('Function not implemented.');
}
