import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import crossIcon from '../../assets/images/common/cross.svg';
import './Modal.scss';

type ModalProps = {
  closeHandler: Function;
  open: boolean;
  title?: string;
  size: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
  customClass?: string;
  noTitle?: boolean;
};

const LoginModal: React.FC<ModalProps> = (props) => {
  const { children, closeHandler, customClass, open, title, size, noTitle } =
    props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(size));

  const handleClose = () => {
    closeHandler();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth={size}
      className="dialog-login"
    >
      <div className="dialog-div-body">
        <div className="go-corner" onClick={() => handleClose()}>
        <ClearIcon />
        </div>
        {noTitle ? null : (
          <DialogTitle
            className={
              customClass ? customClass + ' modal-title' : ' modal-title'
            }
            id="responsive-dialog-title"
          >
            {title}
          </DialogTitle>
        )}
        {/* <IconButton aria-label="close" className="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton> */}
        <DialogContent className="modal-content-ctn">{children}</DialogContent>
      </div>
    </Dialog>
  );
};

export default LoginModal;
