import { BRAND_DOMAIN } from '../../constants/Branding';

export const DevUrlsMap = {
  REACT_APP_REST_API_URL: `https://dev-user-api.sky11.com/api/v1`,
  REACT_APP_REST_API_V2_URL: `https://dev-user-api.sky11.com`,
  REACT_APP_REST_REPORTING_API_URL: `https://dev-reporting.sky11.com`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://dev-reporting.sky11.com/push-notifications`,
  REACT_APP_REST_AGPAY_URL: `https://dev-agpay.sky11.com`,
};

export const StageUrlsMap = {
  REACT_APP_REST_API_URL: `https://stage-user-api.sky11.com/api/v1`,
  REACT_APP_REST_API_V2_URL: `https://stage-user-api.sky11.com`,
  REACT_APP_REST_REPORTING_API_URL: `https://stage-reporting.sky11.com`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://stage-reporting.sky11.com/push-notifications`,
  REACT_APP_REST_AGPAY_URL: `https://stage-agpay.sky11.com`,
};

export const ProdUrls = {
  REACT_APP_REST_API_URL: `https://api.sky11.live/api/v1`,
  REACT_APP_REST_API_V2_URL: `https://api.sky11.live`,
  REACT_APP_REST_REPORTING_API_URL: `https://reporting.sky11.live`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://reporting.sky11.live/push-notifications`,
  REACT_APP_REST_AGPAY_URL: `https://agpay.sky11.live`,
};

export const EnvUrlsMap = {
  development: DevUrlsMap,
  stage: StageUrlsMap,
  production: ProdUrls,
};

export function getBaseUrl(env: string, url: string) {
  return EnvUrlsMap[env][url];
}
