import React from 'react';
import { IonRow, IonCol, IonImg } from '@ionic/react';
import './Reports.scss';
import SideNav from './SideNav';
import { Route } from 'react-router-dom';
import { Redirect, Switch } from 'react-router';
import AccountStatement from '../AccountStatement/AccountStatement';
import transactionIcon from '../../assets/images/common/transaction.svg';
import depositeIcon from '../../assets/images/common/deposite.svg';
import withdrawIcon from '../../assets/images/common/withdraw.svg';
import MobileRightSideNav from './MobileRightSideNav';
import { connect } from 'react-redux';
import { RootState } from '../../models/RootState';

type StoreProps = {
  balance: number;
  exposure: number;
};

const Reports: React.FC<StoreProps> = (props) => {
  const { balance, exposure } = props;
  return (
    <div className="user-report-ctn">
      <IonRow>
        <IonCol sizeMd="3" sizeLg="3" className="web-view">
          <SideNav />
        </IonCol>
        <IonCol sizeXs="12" sizeSm="12" sizeMd="9" sizeLg="9">
          <MobileRightSideNav />
          <IonRow>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4">
              <div className="card">
                <div className="icon-ctn">
                  <IonImg src={transactionIcon} className="icon-img" />
                </div>
                <div className="balance-ctn">
                  <div className="label">Total Balance</div>
                  <div className="value">{balance && balance}</div>
                </div>
              </div>
            </IonCol>

            <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4">
              <div className="card">
                <div className="icon-ctn">
                  <IonImg src={depositeIcon} className="icon-img" />
                </div>
                <div className="balance-ctn">
                  <div className="label">Total Exposure</div>
                  <div className="value">{exposure && exposure}</div>
                </div>
              </div>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4">
              <div className="card">
                <div className="icon-ctn">
                  <IonImg src={withdrawIcon} className="icon-img" />
                </div>
                <div className="balance-ctn">
                  <div className="label">Total Withdraw</div>
                  <div className="value">0</div>
                </div>
              </div>
            </IonCol>
          </IonRow>
          <Switch>
            <Route exact path={'/reports'}>
              <Redirect to={`/reports/deposite`} />
            </Route>
            <Route
              exact
              path={`/reports/deposite`}
              render={() => <AccountStatement />}
            ></Route>
            <Route
              exact
              path={`/reports/transaction`}
              render={() => <AccountStatement />}
            ></Route>
            <Route
              exact
              path={`/reports/withdraw`}
              render={() => <AccountStatement />}
            ></Route>
          </Switch>
        </IonCol>
      </IonRow>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    balance: state.auth.balanceSummary.balance,
    exposure: state.auth.balanceSummary.exposure,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
