import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { AxiosResponse } from 'axios';

import { RootState } from '../../../models/RootState';
import './InCasino.scss';
import API from '../../../api';
import { InGameUrl } from '../../../models/dc/DcGameUrl';
import { IC_PARTNER_BY_CURRENCY_TYPE } from '../../../constants/CurrencyTypeFactor';
import { getCurrencyTypeFromToken } from '../../../store';

type StoreProps = {
  gameUrl: string;
  loggedIn: boolean;
};

type RouteParams = {
  gameCode: string;
};

const CasinoIframeNew: React.FC<StoreProps> = (props) => {
  const [gameSrc, setGameSrc] = useState<string>('');

  const { loggedIn } = props;

  const history = useHistory();

  const { gameCode } = useParams<RouteParams>();
  const icPartnerId = IC_PARTNER_BY_CURRENCY_TYPE[getCurrencyTypeFromToken()];
  
  const getGameUrl = async () => {
    if (loggedIn) {
      const claims = sessionStorage.getItem('jwt_token').split('.')[1];
      const userStatus = JSON.parse(window.atob(claims)).status;
      if (userStatus === 0 || userStatus === 3) {
        return history.push(`/home`);
      }

      const tokenResponse = await API.get('/dc/game/token', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });

      const dcToken = tokenResponse.data.message;

      const userId = JSON.parse(window.atob(claims)).userId;
      const response: AxiosResponse<InGameUrl> = await API.post(
        '/dc/v1.1/indian-game/url',
        {
          user: userId.toString(),
          token: dcToken,
          partner_id: icPartnerId,
          game_code: gameCode,
        }
      );

      setGameSrc(response.data.url);
    } else {
      history.push(`/`);
    }
  };

  useEffect(() => {
    getGameUrl();
  }, []);

  return (
    <div className="inc-iframe-ctn">
      <iframe
        src={gameSrc}
        title="Indian Casino"
        sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
      ></iframe>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    gameUrl: state.common.dcGameUrl,
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(CasinoIframeNew);
