const USER_STATUS = [
  'suspended',
  'active',
  'password_reset_required',
  'bet_locked',
];

export default {
  USER_STATUS,
};
