import React, { useEffect, useState } from 'react';
import { IonRow, IonCol, IonImg } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import '../Casino/CasinoNew/CasinoNew.scss';
import { RootState } from '../../models/RootState';
import { GapGame } from '../../models/dc/DcGame';
import { FatchLiveCasinoGames } from '../../store';
import { Backdrop } from '@material-ui/core';
import Footer from '../../components/Footer/Footer';
import inplay from '../../assets/images/common/Banner/Polygon.svg';
import LoginModal from '../../components/Modal/LoginModal';
import LoginForm from '../../components/LoginForm/LoginForm';
import { CASINO_PROVIDERS } from '../../constants/HomeView';
import PageBanner from '../../components/Page-banner/PageBanner';
type StoreProps = {
  loggedIn: boolean;
  loggedInUserStatus: any;
  FatchLiveCasinoGames: (type: string) => void;
  liveCasinoGames: GapGame[];
};

const TVGamesView: React.FC<StoreProps> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const {
    loggedIn,
    loggedInUserStatus,
    FatchLiveCasinoGames,
    liveCasinoGames,
  } = props;

  const history = useHistory();
  const [infoDialog, setInfoDialog] = useState<boolean>(false);
  const setDialogShow = (show: boolean) => {
    setShowDialog(show);
  };
  const pathName = history.location.pathname;
  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    provider: string,
    subProvider: string
  ) => {
    if (loggedInUserStatus === 0 || loggedInUserStatus === 3) {
      history.push(`/home`);
    }
    if (provider === 'Indian Casino') {
      history.push(`/casino/indian/${gameCode}`);
    } else {
      history.push(
        `/gap_casino/game/${gameName.toLowerCase().replace(/\s+/g, '-')}-${btoa(
          gameId.toString()
        )}-${btoa(gameCode)}-${btoa(provider)}-${btoa(subProvider)}`
      );
    }
  };

  const handleGameClick = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    subProvider: string,
    provider?: string
  ) => {
    if (loggedIn) {
      getGameUrl(gameId, gameName, gameCode, provider, subProvider);
    } else {
      setDialogShow(true);
    }
  };

  useEffect(() => {
    FatchLiveCasinoGames('virtual');
  }, []);

  let tvGamesList = liveCasinoGames.filter(
    (g) => g.subProviderName === 'BetGames.TV'
  );
  return (
    <div className="dc-page-bg">
      <PageBanner />
      <div className="dc-ctn">
        <IonRow class="casino-filters-row">
          <div className="title">Fish Shooting</div>
        </IonRow>
        <div className="title-border-line"></div>
        <div>
          <IonRow className="dc-games-ctn">
            {/* {CASINO_PROVIDERS.map((g, i) => (
              <>
                <IonCol sizeXs="6" sizeSm="6" sizeMd="2" sizeLg="2" key={g.url}>
                  <div className="container1 border-1">
                    <div className='provider-ctn'>
                      <img
                        src={g.image ? g.image : ''}
                        alt={g.image}
                        className="dc-img"
                      />
                    </div>
                    <div className="game-name game-name1">
                      <span className="name">{g.text}</span>
                    </div>
                    <div
                      className="overlay1"
                      onClick={() => history.push(g.url)}
                    >
                      <div className="text1">
                        <div className="custom-btn">
                          <img src={inplay} alt="inplay" />
                          <div> PLAY NOW </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </IonCol>
              </>
            ))} */}
            {liveCasinoGames?.length > 0 ?
            liveCasinoGames.filter(j => j.category == "Fish Shooting").map((g, i) => {
              return (
                <>
                  {g?.urlThumb ? (
                    <IonCol
                      sizeXs="4"
                      sizeSm="4"
                      sizeMd="2"
                      sizeLg="2"
                      key={g.gameId}
                      className="dc-ion-col"
                      style={{ display: "flex" }}
                    >
                      <div className="img-container pointer w-100"
                        onClick={() =>
                          handleGameClick(
                            g.gameId,
                            g.gameName,
                            g.gameCode,
                            g.subProviderName,
                            g.providerName
                          )
                        }>
                        <img
                          src={g.urlThumb ? g.urlThumb : ''}
                          alt={g.gameName}
                          className="dc-img"
                        />
                        <div className="game-name">
                          <span className="name">{g.gameName.length > 22 ? g.gameName.slice(0, 22) + '..' : g.gameName}</span>
                          {/* <span className="p-name">{g.subProviderName}</span> */}
                          <div className='tooltip'>
                            <span className="tooltiptext">{g.subProviderName}</span>
                            {/* <img title={g.subProviderName} className='info-img' src={Info} /> */}
                          </div>
                        </div>
                        {/* <div className="overlay1">
                        <div className="text1">
                          <div
                            className="custom-btn"
                            // onClick={() =>
                            //   handleGameClick(
                            //     g.gameId,
                            //     g.gameName,
                            //     g.gameCode,
                            //     g.subProviderName,
                            //     g.providerName
                            //   )
                            // }
                          >
                            <img src={inplay} alt="inplay" />
                            <div> PLAY NOW </div>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </IonCol>
                  ) : null}
                </>
              );
            })
            : <div>No fish shooting games available</div>
          }
          </IonRow>
        </div>
      </div>

      {/* <Footer /> */}

      <Backdrop className="backdrop-ctn" open={showDialog}>
        <LoginModal
          open={showDialog}
          closeHandler={() => setDialogShow(false)}
          noTitle={true}
          size="xs"
          customClass={'login-modal-ctn'}
        >
          <LoginForm modalCloseHandler={() => setDialogShow(false)}></LoginForm>
        </LoginModal>
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  let status = 0;
  if (state.auth.loggedIn) {
    status = JSON.parse(
      window.atob(sessionStorage.getItem('jwt_token').split('.')[1])
    ).status;
  }
  return {
    loggedIn: state.auth.loggedIn,
    loggedInUserStatus: status,
    liveCasinoGames: state.indianCasino.liveCasinoGames,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    FatchLiveCasinoGames: (type: string) =>
      dispatch(FatchLiveCasinoGames(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TVGamesView);
