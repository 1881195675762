import { IonRow } from '@ionic/react';
import React from 'react';
import { BRAND_NAME } from '../../constants/Branding';
import './Notification-tab.scss';

const NotificationTab: React.FC = (props) => {
  return (
    <IonRow className="notification-ctnn">
      <div className="notification-text">A unique and flawless gaming experience</div>
    </IonRow>
  );
};

export default NotificationTab;
