import React, { useState } from 'react';
import './LoginForm.scss';
import loginimg from '../../assets/images/common/loginbg.png';
import LoginForm from './LoginForm';
import { Tabs, Tab, Button } from '@material-ui/core';
import TabPanel from '../../components/TabPanel/TabPanel';
import SignUp from '../../pages/SignUp/SignUp';
import { IonCol, IonIcon } from '@ionic/react';
import facebook from '../../assets/images/common/facebook.svg';
import google from '../../assets/images/common/google.svg';

type LoginProps = {
  modalCloseHandler: Function;
  tabActive?: number;
};

const LoginPage: React.FC<LoginProps> = (props) => {
  const { modalCloseHandler, tabActive } = props;
  const [tabValue, setTabValue] = useState<number>(tabActive ? tabActive : 0);
  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="login-ctn">
      {/* <img src={Background} className="background-img" /> */}
      {/* <div className="left-ctn">
        <img src={loginimg} />
      </div> */}
      <div className="right-ctn">
        {/* <Tabs value={tabValue} onChange={handleTabChange} className="tab-list">
          <Tab label="Register" wrapped value={1} />
          <Tab label="Login" wrapped value={0} />
        </Tabs> */}
        {/* {
          tabValue == 1 ?
            <Button onClick={() => handleTabChange(0)}>Login</Button>
            :
            <Button onClick={() => handleTabChange(1)}>Register</Button>

        } */}

        <TabPanel value={tabValue} index={0} className="tab-ctn">
          {/* <div className="header-ctn">
            <div className="dot">
              <IonIcon src={google} className="icon" />
            </div>
            <div className="dot">
              <IonIcon src={facebook} className="icon" />
            </div>
          </div> */}
          {/* <p className="header-title">Or Use Your Email Account</p> */}
          <LoginForm modalCloseHandler={modalCloseHandler} />
        </TabPanel>

        <TabPanel value={tabValue} index={1} className="tab-ctn">
          {/* <div className="header-ctn web-view">
            <div className="dot">
              <IonIcon src={google} className="icon" />
            </div>
            <div className="dot">
              <IonIcon src={facebook} className="icon" />
            </div>
          </div> */}
          {/* <p className="header-title web-view">Or Use Your Email Account</p> */}
          <SignUp setTabValue= {setTabValue}/>
        </TabPanel>
        {
          tabValue == 1?
          // <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12">
          <div className="link pb-50">
            Already have an account?
            <span className="register-link" onClick={() => handleTabChange(0)}> Sign in</span>
          </div>
        // </IonCol>
        : 
        <div className="link pb-50">
          Don't have an account?
          <a className="register-link" href={`https://wa.link/skycasino`} target='_blank'> Sign up</a>
        </div>
        }
      </div>
    </div>
  );
};

export default LoginPage;
