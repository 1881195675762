import React, { useState } from 'react';
import {
  Paper,
  Collapse,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
} from '@material-ui/core';
import { IonRow, IonCol } from '@ionic/react';
import { PLStatement } from '../../models/PLStatement';
import './ProfitLossStatement.scss';
import moment, { Moment } from 'moment';
import PLStatementMktLvl from './PLStatementMktLvl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
type PLProps = {
  items: any;
  totalPL: Map<string, number>;
  startDate: Moment;
  endDate: Moment;
  searchName: string;
};

const ProfitLossStatement: React.FC<PLProps> = (props) => {
  const { items, totalPL, startDate, endDate, searchName } = props;
  const [selectedRecord, setSelectedRecord] = useState<PLStatement>();
  const [open, setOpen] = React.useState<any>({});

  return (
    <>
      <div className="web-view pl-statement-report">
        <TableContainer component={Paper}>
          <Table className="myb-table" size="small">
            <TableHead className="myb-table-header">
              <TableRow>
                <TableCell align="center" className="game-type-col"></TableCell>
                <TableCell align="center" className="game-type-col">
                  Market
                </TableCell>
                <TableCell align="center" className="bwon-col">
                  Start time
                </TableCell>
                <TableCell align="center" className="blost-col">
                  Settled time
                </TableCell>
                <TableCell align="center" className="pandl-col">
                  Comm.{' '}
                </TableCell>

                <TableCell align="center" className="pandl-col">
                  Net Win
                </TableCell>
              </TableRow>
            </TableHead>

            {items && items.size > 0 ? (
              <>
                {[...items.keys()]
                  .sort((a, b) => (a < b ? 1 : -1))
                  .map((key) => (
                    <>
                      <TableBody className="tbl-body">
                        {/* <TableRow>
                          <TableCell className="date-header" align="left">
                            <div className="date-header-ctn date-label">
                              {' '}
                              {moment(key, 'DD-MM-YYYY').format('DD/MM/YYYY')}
                            </div>
                          </TableCell>

                          <TableCell align="left" colSpan={5}></TableCell>
                        </TableRow> */}
                        {items.get(key).map((row) => (
                          <>
                            <TableRow
                              className="myb-table-row m-link"
                              key={
                                'key_' + Math.random().toString(36).substr(2, 9)
                              }
                              onClick={() => {
                                setSelectedRecord(row);
                              }}
                            >
                              <TableCell align="center">
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() =>
                                    setOpen((prevState) => {
                                      return {
                                        ...prevState,
                                        [moment(key, 'DD-MM-YYYY').format(
                                          'DD/MM/YYYY'
                                        ) +
                                        '-' +
                                        row?.eventId +
                                        '-' +
                                        row?.marketId]: prevState[
                                          moment(key, 'DD-MM-YYYY').format(
                                            'DD/MM/YYYY'
                                          ) +
                                            '-' +
                                            row?.eventId +
                                            '-' +
                                            row?.marketId
                                        ]
                                          ? false
                                          : true,
                                      };
                                    })
                                  }
                                >
                                  {open[
                                    moment(key, 'DD-MM-YYYY').format(
                                      'DD/MM/YYYY'
                                    ) +
                                      '-' +
                                      row?.eventId +
                                      '-' +
                                      row?.marketId
                                  ] ? (
                                    <KeyboardArrowUpIcon htmlColor={'#000'} />
                                  ) : (
                                    <KeyboardArrowDownIcon htmlColor={'#000'} />
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="myb-table-cell market-name-col m-link"
                              >
                                <span className="txt-bldin-mob">
                                  {row.eventName ? row.eventName : row.gameType}{' '}
                                  {row.marketName}
                                </span>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="myb-table-cell bwon-col m-link"
                              >
                                <span className="txt-bldin-mob">
                                  {moment(row?.betPlacedTime).format(
                                    'D/M/YYYY HH:mm:ss'
                                  )}
                                </span>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="myb-table-cell blost-col m-link"
                              >
                                <span className="txt-bldin-mob">
                                  {moment(row?.payOutDate).format(
                                    'D/M/YYYY HH:mm:ss'
                                  )}
                                </span>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="pandl-col m-link"
                              >
                                <span className="txt-bldin-mob">
                                  {!row.commission ? (
                                    <div>{Number(0).toFixed(2)}</div>
                                  ) : row.commission > 0 ? (
                                    <div className="profit">
                                      {Number(row.commission).toFixed(2)}
                                    </div>
                                  ) : row.commission < 0 ? (
                                    <div className="loss">
                                      {Number(row.commission).toFixed(2)}
                                    </div>
                                  ) : (
                                    <>
                                      {Number(row.commission).toFixed(2) || 0}
                                    </>
                                  )}
                                </span>
                              </TableCell>

                              <TableCell
                                align="center"
                                className="pandl-col m-link"
                              >
                                <span className="txt-bldin-mob">
                                  {row.profit > 0 ? (
                                    <div className="profit">
                                      {Number(
                                        row.profit + row.commission
                                      ).toFixed(2)}
                                    </div>
                                  ) : row.profit < 0 ? (
                                    <div className="loss">
                                      {Number(
                                        row.profit + row.commission
                                      ).toFixed(2)}
                                    </div>
                                  ) : (
                                    <>
                                      {' '}
                                      {Number(
                                        row.profit + row.commission
                                      ).toFixed(2)}
                                    </>
                                  )}
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                align="center"
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={6}
                              >
                                <Collapse
                                  in={
                                    open[
                                      moment(key, 'DD-MM-YYYY').format(
                                        'DD/MM/YYYY'
                                      ) +
                                        '-' +
                                        row?.eventId +
                                        '-' +
                                        row?.marketId
                                    ]
                                      ? true
                                      : false
                                  }
                                  timeout="auto"
                                  unmountOnExit
                                  className="Collapse-list"
                                >
                                  <PLStatementMktLvl
                                    selectedMarket={row}
                                    startDate={startDate}
                                    endDate={endDate}
                                    showGameLevel={() => {
                                      setSelectedRecord(null);
                                    }}
                                    searchName={searchName}
                                  />
                                </Collapse>{' '}
                              </TableCell>{' '}
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </>
                  ))}
              </>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    className="no-data-cell"
                    variant="body"
                    colSpan={7}
                  >
                    {!searchName ? (
                      <div>Please select user and filters to fetch data.</div>
                    ) : (
                      <div> No records found.</div>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>

      {items && items.size > 0 ? (
        <div className="mob-view mob-ctn-pl">
          {[...items.keys()]
            .sort((a, b) => (a < b ? 1 : -1))
            .map((key) => (
              <div className="pl-mob-div">
                <div className="heading">
                  <div className="date date-label">
                    {moment(key, 'DD-MM-YYYY').format('Do MMMM YYYY')}
                  </div>
                  <div className="pl">
                    P&L : {'  '}
                    <div
                      className={
                        totalPL.has(key) && totalPL.get(key) > 0
                          ? ' profit'
                          : ' loss'
                      }
                    >
                      {'  '}
                      {totalPL.has(key)
                        ? totalPL.get(key).toFixed(2)
                        : 0.0}{' '}
                    </div>
                  </div>
                </div>
                {items.get(key).map((row) => (
                  <>
                    <div className="pl-content">
                      <IonRow className="bet-into">
                        <IonCol sizeXs="8" sizeSm="8" className="market-info">
                          <div
                            className="link"
                            onClick={() => {
                              setSelectedRecord(row);
                            }}
                          >
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() =>
                                setOpen((prevState) => {
                                  return {
                                    ...prevState,
                                    [moment(key, 'DD-MM-YYYY').format(
                                      'DD/MM/YYYY'
                                    ) +
                                    '-' +
                                    row?.eventId +
                                    '-' +
                                    row?.marketId]: prevState[
                                      moment(key, 'DD-MM-YYYY').format(
                                        'DD/MM/YYYY'
                                      ) +
                                        '-' +
                                        row?.eventId +
                                        '-' +
                                        row?.marketId
                                    ]
                                      ? false
                                      : true,
                                  };
                                })
                              }
                            >
                              {open[
                                moment(key, 'DD-MM-YYYY').format('DD/MM/YYYY') +
                                  '-' +
                                  row?.eventId +
                                  '-' +
                                  row?.marketId
                              ] ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>{' '}
                            {row.eventName ? row.eventName : row.gameType} -{' '}
                            {row.marketName}
                          </div>
                          <div>
                            <strong>Placed:</strong>{' '}
                            {moment(row.betPlacedTime).format('D/M/YYYY HH:mm')}{' '}
                          </div>
                          <div>
                            <strong>Settled:</strong>{' '}
                            {moment(row.payOutDate).format('D/M/YYYY HH:mm')}
                          </div>
                        </IonCol>
                        <IonCol
                          sizeXs="4"
                          sizeSm="4"
                          className="market-amount-info"
                        >
                          <div>
                            <div className="b-text">Comm:</div>
                            <div className="b-text">Net Win:</div>
                          </div>
                          <div>
                            <div className="b-text">
                              {' '}
                              <div>
                                {' '}
                                {!row.commission ? (
                                  <div>{Number(0).toFixed(2)}</div>
                                ) : row.commission > 0 ? (
                                  <div className="profit">
                                    {Number(row.commission).toFixed(2)}
                                  </div>
                                ) : row.commission < 0 ? (
                                  <div className="loss">
                                    {Number(row.commission).toFixed(2)}
                                  </div>
                                ) : (
                                  <>{Number(row.commission).toFixed(2) || 0}</>
                                )}
                              </div>
                            </div>
                            <div className="b-text">
                              {row.profit > 0 ? (
                                <div className="profit">
                                  {Number(row.profit + row.commission).toFixed(
                                    2
                                  )}
                                </div>
                              ) : row.profit < 0 ? (
                                <div className="loss">
                                  {Number(row.profit + row.commission).toFixed(
                                    2
                                  )}
                                </div>
                              ) : (
                                <>
                                  {' '}
                                  {Number(row.profit + row.commission).toFixed(
                                    2
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </div>

                    <Collapse
                      in={
                        open[
                          moment(key, 'DD-MM-YYYY').format('DD/MM/YYYY') +
                            '-' +
                            row?.eventId +
                            '-' +
                            row?.marketId
                        ]
                          ? true
                          : false
                      }
                      timeout="auto"
                      unmountOnExit
                      className="Collapse-list"
                    >
                      <PLStatementMktLvl
                        selectedMarket={row}
                        startDate={startDate}
                        endDate={endDate}
                        showGameLevel={() => {
                          setSelectedRecord(null);
                        }}
                        searchName={searchName}
                      />
                    </Collapse>
                  </>
                ))}
              </div>
            ))}
        </div>
      ) : null}
    </>
  );
};

export default ProfitLossStatement;
