import React, { useEffect, useState } from 'react';
import 'react-tagsinput/react-tagsinput.css';

import API from '../../api/index';
import moment from 'moment';
import './AdminNotification.scss';
import Spinner from '../Spinner/Spinner';
import { IonRow, IonCol } from '@ionic/react';
import { useInterval } from '../../hooks/useInterval';
import SVLS_API from '../../svls-api';

type Notification = {
  active: boolean;
  allowedConfig: number;
  createdBy: string;
  dateCreated: Date;
  endTime: Date;
  houseId: number;
  id: number;
  lastUpdated: Date;
  message: string;
  startTime: Date;
  title: string;
  updatedBy: string;
};

type AdminNotificationProps = {
  allowedConfig: number;
  loggedIn: boolean;
};
const AdminNotification: React.FC<AdminNotificationProps> = (props) => {
  const { loggedIn, allowedConfig } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const fetchNotifications = async () => {
    setLoading(true);
    const response = await API.get('/notifications/all', {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
    });
    setNotifications(response.data);
    setLoading(false);
  };

  // useInterval(() => {
  //   if (loggedIn) {
  //     fetchNotifications();
  //   }
  // }, 1000 * 90);

  // useEffect(() => {
  //   fetchNotifications();
  // }, []);

  return (
    <div className="notification-ctn">
      {loading ? (
        <Spinner />
      ) : (
        <>
          {notifications ? (
            <>
              {notifications && notifications.length > 0 ? (
                <>
                  {notifications.map((row, idx) => (
                    <>
                      {(allowedConfig & row.allowedConfig) !== 0 && idx < 10 ? (
                        <IonRow>
                          <IonCol size="3">
                            <span>
                              {moment(row.dateCreated).format('DD MMM')}
                            </span>
                          </IonCol>
                          <IonCol>
                            <div className="message">{row.message}</div>
                            {row.title}
                          </IonCol>
                        </IonRow>
                      ) : null}
                    </>
                  ))}
                </>
              ) : null}
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default AdminNotification;
