import React, { useState, useCallback, useEffect } from 'react';
import {
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonCard,
  IonLoading,
  IonSpinner,
  IonToast,
} from '@ionic/react';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './MyProfile.scss';
import API from '../../api/index';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '../Modal/Modal';
import ChangePwdForm from '../ChangePassword/ChangePassword';
import proImage from '../../assets/images/common/pro.png';

type ToastProps = {
  message: string;
  color: string;
  show: boolean;
};

type UpdateUserPersonalDetailsrequest = {
  fullName: string;
  phoneNumber: string;
  emailId: string;
  address: string;
  city: string;
  pinCode: string;
};

type UserPersonalDetails = UpdateUserPersonalDetailsrequest & {
  username: string;
};

const MyProfile: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserPersonalDetails>();
  const [toastDetails, setToastDetails] = useState<ToastProps>({
    message: '',
    color: '',
    show: false,
  });
  const [changePwdMOdal, setChangePwdMOdal] = useState<boolean>(false);
  const fetchData = useCallback(async () => {
    setLoading(true);
    const response = await API.get('/user/personal-details', {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
    });
    const details = response.data;
    setUserDetails(details);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const pinCodeRegex = /^[1-9][0-9]{5}$/;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: userDetails ? userDetails.fullName : '',
      phoneNumber: userDetails ? userDetails.phoneNumber : '',
      emailId: userDetails ? userDetails.emailId : '',
      address: userDetails ? userDetails.address : '',
      city: userDetails ? userDetails.city : '',
      pinCode: userDetails ? userDetails.pinCode : '',
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string().matches(
        phoneRegExp,
        'Phone number is not valid'
      ),
      pinCode: Yup.string().matches(pinCodeRegex, 'Pin Code is not valid'),
    }),
    onSubmit: (values) => {
      let data: UpdateUserPersonalDetailsrequest = {
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        emailId: values.emailId,
        address: values.address,
        city: values.city,
        pinCode: values.pinCode,
      };
      updateDetails(data);
    },
  });

  const updateDetails = async (data: UpdateUserPersonalDetailsrequest) => {
    setProgress(true);
    const response = await API.put('/user/personal-details', data, {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
    });
    setProgress(false);
    if (response.status === 200) {
      setToastDetails({
        message: 'Details saved successfully',
        color: 'success',
        show: true,
      });
    } else {
      setToastDetails({
        message: 'Failed to save details, something went wrong!',
        color: 'danger',
        show: true,
      });
    }
    fetchData();
  };

  return (
    <>
      <IonToast
        isOpen={toastDetails.show}
        onDidDismiss={() =>
          setToastDetails({
            message: '',
            color: '',
            show: false,
          })
        }
        message={toastDetails.message}
        color={toastDetails.color}
        position="top"
        duration={1000}
      />
      <div className="my-profile-ctn">
        <div className="left-ctn">
          <div className="pro-ctn">
            <img src={proImage} />
            <div className="name">
              {userDetails ? userDetails.username : null}
            </div>
          </div>
        </div>
        <div className="right-ctn">
          {loading ? (
            <IonLoading isOpen={loading} message={'Fetching user details...'} />
          ) : (
            <form onSubmit={formik.handleSubmit} className="login-form-ctn">
              <IonRow>
                <IonCol
                  sizeXs="12"
                  sizeSm="12"
                  sizeMd="6"
                  sizeLg="6"
                  className="login-col"
                >
                  {/* <IonLabel>Username</IonLabel> */}

                  <IonInput
                    value={userDetails ? userDetails.username : null}
                    disabled
                    placeholder="Username"
                  />
                </IonCol>

                <IonCol
                  sizeXs="12"
                  sizeSm="12"
                  sizeMd="6"
                  sizeLg="6"
                  className="login-col"
                >
                  {/* <IonLabel>Full Name</IonLabel> */}

                  <IonInput
                    id="user-adornment-FullName"
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    value={formik.values.fullName}
                    onInput={(e) => formik.handleChange(e)}
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <FormHelperText
                      id="my-helper-text"
                      className="my-helper-text"
                    >
                      {formik.errors.fullName}
                    </FormHelperText>
                  ) : null}
                </IonCol>

                <IonCol
                  sizeXs="12"
                  sizeSm="12"
                  sizeMd="6"
                  sizeLg="6"
                  className="login-col"
                >
                  {/* <IonLabel>Email</IonLabel> */}

                  <IonInput
                    id="user-adornment-emailId"
                    type="email"
                    name="emailId"
                    placeholder="Email"
                    value={formik.values.emailId}
                    onInput={(e) => formik.handleChange(e)}
                  />
                  {formik.touched.emailId && formik.errors.emailId ? (
                    <FormHelperText
                      id="my-helper-text"
                      className="my-helper-text"
                    >
                      {formik.errors.emailId}
                    </FormHelperText>
                  ) : null}
                </IonCol>

                <IonCol
                  sizeXs="12"
                  sizeSm="12"
                  sizeMd="6"
                  sizeLg="6"
                  className="login-col"
                >
                  {/* <IonLabel>Phone Number</IonLabel> */}

                  <IonInput
                    id="user-adornment-phone"
                    type="tel"
                    inputMode="tel"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={formik.values.phoneNumber}
                    onInput={(e) => formik.handleChange(e)}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <FormHelperText
                      id="my-helper-text"
                      className="my-helper-text"
                    >
                      {formik.errors.phoneNumber}
                    </FormHelperText>
                  ) : null}
                </IonCol>

                <IonCol
                  sizeXs="12"
                  sizeSm="12"
                  sizeMd="6"
                  sizeLg="6"
                  className="login-col"
                >
                  {/* <IonLabel>Address</IonLabel> */}

                  <IonInput
                    name="address"
                    placeholder="Address"
                    value={formik.values.address}
                    onInput={(e) => formik.handleChange(e)}
                  ></IonInput>
                  {formik.touched.address && formik.errors.address ? (
                    <FormHelperText
                      id="my-helper-text"
                      className="my-helper-text"
                    >
                      {formik.errors.address}
                    </FormHelperText>
                  ) : null}
                </IonCol>

                <IonCol
                  sizeXs="12"
                  sizeSm="12"
                  sizeMd="6"
                  sizeLg="6"
                  className="login-col"
                >
                  {/* <IonLabel>City</IonLabel> */}

                  <IonInput
                    name="city"
                    placeholder="City"
                    value={formik.values.city}
                    onInput={(e) => formik.handleChange(e)}
                  ></IonInput>
                  {formik.touched.city && formik.errors.city ? (
                    <FormHelperText
                      id="my-helper-text"
                      className="my-helper-text"
                    >
                      {formik.errors.city}
                    </FormHelperText>
                  ) : null}
                </IonCol>

                <IonCol
                  sizeXs="12"
                  sizeSm="12"
                  sizeMd="6"
                  sizeLg="6"
                  className="login-col"
                >
                  {/* <IonLabel>Pincode</IonLabel> */}

                  <IonInput
                    className="input-address"
                    name="pinCode"
                    placeholder="Pin Code"
                    value={formik.values.pinCode}
                    onInput={(e) => formik.handleChange(e)}
                  ></IonInput>
                  {formik.touched.pinCode && formik.errors.pinCode ? (
                    <FormHelperText
                      id="my-helper-text"
                      className="my-helper-text"
                    >
                      {formik.errors.pinCode}
                    </FormHelperText>
                  ) : null}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  sizeXs="12"
                  sizeSm="12"
                  sizeMd="12"
                  sizeLg="12"
                  className="login-col"
                >
                  <Button
                    type="submit"
                    endIcon={
                      progress ? <IonSpinner name="lines-small" /> : null
                    }
                    variant="contained"
                    className="login-form-btn"
                  >
                    Update profile
                  </Button>
                </IonCol>
              </IonRow>
            </form>
          )}
        </div>
      </div>
      <Backdrop className="backdrop-ctn" open={changePwdMOdal}>
        <Modal
          open={changePwdMOdal}
          closeHandler={() => setChangePwdMOdal(false)}
          title="Change Password"
          size="xs"
        >
          <ChangePwdForm
            closeHandler={() => setChangePwdMOdal(false)}
            backHandler={() => {}}
          />
        </Modal>
      </Backdrop>
    </>
  );
};

export default MyProfile;
