import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { Paper, TableRow } from '@material-ui/core';
import { IonRow, IonCol } from '@ionic/react';
import { PLStatement, PLRecordMktLvl } from '../../models/PLStatement';
import './ProfitLossStatement.scss';
import moment, { Moment } from 'moment';
import { AuthResponse } from '../../models/api/AuthResponse';
import API from '../../api';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import { getCurrencyTypeFromToken } from '../../store';

type PLProps = {
  searchName: string;
  selectedMarket: PLStatement;
  startDate: Moment;
  endDate: Moment;
  showGameLevel: () => void;
};

const PLStatementMktLvl: React.FC<PLProps> = (props) => {
  const { selectedMarket, startDate, endDate, showGameLevel, searchName } =
    props;
  const [items, setItems] = useState<PLRecordMktLvl[]>();

  const [backTotal, setBackTotal] = useState<number>(0);
  const [layTotal, setLayTotal] = useState<number>(0);
  const [marketTotal, setMarketTotal] = useState<number>(0);
  const [netMarket, setNetMarket] = useState<number>(0);
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];

  const tableFields = [
    {
      key: 'betPlacedTime',
      Label: 'Placed',
      className: 'placed-cell-head',
      align: 'center',
    },
    {
      key: 'selection',
      Label: 'Selection',
      className: 'selection-cell-head',
      align: 'center',
    },
    {
      key: 'betId',
      Label: 'Bet ID',
      className: 'betid-cell-head',
      align: 'center',
    },
    {
      key: 'betType',
      Label: 'Type',
      className: 'type-cell-head',
      align: 'center',
    },
    {
      key: 'odds',
      Label: 'Odds',
      className: 'odds-cell-head',
      align: 'center',
    },
    {
      key: 'stake',
      Label: 'Stake',
      className: 'stake-cell-head',
      align: 'center',
    },
    {
      key: 'profitLoss',
      Label: 'Profit/Loss',
      className: 'ploss-cell-head',
      align: 'center',
    },
    {
      key: 'status',
      Label: 'Status',
      className: 'status-cell-head',
      align: 'center',
    },
  ];

  const fetchPLByMarket = async () => {
    try {
      const response: AuthResponse = await API.post(
        '/sap/reports/get-pl-market',
        {
          eventId: selectedMarket.eventId,
          marketId: selectedMarket.marketId,
          categoryType: selectedMarket.gameType,
          startDate: startDate.startOf('day').toISOString(),
          endDate: endDate.endOf('day').toISOString(),
          searchUserName: searchName,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 200) {
        if (response.data) {
          let records: PLRecordMktLvl[] = response.data;
          setItems(records);
          let backCount = 0;
          let layCount = 0;
          let marketAmt = 0;
          for (let record of records) {
            if (record.betType == 0) {
              backCount += record.profitAmount;
            } else {
              layCount += record.profitAmount;
            }
            marketAmt += record.profitAmount;
          }
          setBackTotal(backCount / cFactor);
          setLayTotal(layCount / cFactor);
          setMarketTotal(marketAmt / cFactor);
          setNetMarket(
            marketAmt / cFactor +
              (selectedMarket.commission ? selectedMarket.commission : 0)
          );
        }
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.error(err.response.data.error);
      }
    }
  };

  useEffect(() => {
    fetchPLByMarket();
  }, []);

  return (
    <>
      {selectedMarket ? (
        <>
          <div className="pl-stmt-tbl web-view">
            <TableContainer component={Paper}>
              <Table className="myb-table">
                <TableHead className="myb-table-header">
                  <TableRow>
                    {tableFields.map((tF, index) => (
                      <TableCell
                        key={'key_' + Math.random().toString(36).substr(2, 9)}
                        align={'center'}
                        className={tF.className}
                      >
                        {tF.Label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {items && items.length > 0 ? (
                  <>
                    <TableBody className="tbl-body">
                      {items.map((row) => (
                        <>
                          <TableRow
                            className={
                              row.profitAmount > 0
                                ? 'profit-bg'
                                : row.profitAmount <= 0
                                ? 'loss-bg'
                                : 'row-bg'
                            }
                            key={
                              'key_' + Math.random().toString(36).substr(2, 9)
                            }
                          >
                            <TableCell align="center">
                              <span className="txt-bldin-mob">
                                {moment(row.betPlacedTime).format(
                                  'D/M/YYYY HH:mm:ss'
                                )}
                              </span>
                            </TableCell>
                            <TableCell
                              className="myb-table-cell bwon-col"
                              align="center"
                            >
                              <span className="txt-bldin-mob">
                                {row.runnerName}
                              </span>
                            </TableCell>
                            <TableCell
                              className="myb-table-cell blost-col"
                              align="center"
                            >
                              <span className="txt-bldin-mob">{row.betId}</span>
                            </TableCell>
                            <TableCell
                              className="myb-table-cell blost-col"
                              align="center"
                            >
                              <span className="txt-bldin-mob">
                                {row.betType == 0 ? 'Back' : 'Lay'}
                              </span>
                            </TableCell>
                            <TableCell
                              className="myb-table-cell blost-col"
                              align="center"
                            >
                              <span className="txt-bldin-mob">
                                {row.oddValue === -1
                                  ? '-'
                                  : row.oddValue.toFixed(2)}
                              </span>
                            </TableCell>
                            <TableCell
                              className="myb-table-cell blost-col"
                              align="center"
                            >
                              <span className="txt-bldin-mob">
                                {(row.stakeAmount / cFactor).toFixed(2)}
                              </span>
                            </TableCell>
                            <TableCell align="center" className="pandl-col">
                              <span className="txt-bldin-mob">
                                <div
                                  className={
                                    row.outcomeResult == 1 ? 'profit' : 'loss'
                                  }
                                >
                                  {Number(row.profitAmount / cFactor).toFixed(
                                    2
                                  )}
                                </div>
                              </span>
                            </TableCell>

                            <TableCell align="center" className="pandl-col">
                              <span className="txt-bldin-mob">
                                <div
                                  className={
                                    row.outcomeResult == 1 ? 'profit' : 'loss'
                                  }
                                >
                                  {row.outcomeResult == 1 ? 'Win' : 'Loss'}
                                </div>
                              </span>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className="no-data-cell"
                        variant="body"
                        colSpan={8}
                      >
                        No records found.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
          {items && items.length > 0 ? (
            <div className="mob-view mob-ctn-pl">
              <div className="pl-mob-div">
                <div
                  className="heading"
                  onClick={() => {
                    showGameLevel();
                  }}
                >
                  <div className="date">
                    <ArrowBackIcon
                      className="back"
                      onClick={() => {
                        showGameLevel();
                      }}
                    />
                    {`${selectedMarket.eventName}-${selectedMarket.marketName}`}
                  </div>
                </div>
                {items.map((row) => (
                  <div
                    className={`pl-content ${
                      row.betType === 0 ? 'back-odd-row' : 'lay-odd-row'
                    }`}
                  >
                    <IonRow>
                      <IonCol sizeXs="7" sizeSm="7" className="market-info">
                        <div>
                          <span
                            className={`label ${
                              row.betType == 0
                                ? 'back-bet-font'
                                : 'lay-bet-font'
                            }`}
                          >
                            Selection:{' '}
                          </span>
                          <span
                            className={`value ${
                              row.betType == 0
                                ? 'back-bet-font'
                                : 'lay-bet-font'
                            }`}
                          >
                            {row.runnerName}
                          </span>
                        </div>
                        <div>
                          <span className="label">Bet ID: </span>
                          <span className="value">{row.betId}</span>
                        </div>
                        <div>
                          <span className="label">Placed: </span>
                          <span className="value">
                            {moment(row.betPlacedTime).format(
                              'D/M/YYYY HH:mm:ss'
                            )}
                          </span>
                        </div>
                      </IonCol>
                      <IonCol sizeXs="5" sizeSm="5" className="market-info">
                        <div className="flex-row">
                          <span className="label">Status: </span>
                          <span className={`value ml-auto`}>
                            <div
                              className={
                                row.outcomeResult == 1 ? 'profit' : 'loss'
                              }
                            >
                              {row.outcomeResult == 1 ? 'Win' : 'Loss'}
                            </div>
                          </span>
                        </div>
                        <div className="flex-row">
                          <span className="label">Profit/Loss: </span>
                          <span className={`value ml-auto`}>
                            <div
                              className={
                                row.outcomeResult == 1 ? 'profit' : 'loss'
                              }
                            >
                              {Number(row.profitAmount / cFactor).toFixed(2)}
                            </div>
                          </span>
                        </div>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="bet-info">
                        <div className="label">Type</div>
                        <div className="value">
                          {row?.betType == 0 ? 'Back' : 'Lay'}
                        </div>
                      </IonCol>
                      <IonCol className="bet-info">
                        <div className="label">Odds</div>
                        <div className="value">{row?.oddValue}</div>
                      </IonCol>
                      <IonCol className="bet-info">
                        <div className="label">Stake</div>
                        <div className="value">
                          {row?.stakeAmount / cFactor}
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          <div className="pl-summary-mob">
            <IonRow>
              Back subtotal:{' '}
              <span className="ml-auto">{Number(backTotal).toFixed(2)}</span>
            </IonRow>
            <IonRow>
              Lay subtotal:{' '}
              <span className="ml-auto">{Number(layTotal).toFixed(2)}</span>
            </IonRow>
            <IonRow>
              Market subtotal:{' '}
              <span className="ml-auto">{Number(marketTotal).toFixed(2)}</span>
            </IonRow>
            <IonRow className="border-bottom">
              Commission:{' '}
              <span className="ml-auto">
                {Number(selectedMarket.commission ?? 0).toFixed(2)}
              </span>
            </IonRow>
            <IonRow className="border-bottom net-market">
              <div className="b-text">Net Market Total:</div>{' '}
              <span className="ml-auto">
                {Number(netMarket ?? 0).toFixed(2)}
              </span>
            </IonRow>
          </div>
        </>
      ) : null}
    </>
  );
};

export default PLStatementMktLvl;
