import React from 'react';
import { useParams } from 'react-router-dom';

import './WacsIframe.scss';

type RouteParams = {
  gameId: any;
};

const WacsIframe: React.FC = () => {
  const { gameId } = useParams<RouteParams>();

  const wacsToken = sessionStorage.getItem('wacs_token');

  const iFrameSrc = wacsToken
    ? `https://pi-test.njoybingo.com/game.do?token=${wacsToken}&pn=kia&game=${gameId}&type=CHARGED&lang=en`
    : `https://pi-test.njoybingo.com/game.do?pn=kia&game=${gameId}&type=FREE&lang=en`;

  return (
    <div className="wacs-iframe-ctn">
      <iframe
        src={iFrameSrc}
        title="WACS game"
        sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
      ></iframe>
    </div>
  );
};

export default WacsIframe;
