import React, { useState, useEffect } from 'react';
import { IonSpinner } from '@ionic/react';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import API from '../../api/index';
import { useHistory } from 'react-router-dom';
import { AuthResponse } from '../../models/api/AuthResponse';
import { IonLabel } from '@ionic/react';
import './UserResetPassword.scss';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

type ResetPwdProps = {
  username: string;
  keyValue: string;
  closeHandler: () => void;
};

type ResetPasswordRequest = {
  resetUser: string;
  resetKey: string;
  resetPassword: string;
};

const UserResetPwdForm: React.FC<ResetPwdProps> = (props) => {
  const { username, keyValue, closeHandler } = props;
  const [progress, setProgress] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(null);
  const [successMsg, setSuccessMsg] = useState<string>(null);

  let history = useHistory();

  const formik = useFormik({
    initialValues: { newPwd: '', confNewPwd: '' },
    validationSchema: Yup.object({
      newPwd: Yup.string()
        .required('Required')
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,}$/,
          'Please choose a strong password. For more details hover on help icon(?).'
        ),
      confNewPwd: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      setErrorMsg(null);
      setSuccessMsg(null);
      const data: ResetPasswordRequest = {
        resetUser: username,
        resetKey: keyValue,
        resetPassword: values.confNewPwd,
      };
      if (values.newPwd === values.confNewPwd) {
        if (values.newPwd === username) {
          setErrorMsg('New password should not be same as username');
        } else {
          resetPassword(data);
        }
      } else {
        setErrorMsg('Passwords mismatch');
      }
    },
  });

  const resetPassword = async (data: ResetPasswordRequest) => {
    try {
      setProgress(true);

      const response: AuthResponse = await API.put(
        '/user/reset-password',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        setProgress(false);
        setSuccessMsg(response.data.message);
        setTimeout(() => redirectHandel(), 3000);
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setErrorMsg(err.response.data.message);
      }
      setProgress(false);
    }
  };

  useEffect(() => {
    setErrorMsg(null);
    setSuccessMsg(null);
  }, []);

  const redirectHandel = () => {
    closeHandler();
    history.push('/');
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="reset-pwd-form-ctn"
      autoComplete="off"
    >
      <div className="user-details">
        <div className="title">
          Reset password for{' '}
          <b>
            <i>{username}</i>
          </b>
        </div>
      </div>

      <div>
        <IonLabel className="input-label">
          Enter New Password
          <Tooltip
            className="input-tooltip"
            title="Password must contains at least 8 characters and at most 20 characters (It contains at least One Uppercase, One Lowercase and One Number)"
          >
            <HelpOutlineIcon />
          </Tooltip>
        </IonLabel>

        <FormControl
          className="login-input-field pwd-field"
          variant="outlined"
          error={formik.touched.newPwd && formik.errors.newPwd ? true : false}
        >
          <OutlinedInput
            id="standard-adornment-password"
            type="password"
            name="newPwd"
            {...formik.getFieldProps('newPwd')}
          />
          {formik.touched.newPwd && formik.errors.newPwd ? (
            <FormHelperText error id="my-helper-text">
              {formik.errors.newPwd}
            </FormHelperText>
          ) : null}
        </FormControl>
      </div>

      <div className="pwd-input">
        <IonLabel className="input-label">Confirm Password</IonLabel>
        <FormControl
          className="login-input-field pwd-field"
          variant="outlined"
          error={
            formik.touched.confNewPwd && formik.errors.confNewPwd ? true : false
          }
        >
          <OutlinedInput
            id="standard-adornment-password"
            type="password"
            name="confNewPwd"
            {...formik.getFieldProps('confNewPwd')}
          />
          {formik.touched.confNewPwd && formik.errors.confNewPwd ? (
            <FormHelperText error id="my-helper-text">
              {formik.errors.confNewPwd}
            </FormHelperText>
          ) : null}
        </FormControl>
      </div>

      {errorMsg !== '' ? <span className="error-msg">{errorMsg}</span> : null}
      {successMsg !== '' ? (
        <span className="success-msg">{successMsg}</span>
      ) : null}

      <Button
        className="submit-form-btn"
        color="primary"
        endIcon={progress ? <IonSpinner name="lines-small" /> : ''}
        type="submit"
        variant="contained"
      >
        {' '}
        Confirm
      </Button>
    </form>
  );
};

export default UserResetPwdForm;
