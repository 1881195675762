import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import commonReducer from './common/commonReducer';
import indianCasinoReducer from './casino/CasinoGameReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  indianCasino: indianCasinoReducer,
});

export default rootReducer;
