import { IonSpinner } from '@ionic/react';
import React from 'react';
import { BRAND_NAME } from '../../constants/Branding';
import { LogoByTheme } from '../../util/stringUtil';
import './LoadingPage.scss';
import logo from "../../assets/images/brand/title.svg";

const LoadingPage: React.FC = () => {
  return (
    <div className="loading-page-ctn">
      <div className="item-row">
        {/* <img src={LogoByTheme()} className="title-img" alt={BRAND_NAME} /> */}
        <img src={logo} className="title-img" />
      </div>
      <div className="item-row">
        <IonSpinner className="loading-page-spinner" name="bubbles" />
      </div>
    </div>
  );
};

export default LoadingPage;
