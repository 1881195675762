import React from 'react';
import { connect } from 'react-redux';
import Dashboard from '../../components/Dashboard/Dashboard';
import { RootState } from '../../models/RootState';
import './DashboardView.scss';

type StoreProps = {
  loggedIn: boolean;
};

const DashboardView: React.FC<StoreProps> = (props) => {
  const { loggedIn } = props;
  return (
    <div className="dashboard-view-ctn">
      {!loggedIn ? (
        <div className="not-logged-in-msg">
          You have to login to view profile
        </div>
      ) : (
        <Dashboard />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(DashboardView);
