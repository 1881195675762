import React from "react";
import Banner from "../../assets/images/home/homeview/banner.png"
import { CASINO_MOB_NAV_PROVIDERS, CASINO_WEB_NAV_PROVIDERS } from "../../constants/CasinoView";
import "../Home/Home.scss"
import Slider from 'react-slick';
import { IonRow, IonSelect, IonSelectOption } from "@ionic/react";
import { GapGame } from "../../models/dc/DcGame";
import NotificationTab from "../Notification-tab/NotificationTab";
import { RootState } from "../../models/RootState";
import { FatchLiveCasinoGames, handleSearchGame, setSearchGame } from "../../store";
import { connect } from "react-redux";
import Search from "../../assets/images/home/homeview/search.svg"

type BannerProps = {
    loggedIn: boolean;
    allowedConfig: number;
    FatchLiveCasinoGames: (type: string) => void;
    liveCasinoGames: GapGame[];
    setSearchGame: Function;
    searchGame: string;
    handleSearchGame: () => void;
  };

const PageBanner: React.FC<BannerProps> = (props) => {
    const {
        allowedConfig,
        FatchLiveCasinoGames,
        liveCasinoGames,
        setSearchGame,
        searchGame,
        handleSearchGame,
      } = props;

  const settings = {
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };
  return (
    <div className="home-page-ctn">
      <div className="page-banner-ctn web-view">
        <Slider {...settings}>
          {CASINO_WEB_NAV_PROVIDERS?.length > 0 &&
            CASINO_WEB_NAV_PROVIDERS.map((g, i) => {
              return (
                <>
                  <div className="slider-images">
                    <img
                      className="banner-images"
                      src={g.image}
                      key={'g.image' + i}
                      alt={g.text}
                    />
                  </div>
                </>
              );
            })}
        </Slider>
        {/* <div className="search-bar">
            <div className="search">
              <input
                className="input"
                placeholder="Search Games"
                name="searchText"
                type="text"
                value={searchGame}
                onChange={(e: any) => setSearchGame(e.target.value)}
              />
              <div
                className="searchButton"
                onClick={() => handleSearchGame()}
              ><img src={Search}></img></div>
            </div>

          </div> */}

          <NotificationTab />
            </div>
            <div className="page-banner-ctn mob-view">
                <Slider {...settings}>
                    {CASINO_MOB_NAV_PROVIDERS?.length > 0 &&
                        CASINO_MOB_NAV_PROVIDERS.map((g, i) => {
                            return (
                                <>
                                    <div className="slider-images">
                                        <img
                                            className="banner-images"
                                            src={g.image}
                                            key={'g.image' + i}
                                            alt={g.text}
                                        />
                                    </div>
                                </>
                            );
                        })}
                </Slider>
                <div className="search-bar">
            {/* <div className="search">
              <input
                className="input"
                placeholder="Search Games"
                name="searchText"
                type="text"
                value={searchGame}
                onChange={(e: any) => setSearchGame(e.target.value)}
              />
              <div
                className="searchButton"
                onClick={() => handleSearchGame()}
              ><img src={Search}></img></div>
            </div> */}

          </div>

          <NotificationTab />
            </div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
      loggedIn: state.auth.loggedIn,
      allowedConfig: state.common.allowedConfig,
      liveCasinoGames: state.indianCasino.liveCasinoGames,
      searchGame: state.indianCasino.searchGame,
  
    };
  };
  
  const mapDispatchToProps = (dispatch: Function) => {
    return {
      FatchLiveCasinoGames: (type: string) =>
        dispatch(FatchLiveCasinoGames(type)),
      setSearchGame: (data: string) => dispatch(setSearchGame(data)),
      handleSearchGame: () => dispatch(handleSearchGame()),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(PageBanner);