import React, { useState, useEffect, useCallback } from 'react';
import { IonRow, IonToggle } from '@ionic/react';
import {
  UserSettings,
  OddsChangeType,
  OddsDisplayType,
} from '../../models/UserSettings';
import { AuthResponse } from '../../models/api/AuthResponse';
import Alert from '../../components/Alert/Alert';
import Spinner from '../../components/Spinner/Spinner';
import API from '../../api/index';
import './PunterSettings.scss';
import { BRAND_NAME } from '../../constants/Branding';

const PunterSettings: React.FC = () => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [progress, setProgress] = useState<Boolean>(false);
  const [userSettings, setUserSettings] = React.useState<UserSettings>({
    asianView: false,
    oddsChangeType: OddsChangeType.none,
    oddsDisplayType: OddsDisplayType.Decimal,
    quickBet: false,
    enableDarkTheme: false,
    enableEmails: false,
    timeZone: 'UTC',
  });

  const handleSettingChange = async (name: string, value: any) => {
    const updatedSettings = { ...userSettings, [name]: value };
    setUserSettings(updatedSettings);
    try {
      const response: AuthResponse = await API.put(
        '/user/settings',
        updatedSettings,
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        setProgress(false);
      } else {
        setProgress(false);
        throw new Error(response.data);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setErrorMsg(err.response.data.error);
      }
      setProgress(false);
    }
  };

  const fetchUserSettings = useCallback(async () => {
    setProgress(true);
    setErrorMsg(null);
    try {
      const response: AuthResponse = await API.get('/user/settings', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
      if (response.status === 200) {
        console.log(response.data);
        setUserSettings(response.data);
        setProgress(false);
      } else {
        setProgress(false);
        throw new Error(response.data);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setErrorMsg(err.response.data.error);
      }
      setProgress(false);
    }
  }, []);

  useEffect(() => {
    fetchUserSettings();
  }, [fetchUserSettings]);

  return (
    <div className="reports-ctn punter-settings">
      <div className="header-ctn">
        <IonRow>
          <div className="page-title">Settings</div>
        </IonRow>
      </div>

      {progress ? (
        <Spinner />
      ) : (
        <div className="content-ctn">
          {errorMsg ? <Alert message={errorMsg} /> : ''}

          {/* <IonRow className="setting-row">
            <div className="toggle-col">
              <div>
                <div className="sub-title">Asian View</div>
                <div className="desc-text">
                  Choose your preferred view. Asian view applies to Cricket,
                  Soccer, Basketball and eSoccer.
                </div>
              </div>
              <div>
                <IonToggle
                  checked={userSettings.asianView}
                  onIonChange={(e) =>
                    handleSettingChange('asianView', e.detail.checked)
                  }
                  mode="ios"
                />
              </div>
            </div>
          </IonRow>

          <IonRow className="setting-row">
            <div className="sub-title">Odds Changes</div>
            <div className="desc-text">Accepted odds preferences.</div>

            <div className="select-control">
              <Select
                id="odds-change-select"
                name="oddsChangeType"
                value={userSettings.oddsChangeType}
                onChange={(e) =>
                  handleSettingChange(e.target.name, e.target.value)
                }
                disableUnderline={true}
              >
                <MenuItem value={OddsChangeType.any}>Any</MenuItem>
                <MenuItem value={OddsChangeType.higher}>Higher</MenuItem>
                <MenuItem value={OddsChangeType.none}>None</MenuItem>
              </Select>
            </div>
          </IonRow>

          <IonRow className="setting-row">
            <div className="sub-title">Odds Format</div>
            <div className="desc-text">
              Choose your preferenced odds format.
            </div>

            <div className="select-control">
              <Select
                id="odds-change-select"
                name="oddsDisplayType"
                value={userSettings.oddsDisplayType}
                onChange={(e) =>
                  handleSettingChange(e.target.name, e.target.value)
                }
                disableUnderline={true}
              >
                <MenuItem value={OddsDisplayType.Decimal}>Decimal</MenuItem>
                <MenuItem value={OddsDisplayType.American}>American</MenuItem>
              </Select>
            </div>
          </IonRow> */}

          <IonRow className="setting-row">
            <div className="toggle-col">
              <div>
                <div className="sub-title">Quick Bet</div>
                <div className="desc-text">
                  Activate this feature to replace bets withot confirming odds
                  chnages in the betslip.
                </div>
              </div>
              <div>
                <IonToggle
                  checked={userSettings.quickBet}
                  onIonChange={(e) =>
                    handleSettingChange('quickBet', e.detail.checked)
                  }
                  mode="ios"
                />
              </div>
            </div>
          </IonRow>

          {/* <IonRow className="setting-row">
            <div className="toggle-col">
              <div>
                <div className="sub-title">Accept All Odd Changes</div>
                <div className="desc-text">
                  Activate this feature to replace bets withot confirming odds
                  chnages in the betslip.
                </div>
              </div>
              <div>
                <IonToggle
                  checked={userSettings.oddsChangeType}
                  onIonChange={(e) =>
                    handleSettingChange('accptAllOddChanges', e.detail.checked)
                  }
                />
              </div>
            </div>
          </IonRow> */}

          <IonRow className="setting-row">
            <div className="toggle-col">
              <div>
                <div className="sub-title">
                  I agree to recieve bonus & marketing emails
                </div>
                <div className="desc-text">
                  Stay up to date and recieve handpicked promotions, news and
                  insider tips from {BRAND_NAME}.
                </div>
              </div>
              <div>
                <IonToggle mode="ios" />
              </div>
            </div>
          </IonRow>

          {/* <IonRow className="setting-row dark-theme-row">
            <div className="toggle-col">
              <div>
                <div className="sub-title">Theme</div>
                <div className="desc-text">
                  Stay up to date and recieve handpicked promotions, news and
                  insider tips from {BRAND_NAME}
                </div>
              </div>
              <div>
                <IonToggle mode="ios" />
              </div>
            </div>
          </IonRow> */}

          {/* <IonRow className="setting-row">
            <div className="sub-title">Time Zone</div>
            <div className="desc-text">Choose your familer time zone.</div>

            <div className="select-control">
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="timeZone"
                value={userSettings.timeZone}
                onChange={(e) =>
                  handleSettingChange(e.target.name, e.target.value)
                }
                disableUnderline={true}
              >
                <MenuItem value={''}>Choose your time zone</MenuItem>
                {TIME_ZONES.map((zone) => (
                  <MenuItem key={zone} value={zone}>
                    {zone}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </IonRow> */}
        </div>
      )}
    </div>
  );
};

export default PunterSettings;
