import React, { useEffect, useState } from 'react';
import { IonRow, IonCol } from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';
import Dialog from '@material-ui/core/Dialog';
import './CasinoNew.scss';
import API from '../../../api';
import { RootState } from '../../../models/RootState';
import { GapGame } from '../../../models/dc/DcGame';
import {
  getCurrencyTypeFromToken,
  setCasinoGame,
  updateCasinoGames,
} from '../../../store';
import inplay from '../../../assets/images/common/Banner/Polygon.svg';
import TabPanel from '../../../components/TabPanel/TabPanel';
import { CasinoGameDTO } from '../../../models/IndianCasinoState';
import Footer from '../../../components/Footer/Footer';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import ClearIcon from '@material-ui/icons/Clear';
import { BRAND_NAME } from '../../../constants/Branding';
import { ShowCasinoModal } from '../../../constants/WhitelabelConfig';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Button } from '@material-ui/core';
import SVLS_API from '../../../svls-api';
type StoreProps = {
  loggedIn: boolean;
  loggedInUserStatus: any;
  updateCasinoGames: (casinoGames: CasinoGameDTO[]) => void;
  setCasinoGame: (cGame: CasinoGameDTO) => void;
  prefersDark: string;
};

const CardGames: React.FC<StoreProps> = (props) => {
  const [games, setGames] = useState<GapGame[]>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedEvolutionGame, setSelectedEvolutionGame] = useState({
    gameId: null,
    gameName: null,
    gameCode: null,
    provider: null,
    subProvider: null,
  });
  const [casinoGameCatTabValue, setCasinoGameCatTabValue] = useState<number>(0);
  const [filteredProvider, setFilteredProvider] = useState<string>('');
  const [providerList, setProviderList] = useState<string[]>();
  const {
    loggedIn,
    updateCasinoGames,
    setCasinoGame,
    loggedInUserStatus,
    prefersDark,
  } = props;

  const pathParams = useParams();
  const gameName = pathParams['gameName'];
  const tabArray = [
    'all',
    'roulette',
    'baccarat',
    'blackJack',
    'poker',
    'evolution',
    'pragmatic-play',
    'super-spade',
    'Others',
  ];
  useEffect(() => {
    let index = tabArray.indexOf(gameName);
    if (index > -1) {
      setCasinoGameCatTabValue(index);
    } else {
      setCasinoGameCatTabValue(0);
    }
  }, [gameName]);

  const history = useHistory();

  const liveCasinoGamesMap = {
    blackJack: 'Live Blackjack',
    baccarat: 'Live Baccarat',
    roulette: 'Live Roulette',
    poker: 'Live Poker',
    other: 'Others',
  };

  const setDialogShow = (show: boolean) => {
    setShowDialog(show);
  };

  const getDcGames = async () => {
    let response: AxiosResponse<GapGame[]>;
    if (loggedIn) {
      response = await SVLS_API.get('/catalog/v2/indian-casino/games',{
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      }
      );
    } else {
      response = await SVLS_API.get('/catalog/v2/indian-casino/games');
    }

    const filteredGamesList = response.data.filter((g: GapGame) => {
      return ['ezugi'].includes(g.subProviderName.toLowerCase());
    });

    setGames(filteredGamesList);
    setProviderList(
      filteredGamesList
        .map((g) => g.subProviderName)
        .filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        })
    );
  };

  const getGameUrl = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    provider: string,
    subProvider: string
  ) => {
    if (loggedIn) {
      // status check
      if (loggedInUserStatus === 0 || loggedInUserStatus === 3) {
        history.push(`/home`);
      }
      if (provider === 'Indian Casino') {
        setCasinoGame({ id: gameCode, name: gameName });
        history.push(`/casino/indian/${gameCode}`);
      } else {
        history.push(
          `/gap_casino/game/${gameName
            .toLowerCase()
            .replace(/\s+/g, '-')}-${btoa(gameId.toString())}-${btoa(
            gameCode
          )}-${btoa(provider)}-${btoa(subProvider)}`
        );
      }
    } else {
      setDialogShow(true);
    }
  };

  const handleGameClick = async (
    gameId: string,
    gameName: string,
    gameCode: string,
    subProvider: string,
    provider?: string
  ) => {
    if (
      getCurrencyTypeFromToken() === 0 &&
      !(
        provider.toLocaleLowerCase() === 'ezugi' ||
        subProvider === 'BetGames.TV' ||
        subProvider === 'Pragmatic Play' ||
        subProvider === 'Onetouch Live' ||
        subProvider === 'OneTouch'
      )
    ) {
      setSelectedEvolutionGame({
        gameId,
        gameName,
        gameCode,
        provider,
        subProvider,
      });
      setOpenDialog(true);
    } else {
      getGameUrl(gameId, gameName, gameCode, provider, subProvider);
    }
  };

  useEffect(() => {
    getDcGames();
  }, []);

  let rouletteList = games.filter(
    (g) => g.category === liveCasinoGamesMap.roulette
  );

  let pokerList = games.filter((g) => g.category === liveCasinoGamesMap.poker);

  let otherList = games.filter(
    (g) =>
      g.category === liveCasinoGamesMap.other ||
      ['Onetouch Live', 'Asia Gaming'].includes(g.subProviderName)
  );

  let blackjackList = games.filter(
    (g) => g.category === liveCasinoGamesMap.blackJack
  );

  let allList = games.filter(
    (g) =>
      g.category !== 'Video Slots' &&
      g.category !== 'Casual Games' &&
      g.category !== 'Virtual Sports' &&
      g.category !== 'Live Dealer' &&
      g.category !== 'Fish Shooting' &&
      g.subProviderName !== 'Hacksaw Gaming'
  );
  return (
    <div className="dc-page-bg">
      <div className="dc-ctn">
        <TabPanel value={casinoGameCatTabValue} index={0}>
          {allList && allList.length > 0 ? (
            <>
              <IonRow className="dc-games-ctn">
                {allList.map((g, i) => (
                  <>
                    <IonCol
                      sizeXs="6"
                      sizeSm="6"
                      sizeMd="3"
                      sizeLg="3"
                      key={g.gameName}
                      onClick={() =>
                        handleGameClick(
                          g.gameId,
                          g.gameName,
                          g.gameCode,
                          g.subProviderName,
                          g.providerName
                        )
                      }
                    >
                      <div className="container1">
                        <img
                          src={g.urlThumb ? g.urlThumb : ''}
                          alt={g.gameName}
                          className="dc-img"
                        />
                        <div className="overlay1">
                          <div className="text1">
                            <div
                              className="custom-btn"
                              onClick={() =>
                                handleGameClick(
                                  g.gameId,
                                  g.gameName,
                                  g.gameCode,
                                  g.subProviderName,
                                  g.providerName
                                )
                              }
                            >
                              <img src={inplay} alt="inplay" />
                              <div> PLAY NOW </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </IonCol>
                  </>
                ))}
              </IonRow>
            </>
          ) : null}
        </TabPanel>

        <TabPanel value={casinoGameCatTabValue} index={2}>
          {rouletteList && rouletteList.length > 0 ? (
            <>
              <IonRow className="dc-games-ctn">
                {rouletteList.map((g, i) => (
                  <>
                    <IonCol
                      sizeXs="6"
                      sizeSm="6"
                      sizeMd="3"
                      sizeLg="3"
                      key={g.gameName}
                      onClick={() =>
                        handleGameClick(
                          g.gameId,
                          g.gameName,
                          g.gameCode,
                          g.subProviderName,
                          g.providerName
                        )
                      }
                    >
                      <img
                        src={g.urlThumb ? g.urlThumb : ''}
                        alt={g.gameName}
                        className="dc-img"
                      />
                    </IonCol>
                  </>
                ))}
              </IonRow>
            </>
          ) : null}
        </TabPanel>
        <TabPanel value={casinoGameCatTabValue} index={3}>
          {pokerList && pokerList.length > 0 ? (
            <>
              <IonRow className="dc-games-ctn">
                {pokerList.map((g, i) => (
                  <>
                    <IonCol
                      sizeXs="6"
                      sizeSm="6"
                      sizeMd="3"
                      sizeLg="3"
                      key={g.gameName}
                      onClick={() =>
                        handleGameClick(
                          g.gameId,
                          g.gameName,
                          g.gameCode,
                          g.subProviderName,
                          g.providerName
                        )
                      }
                    >
                      <img
                        src={g.urlThumb ? g.urlThumb : ''}
                        alt={g.gameName}
                        className="dc-img"
                      />
                    </IonCol>
                  </>
                ))}
              </IonRow>
            </>
          ) : null}
        </TabPanel>

        <TabPanel value={casinoGameCatTabValue} index={4}>
          {blackjackList && blackjackList.length > 0 ? (
            <>
              <IonRow className="dc-games-ctn">
                {blackjackList.map((g, i) => (
                  <>
                    <IonCol
                      sizeXs="6"
                      sizeSm="6"
                      sizeMd="3"
                      sizeLg="3"
                      key={g.gameName}
                      onClick={() =>
                        handleGameClick(
                          g.gameId,
                          g.gameName,
                          g.gameCode,
                          g.subProviderName,
                          g.providerName
                        )
                      }
                    >
                      <img
                        src={g.urlThumb ? g.urlThumb : ''}
                        alt={g.gameName}
                        className="dc-img"
                      />
                    </IonCol>
                  </>
                ))}
              </IonRow>
            </>
          ) : null}
        </TabPanel>

        <TabPanel value={casinoGameCatTabValue} index={5}>
          {otherList && otherList.length > 0 ? (
            <>
              <IonRow className="dc-games-ctn">
                {otherList.map((g, i) => (
                  <>
                    <IonCol
                      sizeXs="6"
                      sizeSm="6"
                      sizeMd="3"
                      sizeLg="3"
                      key={g.gameName}
                      onClick={() =>
                        handleGameClick(
                          g.gameId,
                          g.gameName,
                          g.gameCode,
                          g.subProviderName,
                          g.providerName
                        )
                      }
                    >
                      <img
                        src={g.urlThumb ? g.urlThumb : ''}
                        alt={g.gameName}
                        className="dc-img"
                      />
                    </IonCol>
                  </>
                ))}
              </IonRow>
            </>
          ) : null}
        </TabPanel>
      </div>

      {/* <Footer /> */}

      <Dialog
        open={showDialog}
        onClose={() => setDialogShow(false)}
        aria-labelledby="form-dialog-title"
        className="dialog-div"
      >
        <div className="dialog-div-body">
          <div className="go-corner" onClick={() => setDialogShow(false)}>
            <div className="go-arrow">
              <ClearIcon />
            </div>
          </div>
          <div className="dialog-title">Notice</div>
          <div className="dialog-body">You have to be logged in to play</div>

          <div className="dialog-continue" onClick={() => setDialogShow(false)}>
            <div className="custom-btn btn">OK</div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="form-dialog-title"
        className="dialog-div"
      >
        <div className="dialog-div-body">
          <div className="go-corner" onClick={() => setOpenDialog(false)}>
            <div className="go-arrow">
              <ClearIcon />
            </div>
          </div>
          <div className="dialog-title">
            <StarBorderRoundedIcon />
            Welcome
            <StarBorderRoundedIcon />
          </div>

          {getCurrencyTypeFromToken() === 0 ? (
            <div className="dialog-body">
              1 Live casino point is equal to 10 normal points
              <div className="dialog-ex">
                {' '}
                Ex: 100 casino points = 1000 normal points
              </div>
              <p className="dialog-ex">
                Maximum Bet limit is 50000 casino points
              </p>
            </div>
          ) : null}

          {getCurrencyTypeFromToken() === 1 ? (
            <>
              {' '}
              <div className="dialog-body">
                <div className="dialog-ex">
                  {' '}
                  Maximum Bet Limit For All Games is 5000
                </div>
              </div>
            </>
          ) : null}

          <div className="dialog-continue" onClick={() => {
            getGameUrl(
              selectedEvolutionGame?.gameId,
              selectedEvolutionGame?.gameName,
              selectedEvolutionGame?.gameCode,
              selectedEvolutionGame?.provider,
              selectedEvolutionGame?.subProvider
            )
          }}>
            <Button className="btn">Continue</Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  let status = 0;
  if (state.auth.loggedIn) {
    status = JSON.parse(
      window.atob(sessionStorage.getItem('jwt_token').split('.')[1])
    ).status;
  }
  return {
    loggedIn: state.auth.loggedIn,
    loggedInUserStatus: status,
    prefersDark: state.common.prefersDark,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    updateCasinoGames: (casinoGames: CasinoGameDTO[]) =>
      dispatch(updateCasinoGames(casinoGames)),
    setCasinoGame: (cGame: CasinoGameDTO) => dispatch(setCasinoGame(cGame)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardGames);
