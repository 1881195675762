// Web banners
import banner from '../assets/images/common/Landing.png';

import { CONFIG_PERMISSIONS } from './ConfigPermission';

import cardImg from '../assets/images/common/Banner/c1.jpg';
import cardImg1 from '../assets/images/common/Banner/4.jpg';
import cardImg2 from '../assets/images/common/Banner/3.jpg';

import casinoImg from '../assets/images/common/Banner/11.jpg';
import casinoImg777 from '../assets/images/common/Banner/777.jpg';
import casinoImg1 from '../assets/images/common/Banner/9.jpg';

import slotImg from '../assets/images/common/Banner/casino_mob_slide.jpeg';
import slotImg1 from '../assets/images/common/Banner/2.jpg';

import virtualImg from '../assets/images/common/Banner/11.jpg';
import virtualImgMob from '../assets/images/common/Banner/virual_games_mob.jpeg';

import otherImg1 from '../assets/images/common/Banner/sportsbook.jpg';
import othersWebImg1 from '../assets/images/common/Banner/others_web1.jpg';
import othersWebImg2 from '../assets/images/common/Banner/others_web2.jpg';

import CricketMobSwiper from '../assets/images/common/homepagebanner/cricketweb.jpeg';
import SoccerMobSwiper from '../assets/images/common/homepagebanner/footballweb.jpeg';
import TennisMobSwiper from '../assets/images/common/homepagebanner/tennisweb.jpeg';

import PokerCardImg from '../assets/images/casino/providers/poker-card.png';
import EvolutionCardImg from '../assets/images/casino/providers/playing-card.png';
import RoyalCardImg from '../assets/images/casino/providers/royal-card.png';
import EzugiCardImg from '../assets/images/casino/providers/spider-card.png';

import allIcon from '../assets/images/common/all.svg';
import rouletteIcon from '../assets/images/common/roulette.svg';
import baccaratIcon from '../assets/images/common/baccarat.svg';
import pokerIcon from '../assets/images/common/cards.svg';
import crapsIcon from '../assets/images/common/craps.svg';
import bingoIcon from '../assets/images/common/bingo.svg';
import wheelIcon from '../assets/images/common/wheel.svg';
import arcadeIcon from '../assets/images/common/arcade.svg';
import scratchIcon from '../assets/images/common/scratch.svg';
import img11 from '../assets/images/common/7.svg';
import Img1 from '../assets/images/casino/cards/1.svg';
import Img2 from '../assets/images/casino/cards/2.svg';
import Img3 from '../assets/images/casino/cards/3.svg';
import Img4 from '../assets/images/casino/cards/4.svg';
import Img5 from '../assets/images/casino/cards/5.svg';
import Img6 from '../assets/images/casino/cards/bombay.svg';
import Img7 from '../assets/images/casino/cards/7.svg';
import Img8 from '../assets/images/casino/cards/11.svg';
import Img9 from '../assets/images/casino/cards/17.svg';
import Img10 from '../assets/images/casino/cards/10.svg';
import Img22 from '../assets/images/casino/cards/22.svg';

const HOME_PAGE_HEADERS = {
  featureSports: 'FEATURED SPORTS',
  popularGames: 'POPULAR GAMES',
  liveGames: 'Live Casino',
};

const HOME_PAGE_IMAGES = {
  speedCricketBaccarat: {
    image: 'https://cdn.dreamcasino.live/roulette-lobby-thumb.jpg',
    id: '100308:evo_liveroulettelobby:EZUGI:Evolution Games',
    text: 'Roulette Lobby',
  },

  roulette: {
    image: 'https://cdn.dreamcasino.live/ezg_baccaratlobby.jpg',
    id: '100049:ezg_baccaratlobby:EZUGI:EZUGI',
    text: 'Baccarat Lobby',
  },

  VIPRoulette: {
    image:
      'https://cdn.dreamcasino.live/game-thumbs/latest/OT_Live_490x368.png',
    id: '201132:otl_onetouchliveroulettelobby:DC:Onetouch',
    text: 'OneTouch Live',
  },
  dragonTiger: {
    image: 'https://cdn.dreamcasino.live/evo_nocommissionbaccarat.jpg',
    id: '100207:evo_no_commission_baccarat:EZUGI:Evolution Games',
    text: 'Live',
  },

  teenpatti: {
    image:
      'https://cdn.dreamcasino.live/superspadeclub/super-spade-games-lobby-thumb.jpg',
    id: '200093:ssc_superspadeclublobby:DC:Super Spade Games',
    text: 'Super Spade Games',
  },

  BetonNumbers: {
    image: 'https://cdn.dreamcasino.live/btg_andarbahar-thumb.jpg',
    id: '201160:bgt_andarbahar:DC:BetGames.TV',
    text: 'Andar Bahar',
  },

  houseOfDragons: {
    image: 'https://cdn.dreamcasino.live/ebt_ebetlobby.jpg',
    id: '200912:ebt_ebetlobby:DC:eBET',
    text: 'eBET',
  },

  Cards32: {
    image: 'https://cdn.dreamcasino.live/ezg_dragontiger.jpg',
    id: '100061:ezg_dragontiger:EZUGI:EZUGI',
    text: 'Dragon Tiger',
  },
  blackJack: {
    image: 'https://cdn.dreamcasino.live/ebt_ebetlobby.jpg',
    id: '200912:ebt_ebetlobby:DC:eBET',
    text: 'eBET',
  },
  blackjack: {
    image: 'https://cdn.hub88.io/evolution/evo_liveblackjackd.jpg',
    id: '100221:evo_speed_blackjack_d:EZUGI:Evolution Games',
    text: 'Blackjack D',
  },

  andar_bahar_lobby: {
    image: 'https://cdn.dreamcasino.live/ezg_andarbaharlobby.jpg',
    id: '3928:ezg_andarbaharlobby:EZUGI:EZUGI',
    text: 'Andar Bahar Lobby',
  },
  cricket_baccarat: {
    image: 'https://cdn.dreamcasino.live/ezg_speedcricketbaccarat.jpg',
    id: '100050:ezg_speedcricketbaccarat:EZUGI:EZUGI',
    text: 'Speed Cricket Baccarat',
    gameName: 'baccarat',
  },
  speed_roulette: {
    image: 'https://cdn.dreamcasino.live/ezg_speedroulette.jpg',
    id: '100027:ezg_speedroulette:EZUGI:EZUGI',
    text: 'Speed Roulette',
    gameName: 'roulette',
  },
  speed_auto_roulette: {
    image: 'https://cdn.dreamcasino.live/evo_livetriplecardpoker.jpg',
    id: '100035:ezg_speedautoroulette:EZUGI:EZUGI',
    text: 'Speed Auto Roulette',
    gameName: 'poker',
  },
  teen_patti: {
    image: 'https://cdn.dreamcasino.live/ezg_teenpatti.jpg',
    id: '100046:ezg_teenpatti:EZUGI:EZUGI',
    text: 'Teen Patti',
    gameName: 'EZUGI',
  },
  one_day_teen_patti_classic: {
    image: 'https://cdn.dreamcasino.live/ezg_onedayteenpatticlassic.jpg',
    id: '100048:ezg_onedayteenpatticlassic:EZUGI:EZUGI',
    text: 'One Day Teen Patti Classic',
    gameName: 'EZUGI',
  },

  game_32_card: {
    image: 'https://cdn.dreamcasino.live/ezg_32cards-thumb.jpg',
    id: '100067:ezg_32cards:EZUGI:EZUGI',
    text: '32 Cards',
    gameName: 'EZUGI',
  },
  bet_on_teen_patti: {
    // image: 'https://cdn.dreamcasino.live/ezg_betonteenpatti.jpg',
    image: 'https://cdn.dreamcasino.live/evo_liveblackjacka.jpg',
    id: '100047:ezg_betonteenpatti:EZUGI:EZUGI',
    text: 'Bet On Teen Patti',
    gameName: 'blackJack',
  },
  onetouch_live_lobby: {
    image: 'https://cdn.dreamcasino.live/onto_live_lobby-thumb.jpg',
    id: '201126:onto_onetouchlivelobby:DC:Onetouch Live',
    text: 'OneTouch Live Lobby',
    gameName: 'EZUGI',
  },

  onetouch_live_roulette_lobby: {
    // image:
    //   'https://cdn.dreamcasino.live/game-thumbs/latest/OT_Live_490x368.png',
    image: 'https://cdn.hub88.io/pragmatic/pgp_live-baccaratb.jpg',
    id: '201132:otl_onetouchliveroulettelobby:DC:Onetouch Live',
    text: 'OneTouch Live Roulette Lobby',
    gameName: 'pragmatic-play',
  },

  virtual_cricket: {
    image: 'https://cdn.dreamcasino.live/premier-league-cricket-thumb.jpg',
    id: '200970:edg_virtualcricket:DC:Edge Gaming',
    text: 'virtual cricket',
    gameName: 'EZUGI',
  },
  rct: {
    // image: 'https://cdn.dreamcasino.live/premier-league-cricket-thumb.jpg',
    image: 'https://cdn.dreamcasino.live/evo_freebetblackjack.jpg',
    id: '200970:cmg_cricketfevervideoslot:DC:Edge Gaming',
    text: 'virtual cricket',
    gameName: 'evolution',
  },
};

const HOME_PAGE_WEB_BANNERS = [
  // {
  //   image: banner1,
  //   text: 'Casino',
  //   url: '/casino',
  //   configPermission: CONFIG_PERMISSIONS.casino,
  // },
  // {
  //   image: banner2,
  //   text: 'Casino',
  //   url: '/casino',
  //   configPermission: CONFIG_PERMISSIONS.casino,
  // },
  // {
  //   image: banner3,
  //   text: 'Casino',
  //   url: '/casino',
  //   configPermission: CONFIG_PERMISSIONS.casino,
  // },
  {
    image: banner,
    text: 'Casino',
    url: '/casino',
    configPermission: CONFIG_PERMISSIONS.casino,
  },
];

const HOME_PAGE_MOB_BANNERS = [
  {
    image: banner,
    text: 'Casino',
    url: '/casino',
    configPermission: CONFIG_PERMISSIONS.casino,
  },
];

const CARDS_SLIDER_IMAGES = [
  {
    name: 'all',
    img: allIcon,
    tab: 0,
    class: 'icon1',
  },
  // {
  //   name: 'Lucky7',
  //   img: rouletteIcon,
  //   tab: 1,
  //   class: 'icon2',
  // },

  // {
  //   name: 'Dragon Tiger',
  //   img: rouletteIcon,
  //   tab: 1,
  //   class: 'icon2',
  // },
  // {
  //   name: 'Andhar Bahar',
  //   img: rouletteIcon,
  //   tab: 1,
  //   class: 'icon2',
  // },
  {
    name: 'roulette',
    img: rouletteIcon,
    tab: 1,
    class: 'icon2',
  },
  {
    name: 'Teenpatti',
    img: rouletteIcon,
    tab: 1,
    class: 'icon2',
  },
  {
    name: 'baccarat',
    img: baccaratIcon,
    tab: 2,
    class: 'icon2',
  },
  {
    name: 'poker',
    img: pokerIcon,
    tab: 3,
    class: 'icon2',
  },
  {
    name: 'craps',
    img: crapsIcon,
    tab: 4,
    class: 'icon2',
  },
  {
    name: 'bingo',
    img: bingoIcon,
    tab: 5,
    class: 'icon1',
  },
  {
    name: 'wheel',
    img: wheelIcon,
    tab: 6,
    class: 'icon2',
  },
  {
    name: 'arcade',
    img: arcadeIcon,
    tab: 7,
    class: 'icon2',
  },
  {
    name: 'scratch',
    img: scratchIcon,
    tab: 8,
    class: 'icon2',
  },
];

const CARDS_SLIDER_SPORTS_MOB = [
  {
    image: CricketMobSwiper,
    text: 'Cricket',
    url: 'exchange_sports/cricket',
  },
  {
    image: SoccerMobSwiper,
    text: 'Football',
    url: 'exchange_sports/football',
  },
  {
    image: TennisMobSwiper,
    text: 'Tennis',
    url: 'exchange_sports/tennis',
  },
];

const CARDS_SLIDER_CASINO_MOB = [
  {
    image: casinoImg777,
    text: 'Slot',
    url: '/slot_games',
  },
];

const CARDS_SLIDER_CARDS_MOB = [
  {
    image: cardImg,
    text: 'Cards',
    url: '/cards',
  },
];
const CARDS_SLIDER_SLOTS_MOB = [
  {
    image: slotImg,
    text: 'Live Casino',
    url: '/casino',
  },
];
const CARDS_SLIDER_VITRUAL_MOB = [
  {
    image: virtualImgMob,
    text: 'virtual games',
    url: '/virtual_games',
  },
];

const CARDS_SLIDER_OTHER_MOB = [
  {
    image: otherImg1,
    text: 'SportBook',
    url: '/premium_sports',
  },
];

const OTHERS_TAB_GAMES_WEB = [
  {
    image: othersWebImg1,
    text: 'virtual games',
    url: '/other_games',
  },
  {
    image: othersWebImg2,
    text: 'Binary',
    url: '/other_games',
  },
];

export const CASINO_CARDS_IMAGES = [];

const HOME_PAGE_CASINO_TILES = [
  {
    image: EvolutionCardImg,
    text: 'Evolution Gaming',
  },
  {
    image: EzugiCardImg,
    text: 'Ezugi',
  },

  {
    image: PokerCardImg,
    text: 'Asia Gaming',
  },
  {
    image: RoyalCardImg,
    text: '',
  },
];

export const CASINO_PROVIDERS = [
  {
    image: img11,
    text: 'Laser',
    url: '/casino/royal laser',
  },
  {
    image: Img1,
    text: 'Royal Casino',
    url: '/casino/royal gaming',
  },
  {
    image: Img2,
    text: 'Betsoft',
    url: '/casino/betsoft',
  },
  {
    image: Img3,
    text: 'EZUGI',
    url: '/casino/ezugi',
  },
  {
    image: Img4,
    text: 'Supernowa',
    url: '/casino/supernowa',
  },
  {
    image: Img5,
    text: 'Evolution Gaming',
    url: '/casino/evolution gaming',
  },
  {
    image: Img6,
    text: 'bombay live',
    url: '/casino/bombay live',
  },
  {
    image: Img7,
    text: 'super spade gaming',
    url: '/casino/super spade gaming',
  },
  {
    image: Img8,
    text: 'AE Sexy',
    url: '/casino/sexybcrt',
  },
  {
    image: Img9,
    text: 'jili',
    url: '/casino/jili gaming',
  },
  {
    image: Img10,
    text: 'betGames.tv',
    url: '/casino/betGames.tv',
  },
  {
    image: Img22,
    text: 'Dream Casino',
    url: '/casino',
  },
];
export default {
  HOME_PAGE_HEADERS,
  HOME_PAGE_IMAGES,
  HOME_PAGE_WEB_BANNERS,
  HOME_PAGE_MOB_BANNERS,
  CARDS_SLIDER_IMAGES,
  HOME_PAGE_CASINO_TILES,
  CARDS_SLIDER_SPORTS_MOB,
  CARDS_SLIDER_CASINO_MOB,
  CARDS_SLIDER_CARDS_MOB,
  CARDS_SLIDER_SLOTS_MOB,
  CARDS_SLIDER_VITRUAL_MOB,
  CARDS_SLIDER_OTHER_MOB,
  OTHERS_TAB_GAMES_WEB,
};
